function timeAgo(created_at) {
    
  const currentDate = new Date();
  const messageDate = new Date(created_at);
  const timeDifference = currentDate - messageDate;
  const minutes = Math.floor(timeDifference / (1000 * 60));

  if (minutes < 1) {
    return "Just now";
  } else if (minutes === 1) {
    return "1 minute ago";
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else {
    const hours = Math.floor(minutes / 60);
    if (hours === 1) {
      return "1 hour ago";
    } else if (hours < 24) {
      return `${hours} hours ago`;
    } else {
      const days = Math.floor(hours / 24);
      if (days === 1) {
        return "1 day ago";
      } else {
        return `${days} days ago`;
      }
    }
  }
}

export default timeAgo