import React, { useEffect, useMemo, useState } from "react";
import { dateWiseLogsApi, dateWiseWorkApi, fetchallusers } from "../../api/api";
import TimeZone from "../../utils/helper/timezone";
const ExcelJS = require("exceljs");

const CalendarTable = () => {
  const [allUsers, setAllUsers] = useState(null);
  const [allMonths, setAllMonths] = useState([
    { id: 1, val: "January" },
    { id: 2, val: "February" },
    { id: 3, val: "March" },
    { id: 4, val: "April" },
    { id: 5, val: "May" },
    { id: 6, val: "June" },
    { id: 7, val: "July" },
    { id: 8, val: "August" },
    { id: 9, val: "September" },
    { id: 10, val: "October" },
    { id: 11, val: "November" },
    { id: 12, val: "December" },
  ]);
  const [calendarTable, setCalendarTable] = useState(null);
  const [dateWiseLogs, setDateWiseLogs] = useState(null);
  const [params, setParams] = useState({ assign_id: "", month: 1 });
  const [isLoading, setIsLoading] = useState(false);
  const filter = {};
  const fetchAssign = async () => {
    try {
      let response = await fetchallusers();
      let data = response.data;
      if (data.status === false) {
        console.log(data);
      }
      if (data.users) {
        let ignoreUserRole = [
          "Admin",
          "User",
          "Dispatcher Manager",
          "Dispatcher",
          "Client",
        ];
        let filterUser = data.users.filter(
          (user) => !ignoreUserRole.includes(user.roleName)
        );
        setAllUsers(filterUser);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (params.assign_id) {
      filter.assign_id = params.assign_id.split(" ")[0];
    }
    if (params.month) {
      filter.month = params.month;
    } else {
      setCalendarTable(null);
    }
    // if (!params.assign_id) {
    //   fetchDateWiseReport(filter);
    // }
    // if (Object.keys(filter).length == 2) {
    fetchDateWiseReport(filter);
  }, [params]);

  const fetchDateWiseReport = async (par) => {
    try {
      setIsLoading(true);
      let res = await dateWiseWorkApi(par);
      let data = res.data;
      if (data.status == true) {
        setIsLoading(false);
        let isAssign = false;
        if (params.assign_id) {
          isAssign = true;
        }
        let calendarTable = generateCalendarTable(
          params.month,
          data.data,
          isAssign
        );
        setCalendarTable(calendarTable);
      } else {
        setIsLoading(false);
        console.log(data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Report" + error);
    }
  };

  const paramsHandleChange = (e) => {
    /* if (e.target.name == "assign_id" && e.target.value=="") {
      fetchDateWiseReport({month:params.month});
    } */
    setParams({ ...params, [e.target.name]: e.target.value });
  };
  const memoizedFetchUser = useMemo(() => fetchAssign, []);

  useEffect(() => {
    memoizedFetchUser();
  }, []);

  const generateCalendarTable = (month, data, isAssign) => {
    if (isAssign) {
      const year = new Date().getFullYear();
      const numDays = new Date(year, month, 0).getDate();
      const calendarTable = {};

      for (let day = 1; day <= numDays; day++) {
        let totalMinutes = 0;
        const ticketIds = [];
        const fullDate = new Date(year, month - 1, day);

        data.forEach((item) => {
          if (item.hasOwnProperty(day.toString())) {
            const time = item[day.toString()];
            const [hours, minutes] = time.split(":").map(Number);
            totalMinutes += hours * 60 + minutes;
            if (Array.isArray(item[day.toString()])) {
              ticketIds.push(...item[day.toString()]);
            } else {
              ticketIds.push(item.ticket_id);
            }
          }
        });

        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        const formattedDate = fullDate.toLocaleDateString("en-GB");

        calendarTable[formattedDate] = {
          totalWorkedTime:
            hours.toString().padStart(2, "0") +
            ":" +
            minutes.toString().padStart(2, "0"),
          ticketIds: ticketIds,
        };
      }

      // Calculate total working time
      let totalWorkingTime = 0;
      Object.values(calendarTable).forEach((dayData) => {
        const [hours, minutes] = dayData.totalWorkedTime.split(":").map(Number);
        totalWorkingTime += hours * 60 + minutes;
      });

      const totalHours = Math.floor(totalWorkingTime / 60);
      const totalMinutes = totalWorkingTime % 60;

      calendarTable["totalWorkingTime"] = {
        totalWorkedTime:
          totalHours.toString().padStart(2, "0") +
          ":" +
          totalMinutes.toString().padStart(2, "0"),
        ticketIds: [], // You can define the ticketIds if needed
      };

      return calendarTable;
    } else {
      const year = new Date().getFullYear();
      const numDays = new Date(year, month, 0).getDate();
      const calendarTables = {};

      // Group data by user_id
      data.forEach((item) => {
        const user_id = item.user_id;
        if (!calendarTables[user_id]) {
          calendarTables[user_id] = [];
        }
        calendarTables[user_id].push(item);
      });

      const calendarTablesArray = [];

      // Generate calendar table for each user
      for (const user_id in calendarTables) {
        const userCalendarTable = {};
        for (let day = 1; day <= numDays; day++) {
          let totalMinutes = 0;
          const ticketIds = [];

          calendarTables[user_id].forEach((item) => {
            if (item.hasOwnProperty(day.toString())) {
              const time = item[day.toString()];
              const [hours, minutes] = time.split(":").map(Number);
              totalMinutes += hours * 60 + minutes;
              if (Array.isArray(item[day.toString()])) {
                ticketIds.push(...item[day.toString()]);
              } else {
                ticketIds.push(item.ticket_id);
              }
            }
          });
          const hours = Math.floor(totalMinutes / 60);
          const minutes = totalMinutes % 60;
          const fullDate = new Date(year, month - 1, day); // month - 1 because month in Date object is zero-based
          // Convert date to dd-mm-yyyy format
          const formattedDate = `${fullDate
            .getDate()
            .toString()
            .padStart(2, "0")}-${(fullDate.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${fullDate.getFullYear()}`;

          userCalendarTable[formattedDate] = {
            totalWorkedTime:
              hours.toString().padStart(2, "0") +
              ":" +
              minutes.toString().padStart(2, "0"),
            ticketIds: ticketIds,
          };
        }

        // Calculate total working hours for the user
        let totalWorkingMinutes = 0;
        Object.values(userCalendarTable).forEach((dayData) => {
          const [hours, minutes] = dayData.totalWorkedTime
            .split(":")
            .map(Number);
          totalWorkingMinutes += hours * 60 + minutes;
        });

        const totalHours = Math.floor(totalWorkingMinutes / 60);
        const totalMinutes = totalWorkingMinutes % 60;

        const totalWorkingHours = `${totalHours
          .toString()
          .padStart(2, "0")}:${totalMinutes.toString().padStart(2, "0")}`;

        // Push user data along with total working hours to calendarTablesArray
        calendarTablesArray.push({
          user_id: user_id,
          calendarTable: userCalendarTable,
          totalWorkingHours: totalWorkingHours,
        });
      }

      return calendarTablesArray;
    }
  };

  const showLogs = async (data) => {
    try {
      let { assign_id, day } = data;
      day = parseInt(day) + 1;
      assign_id = assign_id.split(" ")[0];
      let res = await dateWiseLogsApi({ ...data, assign_id, day });
      res = res.data;
      if (res.status == true) {
        setDateWiseLogs(res.data["logs"]);
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const exportToExcel = (isAssign) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.addRow(["Day", "Total Worked Time", "User"]);

    if (isAssign) {
      Object.keys(calendarTable).forEach((date) => {
        const rowData = calendarTable[date];
        const totalWorkedTime = rowData.totalWorkedTime;
        // const ticketIds = rowData.ticketIds.join(", ");
        sheet.addRow([date, totalWorkedTime, params.assign_id.split(" ")[1]]);
      });
    } else {
      let currenUser;
      calendarTable.forEach((userData) => {
        const cleanedUserDataString = userData.user_id.replace(/\n\s*/g, "");
        const match = cleanedUserDataString.match(/name:\s*'([^']+)'/);
        const username = match ? match[1] : null;
        const userId = username;
        const calendarTable = userData.calendarTable;
        if (currenUser === undefined) {
          currenUser = userId;
          sheet.addRow(["", "", ""]).height = 10;
          const row = sheet.addRow(["", userId, ""]);
          row.height = 40;
        }

        if (currenUser !== userId) {
          sheet.addRow(["", "", ""]).height = 10;
          const row = sheet.addRow(["", userId, ""]);
          row.height = 40;
          // row.fill = {
          //   type: "pattern",
          //   pattern: "solid",
          //   fgColor: { argb: "FFB7B3B3" },
          // };
          currenUser = userId;
        }
        Object.keys(calendarTable).forEach((date) => {
          const rowData = calendarTable[date];
          const totalWorkedTime = rowData.totalWorkedTime;
          // const ticketIds = rowData.ticketIds.join(", ");
          sheet.addRow([date, totalWorkedTime, '""']);
        });
      });
    }

    sheet.getRow(1).font = { bold: true };

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      if (navigator.vendor && navigator.vendor.indexOf("Apple") > -1) {
        // Safari
        window.open(
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
            data.toString("base64")
        );
      } else {
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "download.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      }
    });
  };

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">
                    Date Wise Working Time
                  </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="#">Report</a>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row justify-content-between">
                      <div className="col-md-8 d-flex">
                        <div className="col-md-2 ">
                          <h5
                            className="col-form-label"
                            style={{ fontWeight: "bold" }}
                          >
                            Assign User
                          </h5>

                          <select
                            id="role-assign"
                            className="form-select"
                            aria-label="Default select example"
                            name="assign_id"
                            onChange={paramsHandleChange}
                            value={params.assign_id}
                          >
                            <option defaultValue value="">
                              Select Users
                            </option>
                            {allUsers &&
                              allUsers.map((user) => (
                                <option
                                  value={`${user._id} ${user.username}`}
                                  key={user._id}
                                >
                                  {user.username}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="col-md-2 ms-5">
                          <h5
                            className="col-form-label"
                            style={{ fontWeight: "bold" }}
                          >
                            Month
                          </h5>

                          <select
                            id="role-assign"
                            className="form-select"
                            aria-label="Default select example"
                            name="month"
                            onChange={paramsHandleChange}
                            value={params.month}
                          >
                            <option defaultValue value="">
                              Select Month
                            </option>
                            {allMonths &&
                              allMonths.map((mo) => (
                                <option value={mo.id} key={mo.id}>
                                  {mo.val}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2 ">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            let isAssign = false;
                            if (params.assign_id) {
                              isAssign = true;
                            }
                            exportToExcel(isAssign);
                          }}
                        >
                          Generate Sheet
                        </button>
                      </div>
                    </div>
                    <div>
                      <h2 className="my-3 text-center">
                        {params.assign_id && params.assign_id.split(" ")[1]}
                      </h2>
                      {!params.assign_id && !isLoading && (
                        <div class="container">
                          <div class="row">
                            <div class="col">
                              <div class="accordion" id="accordionExample">
                                <table className="table table-condensed table-striped">
                                  <thead>
                                    <tr>
                                      <th>User</th>
                                      <th>Monthly Working</th>
                                    </tr>
                                  </thead>
                                </table>
                                {calendarTable &&
                                  Array.isArray(calendarTable) &&
                                  calendarTable.map((userData, index) => {
                                    const cleanedUserDataString =
                                      userData.user_id.replace(/\n\s*/g, "");
                                    const match =
                                      cleanedUserDataString.match(
                                        /name:\s*'([^']+)'/
                                      );
                                    const username = match ? match[1] : null;
                                    let totalWorkingHours =
                                      userData.totalWorkingHours;

                                    return (
                                      <div class="accordion-item" key={index}>
                                        <h2
                                          class="accordion-header"
                                          id={"heading" + index}
                                        >
                                          <button
                                            class="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={"#collapse" + index}
                                            aria-expanded="false"
                                            aria-controls={"collapse" + index}
                                          >
                                            <span
                                              class="me-5"
                                              style={{
                                                display: "inline-block",
                                                width: "20%",
                                              }}
                                            >
                                              {username}
                                            </span>
                                            <span>{totalWorkingHours}</span>
                                          </button>
                                        </h2>
                                        <div
                                          id={"collapse" + index}
                                          class="accordion-collapse collapse"
                                          aria-labelledby={"heading" + index}
                                          data-bs-parent="#accordionExample"
                                        >
                                          <div class="accordion-body">
                                            <table class="table">
                                              <thead>
                                                <tr>
                                                  <th width="250">Day</th>
                                                  <th>Daily Working</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {Object.entries(
                                                  userData.calendarTable
                                                ).map(([day, data]) => (
                                                  <tr key={day}>
                                                    <td>{day}</td>
                                                    <td>
                                                      <a
                                                        href="#"
                                                        role="button"
                                                        onClick={() =>
                                                          showLogs({
                                                            day: day,
                                                            month: params.month,
                                                            assign_id:
                                                              userData.user_id.match(
                                                                /ObjectId\('(.*)'\)/
                                                              )[1],
                                                          })
                                                        }
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#viewTasks"
                                                      >
                                                        {data.totalWorkedTime}
                                                      </a>
                                                    </td>
                                                  </tr>
                                                ))}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {params.assign_id && (
                        <div className="table-responsive">
                          <table className="table align-middle table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                <th className="align-middle" width="20">
                                  Date
                                </th>
                                <th className="align-middle" width="30">
                                  Worked Time
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {calendarTable &&
                                Object.entries(calendarTable).map(
                                  ([day, workingTime]) => (
                                    <tr key={day}>
                                      <td>{day}</td>
                                      <td>
                                        <a
                                          href="#"
                                          role="button"
                                          onClick={() =>
                                            showLogs({
                                              day: day,
                                              month: params.month,
                                              assign_id: params.assign_id,
                                            })
                                          }
                                          // className="btn btn-primary btn-sm btn-rounded waves-effect waves-light"
                                          data-bs-toggle="modal"
                                          data-bs-target="#viewTasks"
                                        >
                                          {workingTime.totalWorkedTime}
                                        </a>
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="viewTasks"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Task Time Logs
              </h5>
              <button
                type="button"
                // onClick={() => close()}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <table className="table">
                <tbody>
                  {dateWiseLogs &&
                    Object.entries(dateWiseLogs).map(
                      ([ticketInfo, logsArray]) => (
                        <React.Fragment key={ticketInfo}>
                          <td>
                            {ticketInfo.split("\n").map((line, index) => {
                              if (line.includes("title:")) {
                                const title = line
                                  .split("title: ")[1]
                                  .replace(/'/g, "");
                                // Extract working time from the logs data
                                const working_time = logsArray.working_time;
                                return (
                                  <div
                                    className="d-flex justify-content-between"
                                    style={{ margin: "20px 0" }}
                                  >
                                    <h6 key={index}>
                                      <span>Title :</span>
                                      {title}
                                    </h6>
                                    <h6>{working_time}</h6>
                                  </div>
                                );
                              }
                              return null;
                            })}
                          </td>
                          {logsArray.time_logs.map((log, index) => (
                            <tr key={index}>
                              <td>{TimeZone(log.time)}</td>
                              <td>{log.type}</td>
                            </tr>
                          ))}
                        </React.Fragment>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CalendarTable;
