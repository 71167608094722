import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useToast } from "@chakra-ui/toast";
import {
  deletepermission,
  getallpermission,
  updatepermission,
} from "../../api/api";

const ListofPermissions = () => {
  const initialTicketState = {
    Ticket: false,
    Ticket_Create: false,
    Ticket_Update: false,
    Ticket_View: false,
    Ticket_Delete: false,
    Ticket_Priority: false,
    Ticket_Status: false,
    Ticket_Piechart: false,
    Ticket_Groupby: false,
    Ticket_Filter: false,
  };
  const initialProjectState = {
    Project: false,
    Project_Create: false,
    Project_View: false,
    Project_Update: false,
    Project_Delete: false,
  };
  const initialUserState = {
    User: false,
    User_Create: false,
    User_View: false,
    User_Update: false,
    User_Delete: false,
  };
  const initialSprintState = {
    Sprint: false,
    Sprint_Create: false,
    Sprint_View: false,
    Sprint_Update: false,
    Sprint_Delete: false,
    Sprint_Piechart: false,
  };
  const [updateData, setUpdateData] = useState({
    role: "",
    permission: [],
  });
  const [permission, setPermission] = useState([]);
  const [ticketChecked, setTicketChecked] = useState(initialTicketState);
  const [userChecked, setUserChecked] = useState(initialUserState);
   const [sprintChecked, setSprintChecked] = useState(initialSprintState);
  const [projectChecked, setProjectChecked] = useState(initialProjectState);
  const toast = useToast();
  const closeModel = useRef();

  const convertPermissionObject = (permissionObject, convertedObject) => {
    if (permissionObject !== undefined) {
      Object.keys(permissionObject).forEach((entity) => {
        convertedObject[entity] = permissionObject[entity].length > 0;
        permissionObject[entity].forEach((action) => {
          convertedObject[`${entity}_${action}`] = true;
          // if (!convertedObject.permission.includes(entity)) {
          //   convertedObject.permission.push(entity);
          // }
        });
      });
    }

    return convertedObject;
  };

  const generatePermissionArray = (permissions) => {
    const permissionArray = {};

    for (const [key, value] of Object.entries(permissions)) {
      const [entity, action] = key.split("_");

      if (value !== false && action) {
        if (!permissionArray[entity]) {
          permissionArray[entity] = [];
        }

        permissionArray[entity].push(
          action.charAt(0).toUpperCase() + action.slice(1)
        );
      }
    }

    return permissionArray;
  };

  const fetchRoles = async () => {
    try {
      let response = await getallpermission();
      let data = response.data;
      if (data.status === false) {
        toast({
          title: data?.message,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
        throw new Error(data?.message);
      }
      if (data.permission) {
        setPermission(data.permission);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;

    setUpdateData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const updateRole = (perm) => {
   setProjectChecked(initialProjectState);
   setTicketChecked(initialTicketState);
   setUserChecked(initialUserState);
   setSprintChecked(initialSprintState);
    // console.log(convertPermissionObject(perm.permission, initialUserState));
    if (perm.permission.User) {
      
      const convertedUserObject = convertPermissionObject(
        { User: perm.permission.User },
        initialUserState
      );

      let { User, User_Create, User_View, User_Update, User_Delete } =
        convertedUserObject;
      if (!User_Create || !User_View || !User_Update || !User_Delete) {
        setUserChecked({
          ...userChecked,
          ...convertedUserObject,
          User: false,
        });
      } else {
        setUserChecked({
          ...convertedUserObject,
        });
      }
    }
    if (perm.permission.Ticket) {
    
      const convertedTicketObject = convertPermissionObject(
        { Ticket: perm.permission.Ticket },
        initialTicketState
      );

      let {
        Ticket_Create,
        Ticket_Delete,
        Ticket_Update,
        Ticket_View,
        Ticket_Priority,
        Ticket_Status,
      } = convertedTicketObject;
      if (
        !Ticket_Create ||
        !Ticket_Delete ||
        !Ticket_Update ||
        !Ticket_View ||
        !Ticket_Priority ||
        !Ticket_Status
      ) {
        setTicketChecked({
          ...ticketChecked,
          ...convertedTicketObject,
          Ticket: false,
        });
      } else {
        setTicketChecked({
          ...ticketChecked,
          ...convertedTicketObject,
        });
      }
    }
    if (perm.permission.Sprint) {
    
      const convertedSprintObject = convertPermissionObject(
        { Sprint: perm.permission.Sprint },
        initialSprintState
      );

      let {
        Sprint_Create,
        Sprint_Delete,
        Sprint_Update,
        Sprint_View,
        Sprint_Piechart
      } = convertedSprintObject;
      if (
        !Sprint_Create ||
        !Sprint_Delete ||
        !Sprint_Update ||
        !Sprint_View ||
        !Sprint_Piechart
      ) {
        setSprintChecked({
          ...sprintChecked,
          ...convertedSprintObject,
          Sprint: false,
        });
      } else {
        setSprintChecked({
          ...sprintChecked,
          ...convertedSprintObject,
        });
      }
    }
    if (perm.permission.Project) {
     
      const convertedProjectObject = convertPermissionObject(
        { Project: perm.permission.Project },
        initialProjectState
      );

      let { Project_Create, Project_Delete, Project_Update, Project_View } =
        convertedProjectObject;
      if (
        !Project_Create ||
        !Project_Delete ||
        !Project_Update ||
        !Project_View
      ) {
        setProjectChecked({
          ...projectChecked,
          ...convertedProjectObject,
          Project: false,
        });
      } else {
        setProjectChecked({
          ...projectChecked,
          ...convertedProjectObject,
        });
      }
    }

    setUpdateData({ ...perm });
  };

  const ticketHandleChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (name === "Ticket") {
      // Handle "All" checkbox
      const updatedFormData = { ...ticketChecked };
      Object.keys(updatedFormData).forEach((key) => {
        updatedFormData[key] = value;
      });
      setTicketChecked(updatedFormData);
    } else {
      // Handle other checkboxes
      setTicketChecked((prevData) => {
        const updatedData = { ...prevData, [name]: value };

        // Check if all child checkboxes are checked
        const allChildCheckboxesChecked = Object.keys(updatedData)
          .filter((key) => key !== "Ticket")
          .every((key) => updatedData[key]);
        
        // Update "Ticket" checkbox accordingly
        updatedData["Ticket"] = allChildCheckboxesChecked;

        return updatedData;
      });
    }
  };
  const userHandleChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (name === "User") {
      // Handle "All" checkbox
      const updatedFormData = { ...userChecked };
      Object.keys(updatedFormData).forEach((key) => {
        updatedFormData[key] = value;
      });
      setUserChecked(updatedFormData);
    } else {
      // Handle other checkboxes
      setUserChecked((prevData) => {
        const updatedData = { ...prevData, [name]: value };

        // Check if all child checkboxes are checked
        const allChildCheckboxesChecked = Object.keys(updatedData)
          .filter((key) => key !== "User")
          .every((key) => updatedData[key]);
      
        // Update "User" checkbox accordingly
        updatedData["User"] = allChildCheckboxesChecked;

        return updatedData;
      });
    }
  };
  const projectHandleChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (name === "Project") {
      // Handle "All" checkbox
      const updatedFormData = { ...projectChecked };
      Object.keys(updatedFormData).forEach((key) => {
        updatedFormData[key] = value;
      });
      setProjectChecked(updatedFormData);
    } else {
      // Handle other checkboxes
      setProjectChecked((prevData) => {
        const updatedData = { ...prevData, [name]: value };

        // Check if all child checkboxes are checked
        const allChildCheckboxesChecked = Object.keys(updatedData)
          .filter((key) => key !== "Project")
          .every((key) => updatedData[key]);

        // Update "User" checkbox accordingly
        updatedData["Project"] = allChildCheckboxesChecked;

        return updatedData;
      });
    }
  };
 const sprintHandleChange = (e) => {
   const name = e.target.name;
   const value =
     e.target.type === "checkbox" ? e.target.checked : e.target.value;

   if (name === "Sprint") {
     // Handle "All" checkbox
     const updatedFormData = { ...sprintChecked };
     Object.keys(updatedFormData).forEach((key) => {
       updatedFormData[key] = value;
     });
     setSprintChecked(updatedFormData);
   } else {
     // Handle other checkboxes
     setSprintChecked((prevData) => {
       const updatedData = { ...prevData, [name]: value };

       // Check if all child checkboxes are checked
       const allChildCheckboxesChecked = Object.keys(updatedData)
         .filter((key) => key !== "Sprint")
         .every((key) => updatedData[key]);

       // Update "User" checkbox accordingly
       updatedData["Sprint"] = allChildCheckboxesChecked;

       return updatedData;
     });
   }
 };
  const updateRoleHandler = async (e) => {
    e.preventDefault();

    if (updateData.role === "") {
      toast({
        title: "Please Add Role Name.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else {
      try {
        let id = updateData._id;
        let { role_ID, role, status } = updateData;
        let ticketPermission = generatePermissionArray(ticketChecked);
        let userPermission = generatePermissionArray(userChecked);
        let projectPermission = generatePermissionArray(projectChecked);
        let sprintPermission = generatePermissionArray(sprintChecked);

        let permission = {};
        if (Object.keys(ticketPermission).length) {
          permission = { ...ticketPermission };
        }
        if (Object.keys(userPermission).length) {
          permission = { ...permission, ...userPermission };
        }
        if (Object.keys(projectPermission).length) {
          permission = { ...permission, ...projectPermission };
        }
        if (Object.keys(sprintPermission).length) {
          permission = { ...permission, ...sprintPermission };
        }
        
        let response = await updatepermission(id, {
          role_ID,
          role,
          status,
          permission,
        });

        let data = await response.data;
        if (data.status === false) {
          toast({
            title: data?.message,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
          throw new Error(data?.message);
        }
        if (data.status === true) {
          toast({
            title: data.message,
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          setProjectChecked(initialProjectState);
          setTicketChecked(initialTicketState);
          setUserChecked(initialUserState);
          setSprintChecked(initialSprintState);
          setUpdateData({ role: "", permission: [] });
          closeModel.current.click();
          fetchRoles();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const deleteRole = async (id) => {
    const shouldDelete = window.confirm("Are You Sure To Delete This Role ?");

    if (shouldDelete) {
      try {
        const response = await deletepermission(id);
        const data = response.data;
        if (data.status === false) {
          toast({
            title: data?.message,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
          throw new Error(data?.message);
        }
        if (data.status === true) {
          toast({
            title: data.message,
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          fetchRoles();
        }
      } catch (error) {
        console.log(error);
      }
    }

    return;
  };

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">List of Permissions</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="#">Admin</a>
                      </li>
                      <li className="breadcrumb-item active">
                        List of Permissions
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className=" mb-4">
                      <div className="row d-flex justify-content-end">
                        <div className="col-md-2 ">
                          <Link
                            to="/createpermission"
                            className="btn btn-primary  btn-rounded waves-effect waves-light"
                            data-bs-whatever="Create New Company"
                          >
                            <i className="fas fa-plus mx-2"></i>
                            Add New Permission
                          </Link>
                        </div>
                      </div>
                    </div>

                    {permission.length > 0 ? (
                      <>
                        <div className="table-responsive">
                          <table className="table align-middle table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                <th className="align-middle">No.</th>
                                <th className="align-middle">Role</th>
                                {/* <th className="align-middle">User Model</th>
                                <th className="align-middle">Ticket Model</th> */}
                                <th className="align-middle">Permissions</th>

                                {/* <th className="align-middle">Created </th> */}
                                {/* <th className="align-middle">Status</th> */}

                                <th width="250">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {permission.length > 0 &&
                                permission.map((per, index) => (
                                  <tr key={per._id}>
                                    <td className="text-body align-top fw-bold">
                                      {index + 1}
                                    </td>
                                    <td className="align-top">{per.role}</td>
                                    <td>
                                      <ul style={{ paddingLeft: "0.5rem" }}>
                                        {per.permission ? (
                                          Object.keys(per.permission).map(
                                            (entity) => (
                                              <li key={entity}>
                                                {`${entity}: [${per.permission[
                                                  entity
                                                ].join(", ")}]`}
                                              </li>
                                            )
                                          )
                                        ) : (
                                          <h6>No Permissions</h6>
                                        )}
                                      </ul>
                                    </td>
                                    {/* <td>{per.createdAt}</td> */}

                                    {/* <td>
                                      <span
                                        className={`badge badge-pill ${
                                          per.status === true
                                            ? "badge-soft-success"
                                            : "badge-soft-danger"
                                        } font-size-13`}
                                      >
                                        {per.status === false
                                          ? "Disable"
                                          : "Enable"}
                                      </span>
                                    </td> */}

                                    <td className="align-top">
                                      <div className="d-flex ">
                                        <a
                                          href="#"
                                          role="button"
                                          onClick={() => updateRole(per)}
                                          data-bs-toggle="modal"
                                          data-bs-target="#AssignUser"
                                          className="d-inline-block mx-2 "
                                        >
                                          <i
                                            className="fas fa-edit"
                                            style={{ fontSize: "20px" }}
                                          ></i>{" "}
                                        </a>

                                        <a
                                          href="#"
                                          role="button"
                                          onClick={() => deleteRole(per._id)}
                                          className="d-inline-block mx-2"
                                        >
                                          <i
                                            className="fas fa-trash-alt"
                                            style={{ fontSize: "20px" }}
                                          ></i>
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      <h4>No Permissions Found.</h4>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="AssignUser"
          tabIndex="-1"
          aria-labelledby="AssignUserStuday"
          aria-hidden="true"
        >
          <div className="modal-dialog ">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="AssignUserStuday">
                  Update Permission
                </h5>
                <button
                  type="button"
                  ref={closeModel}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={updateRoleHandler}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          htmlFor="role"
                          className="col-form-label font-size-20"
                        >
                          Role :
                          <span className="ms-2 badge badge-pill badge-soft-info">
                            {updateData.role}
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          htmlFor="role-Responsibility"
                          className="col-form-label"
                        >
                          Status :
                        </label>

                        <select
                          id="role-Responsibility"
                          className="form-select"
                          aria-label="Default select example"
                          name="status"
                          onChange={handleChange}
                          value={updateData.status}
                        >
                          <option value="true">Enable</option>
                          <option value="false">Disable</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    {/* <div className="col-md-12 d-flex justify-content-center mb-3"> */}
                    <div className="col-md-4 d-flex justify-content-center">
                      <div className="mb-3">
                        <div>
                          <div className=" form-check">
                            <label
                              className="form-check-label"
                              htmlFor="exampleCheck10"
                            >
                              <h5>User Model</h5>
                            </label>
                            <input
                              name="User"
                              onChange={userHandleChange}
                              type="checkbox"
                              className="form-check-input"
                              id="exampleCheck10"
                              checked={userChecked.User}
                            />
                          </div>
                        </div>
                        <div className=" form-check">
                          <input
                            name="User_Create"
                            onChange={userHandleChange}
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck1"
                            checked={userChecked.User_Create}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheck1"
                          >
                            Create
                          </label>
                        </div>
                        <div className=" form-check">
                          <input
                            name="User_View"
                            onChange={userHandleChange}
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck"
                            checked={userChecked.User_View}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheck"
                          >
                            View
                          </label>
                        </div>
                        <div className=" form-check">
                          <input
                            name="User_Update"
                            onChange={userHandleChange}
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck2"
                            checked={userChecked.User_Update}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheck2"
                          >
                            Update
                          </label>
                        </div>
                        <div className=" form-check">
                          <input
                            name="User_Delete"
                            onChange={userHandleChange}
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck3"
                            checked={userChecked.User_Delete}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheck3"
                          >
                            Delete
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 d-flex justify-content-center">
                      <div className="mb-3">
                        <div>
                          <div className=" form-check">
                            <label
                              className="form-check-label"
                              htmlFor="Project"
                            >
                              <h5>Project Model</h5>
                            </label>
                            <input
                              name="Project"
                              onChange={projectHandleChange}
                              type="checkbox"
                              className="form-check-input"
                              id="Project"
                              checked={projectChecked.Project}
                            />
                          </div>
                        </div>
                        <div className=" form-check">
                          <input
                            name="Project_Create"
                            onChange={projectHandleChange}
                            type="checkbox"
                            className="form-check-input"
                            id="Project_Create"
                            checked={projectChecked.Project_Create}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Project_Create"
                          >
                            Create
                          </label>
                        </div>
                        <div className=" form-check">
                          <input
                            name="Project_View"
                            onChange={projectHandleChange}
                            type="checkbox"
                            className="form-check-input"
                            id="Project_View"
                            checked={projectChecked.Project_View}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Project_View"
                          >
                            View
                          </label>
                        </div>
                        <div className=" form-check">
                          <input
                            name="Project_Update"
                            onChange={projectHandleChange}
                            type="checkbox"
                            className="form-check-input"
                            id="Project_Update"
                            checked={projectChecked.Project_Update}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Project_Update"
                          >
                            Update
                          </label>
                        </div>
                        <div className=" form-check">
                          <input
                            name="Project_Delete"
                            onChange={projectHandleChange}
                            type="checkbox"
                            className="form-check-input"
                            id="Project_Delete"
                            checked={projectChecked.Project_Delete}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Project_Delete"
                          >
                            Delete
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ">
                      <div className="ticket_model mb-3">
                        <div>
                          <div className=" form-check">
                            <label
                              className="form-check-label"
                              htmlFor="sprint"
                            >
                              <h5>Sprint Model</h5>
                            </label>
                            <input
                              name="Sprint"
                              onChange={sprintHandleChange}
                              type="checkbox"
                              className="form-check-input"
                              id="sprint"
                              data-parent-element="sprint_model"
                              data-value="All"
                              checked={sprintChecked.Sprint}
                            />
                          </div>
                        </div>
                        <div className=" form-check">
                          <input
                            name="Sprint_Create"
                            onChange={sprintHandleChange}
                            type="checkbox"
                            className="form-check-input"
                            id="Sprint_Create"
                            checked={sprintChecked.Sprint_Create}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Sprint_Create"
                          >
                            Create
                          </label>
                        </div>
                        <div className=" form-check">
                          <input
                            name="Sprint_View"
                            onChange={sprintHandleChange}
                            type="checkbox"
                            className="form-check-input"
                            id="Sprint_View"
                            checked={sprintChecked.Sprint_View}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Sprint_View"
                          >
                            View
                          </label>
                        </div>
                        <div className=" form-check">
                          <input
                            name="Sprint_Update"
                            onChange={sprintHandleChange}
                            type="checkbox"
                            className="form-check-input"
                            id="Sprint_Update"
                            checked={sprintChecked.Sprint_Update}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Sprint_Update"
                          >
                            Update
                          </label>
                        </div>
                        <div className=" form-check">
                          <input
                            name="Sprint_Delete"
                            onChange={sprintHandleChange}
                            type="checkbox"
                            className="form-check-input"
                            id="Sprint_Delete"
                            checked={sprintChecked.Sprint_Delete}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Sprint_Delete"
                          >
                            Delete
                          </label>
                        </div>
                        <div className=" form-check">
                          <input
                            name="Sprint_Piechart"
                            onChange={sprintHandleChange}
                            type="checkbox"
                            className="form-check-input"
                            id="Sprint_Piechart"
                            checked={sprintChecked.Sprint_Piechart}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Sprint_Piechart"
                          >
                            Piechart
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 d-flex justify-content-center">
                      <div className="mb-3">
                        <div>
                          <div className=" form-check">
                            <label
                              className="form-check-label"
                              htmlFor="exampleCheck11"
                            >
                              <h5>Ticket Model</h5>
                            </label>
                            <input
                              name="Ticket"
                              onChange={ticketHandleChange}
                              type="checkbox"
                              className="form-check-input"
                              id="exampleCheck11"
                              checked={ticketChecked.Ticket}
                            />
                          </div>
                        </div>
                        <div className=" form-check">
                          <input
                            name="Ticket_Create"
                            onChange={ticketHandleChange}
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck4"
                            checked={ticketChecked.Ticket_Create}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheck4"
                          >
                            Create
                          </label>
                        </div>
                        <div className=" form-check">
                          <input
                            name="Ticket_View"
                            onChange={ticketHandleChange}
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck5"
                            checked={ticketChecked.Ticket_View}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheck5"
                          >
                            View
                          </label>
                        </div>
                        <div className=" form-check">
                          <input
                            name="Ticket_Update"
                            onChange={ticketHandleChange}
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck6"
                            checked={ticketChecked.Ticket_Update}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheck6"
                          >
                            Update
                          </label>
                        </div>
                        <div className=" form-check">
                          <input
                            name="Ticket_Delete"
                            onChange={ticketHandleChange}
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck7"
                            checked={ticketChecked.Ticket_Delete}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheck7"
                          >
                            Delete
                          </label>
                        </div>
                        <div className=" form-check">
                          <input
                            name="Ticket_Status"
                            onChange={ticketHandleChange}
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck77"
                            checked={ticketChecked.Ticket_Status}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheck77"
                          >
                            Status
                          </label>
                        </div>
                        <div className=" form-check">
                          <input
                            name="Ticket_Priority"
                            onChange={ticketHandleChange}
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck78"
                            checked={ticketChecked.Ticket_Priority}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheck78"
                          >
                            Priority
                          </label>
                        </div>
                        <div className=" form-check">
                          <input
                            name="Ticket_Piechart"
                            onChange={ticketHandleChange}
                            type="checkbox"
                            className="form-check-input"
                            id="Ticket_Piechart"
                            checked={ticketChecked.Ticket_Piechart}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Ticket_Piechart"
                          >
                            Piechart
                          </label>
                        </div>
                        <div className=" form-check">
                          <input
                            name="Ticket_Groupby"
                            onChange={ticketHandleChange}
                            type="checkbox"
                            className="form-check-input"
                            id="Ticket_Groupby"
                            checked={ticketChecked.Ticket_Groupby}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Ticket_Groupby"
                          >
                            Groupby
                          </label>
                        </div>
                        <div className=" form-check">
                          <input
                            name="Ticket_Filter"
                            onChange={ticketHandleChange}
                            type="checkbox"
                            className="form-check-input"
                            id="Ticket_Filter"
                            checked={ticketChecked.Ticket_Filter}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Ticket_Filter"
                          >
                            Filter
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* </div> */}
                  </div>

                  <div className="text-center mt-4">
                    <button type="submit" className="btn btn-primary">
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListofPermissions;
