import React from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Header from "../pages/Header/Header";
import Footer from "../pages/Footer/Footer";

const PrivateRoute = ({ userDetail, onProjectChange, project }) => {
  const navigate = useNavigate();

  const authorization = localStorage.getItem("rove-token");

  const logCurrentOutlet = () => {
    console.log("Current Outlet: ", window.location.pathname);
  };

  return (
    <>
      <Header onProjectChange={onProjectChange} projectId={ project} />
      <main>
        {authorization ? (
          <Outlet onNavigate={logCurrentOutlet} />
        ) : (
          <Navigate to="/" />
        )}
      </main>
      {/* <Footer></Footer> */}
    </>
  );
};

export default PrivateRoute;
