import React, { useEffect, useState } from "react";
import { getAllNotifications } from "../../api/api";
import timeAgo from "../../utils/helper/timeago";
import queryString from "query-string";
import { useNavigate } from "react-router";

const AllNotification = () => {
  const [notifications, setNotifications] = useState(null);
  const Navigate = useNavigate();

  useEffect(() => {
    fetchNotification();
  }, []);

  async function fetchNotification() {
    try {
      let response = await getAllNotifications();
      let data = response.data;
      if (data.status === false) {
        // throw new Error(data.message);
        console.log(data)
      }
      if (data.notification) {
        setNotifications(data.notification);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">All Notifications</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="#">All Notifications</a>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    {notifications !== null && notifications.length > 0 ? (
                      <>
                        <div className="table-responsive">
                          <table className="table align-middle table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                <th className="align-middle">No.</th>
                                <th className="align-middle">Content</th>
                                <th className="align-middle">Sender</th>

                                <th className="align-middle">Time </th>
                              </tr>
                            </thead>
                            <tbody>
                              {notifications.length > 0 &&
                                notifications.map((noti, index) => (
                                  <tr key={noti._id}>
                                    <td className="text-body fw-bold">
                                      {index + 1}
                                    </td>

                                    <td>
                                      {
                                        <a
                                          title="Click To See Task"
                                          role="button"
                                          onClick={() =>
                                            Navigate(`/listof-study?${queryString.stringify(
                                              {
                                                _id: noti.ticket._id,
                                              }
                                            )}
                                    `)
                                          }
                                        >
                                          {`${noti.name}: ${noti.ticket?.ticket_id}`}
                                        </a>
                                      }
                                    </td>
                                    <td>{`${noti.from.username}`}</td>
                                    <td>{timeAgo(noti.createdAt)}</td>
                                    {/* <td className="align-middle">
                                      <div className="d-flex align-items-center">
                                        <a
                                          href="#"
                                          role="button"
                                          onClick={() => updateRole(role)}
                                          data-bs-toggle="modal"
                                          data-bs-target="#AssignUser"
                                          className="d-inline-block mx-2 "
                                        >
                                          <i
                                            className="fas fa-edit"
                                            style={{ fontSize: "20px" }}
                                          ></i>{" "}
                                        </a>

                                        <a
                                          href="#"
                                          role="button"
                                          onClick={() => deleteRole(role._id)}
                                          className="d-inline-block mx-2"
                                        >
                                          <i
                                            className="fas fa-trash-alt"
                                            style={{ fontSize: "20px" }}
                                          ></i>
                                        </a>
                                      </div>
                                    </td> */}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      <h4>No Notification Found.</h4>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllNotification;
