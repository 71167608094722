import React, { useState, useEffect, useContext } from "react";
import { TicketContext } from "../../utils/context";
import { useNavigate } from "react-router";
import { useToast } from "@chakra-ui/toast";
import useRole from "../../utils/middleware/role";
import { createSprint } from "../../api/api";
import SprintForm from "../../component/SprintForm";

const CreateSprint = () => {
  const toast = useToast();
  const { getRole } = useRole();
  const navigate = useNavigate();
  const { projectId } = useContext(TicketContext);
  const [userDetail, setUserDetail] = useState(null);

  useEffect(() => {
    getRole()
      .then((d) => setUserDetail(d))
      .catch((err) => console.log(err));
  }, []);

  const submitHandler = ({ event, data }) => {
    event.preventDefault();

    createSprint({ ...data })
      .then((res) => {
        if (res.data.status === false) {
          toast({
            title: data?.message,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
          throw new Error(data?.message);
        }
        if (res.data.status===true) {
          toast({
            title: res.data?.message,
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
      })
      .catch((error) => console.log(error));

    let timeout = setTimeout(() => {
      navigate("/list-sprint");
    }, 2000);
    return () => clearTimeout(timeout);
  };

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Create Sprint</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item active">Create Sprint</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <SprintForm onSubmit={submitHandler}></SprintForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSprint;
