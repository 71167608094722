import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  SprintDelete,
  SprintUpdate,
  fetchSprint,
  getAllProjects,
  getalltickets,
} from "../../api/api";
import { useToast } from "@chakra-ui/toast";
import queryString from "query-string";
import Select from "react-select";
import { TicketContext } from "../../utils/context";
import useRole from "../../utils/middleware/role";
import TimeZone from "../../utils/helper/timezone";
import usePermission from "../../utils/middleware/usePermission";
const allAccess = ["Admin", "Team Leader"];

const ListofSprint = () => {
  const [updateData, setUpdateData] = useState({
    tasks: [],
    startDate: "",
    endDate: "",
    name: "",
  });
  const minDate = new Date().toISOString().split(".")[0];
  const [sprint, setSprint] = useState([]);
  const [userDetail, setUserDetail] = useState(null);
  const toast = useToast();
  const closeModalRef = useRef();
  const { getRole } = useRole();
  const [allTask, setAllTask] = useState([]);
  const [projects, setProjects] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const { projectId, projectLength, setFilterTicket } =
    useContext(TicketContext);
  const location = useLocation();
  const [formData, setFormData] = useState({ project: "" });
  // const optionList = updateData.tasks.map((t) => {
  //   return { value: t._id, label: t.title };
  // });
  let param = { project: projectId };

  // if (!projectId) {
  //   setSprint([])
  // }
  function handleSelect(data) {
    setUpdateData({ ...updateData, tasks: data });
  }

  const inputChangeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getRole()
      .then((d) => setUserDetail(d))
      .catch((err) => console.log(err));
  }, []);

  const { hasSprintDelete, hasSprintUpdate, hasSprintCreate } =
    usePermission(userDetail);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchSprints = async (param) => {
    try {
      let response = await fetchSprint(param);
      let data = response.data;
      if (data.status === false) {
        toast({
          title: data?.message,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
        throw new Error(data?.message);
      }
      if (data.sprint) {
        setFilterTicket(data.sprint);
        setSprint(data.sprint);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function fetchProjects() {
    try {
      let response = await getAllProjects();
      let data = response.data;
      if (data.status === false) {
        console.log(data?.message);
      }
      if (data.project) {
        setProjects(data.project);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (
      userDetail &&
      !allAccess.includes(userDetail.role) &&
      projectLength &&
      projectLength > 1 &&
      !projectId
    ) {
      toast({
        title: "Please, Select Project.",
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
    if (location && location.search) {
      let parsedData = queryString.parse(location.search);
      param.delayTask = parsedData.delayTask;
    }
    if (formData.project) {
      param.project = formData.project;
    }
    if (userDetail) {
      fetchSprints(param);
      // fetchTicket(param);
    }
  }, [projectId, userDetail, projectLength, formData, location]);

  const fetchTicket = async (param) => {
    try {
      const res = await getalltickets(param);
      let data = res.data;
      if (data.status === false) {
        throw new Error(data?.message);
      }
      if (data.tickets) {
        let nonSprintTicket = data.tickets.filter((t) => {
          return t.sprint === null;
        });

        setAllTask(nonSprintTicket);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setUpdateData({
      ...updateData,
      [e.target.name]: e.target.value,
    });
  };

  const updateRole = (task) => {
    if (formData.project) {
      param.project = formData.project;
    }
    fetchTicket(param);
    let tasks = task.tasks.map((t) => {
      return { value: t._id, label: t.title };
    });
    setUpdateData({ ...task, tasks });
  };

  const updateRoleHandler = async (e) => {
    e.preventDefault();

    if (updateData.name.trim().length < 3) {
      toast({
        title: "Name Must Be 3 Character.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else {
      try {
        let id = updateData._id;
        if (selectedOptions === null) {
          let value = updateData.tasks?.map((t) => {
            return t.value;
          });

          let response = await SprintUpdate(id, {
            ...updateData,
            tasks: value,
          });

          let data = response.data;
          if (data.status === false) {
            toast({
              title: data?.message,
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
            throw new Error(data?.message);
          }
          if (data.status === true) {
            toast({
              title: data.message,
              status: "success",
              duration: 2000,
              isClosable: true,
            });
            closeModalRef.current.click();
            if (formData.project) {
              param.project = formData.project;
            }
            fetchSprints(param);
            // fetchTicket(param);
          }
        } else {
          toast({
            title: "Please Select Task.",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const deleteRole = async (id) => {
    const shouldDelete = window.confirm("Are You Sure To Delete This Sprint ?");

    if (shouldDelete) {
      try {
        const response = await SprintDelete(id);
        const data = response.data;
        if (data.status === false) {
          toast({
            title: data?.message,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
          throw new Error(data?.message);
        }
        if (data.status === true) {
          toast({
            title: data.message,
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          fetchSprints(param);
        }
      } catch (error) {
        toast({
          title:
            error.response.data.error ||
            "Network error or something went wrong",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
    }

    return;
  };

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">List of Sprints</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      {/* <Link
                        to="/genpdf"
                        className="btn btn-primary  btn-rounded waves-effect waves-light mb-2"
                        data-bs-whatever="Create New Company"
                      >
                        <i className="fas fa-file-excel mx-2"></i>
                        Generate XL sheet
                      </Link> */}
                      <Link to="/dashboard" className="btn btn-primary">
                        Back{" "}
                      </Link>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    {userDetail && allAccess.includes(userDetail.role) && (
                      <div className=" mb-4">
                        <div className="row d-flex justify-content-between">
                          <div className="col-md-1 col-xl-1 ">
                            {userDetail &&
                              allAccess.includes(userDetail.role) && (
                                <div className="row">
                                  <label
                                    htmlFor="project"
                                    className="col-form-label"
                                  >
                                    Filter By
                                  </label>
                                  <select
                                    id="project"
                                    className="form-select "
                                    aria-label="Default select example"
                                    name="project"
                                    onChange={inputChangeHandler}
                                    value={formData.project}
                                  >
                                    <option defaultValue value="">
                                      Project
                                    </option>
                                    {projects !== null &&
                                      projects.map((p) => (
                                        <option value={p._id} key={p._id}>
                                          {p.projectName}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              )}
                          </div>
                          {userDetail && hasSprintCreate && (
                            <div className="col-md-2 ">
                              <Link
                                to="/create-sprint"
                                className="btn btn-primary  btn-rounded waves-effect waves-light"
                                data-bs-whatever="Create New Company"
                              >
                                <i className="fas fa-plus mx-2"></i>
                                Add New Sprint
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {sprint?.length > 0 ? (
                      <>
                        <div className="table-responsive">
                          <table className="table align-middle table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                <th className="align-middle">No.</th>
                                <th className="align-middle">Sprint Name</th>
                                <th className="align-middle">Task</th>
                                <th className="align-middle">Created By</th>
                                <th className="align-middle">Project</th>
                                {userDetail &&
                                  (hasSprintDelete || hasSprintUpdate) && (
                                    <th className="align-middle" width="80">
                                      Action
                                    </th>
                                  )}
                                <th className="align-middle">Start Date </th>
                                <th className="align-middle">
                                  Expected End Date
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {sprint.length > 0 &&
                                sprint.map((sp, index) => (
                                  <tr key={sp._id}>
                                    <td className="text-body fw-bold">
                                      {index + 1}
                                    </td>
                                    <td>{sp.name}</td>
                                    <td>
                                      {sp.tasks.map((t) => (
                                        <li key={t._id} title={t.title}>
                                          {t.title.length > 35
                                            ? `${t.title.slice(0, 35)}...`
                                            : t.title}
                                        </li>
                                      ))}
                                    </td>
                                    <td>{sp?.user?.username}</td>
                                    <td>{sp?.project?.projectName}</td>

                                    {userDetail &&
                                      (hasSprintUpdate || hasSprintDelete) && (
                                        <td className="align-middle">
                                          <div className="d-flex align-items-center">
                                            {userDetail && hasSprintUpdate && (
                                              <a
                                                href="#"
                                                role="button"
                                                onClick={() => updateRole(sp)}
                                                data-bs-toggle="modal"
                                                data-bs-target="#updateSprint"
                                                className="d-inline-block me-2 "
                                              >
                                                <i
                                                  className="fas fa-edit"
                                                  style={{ fontSize: "20px" }}
                                                ></i>{" "}
                                              </a>
                                            )}

                                            {userDetail && hasSprintDelete && (
                                              <a
                                                href="#"
                                                role="button"
                                                onClick={() =>
                                                  deleteRole(sp._id)
                                                }
                                                className="d-inline-block "
                                              >
                                                <i
                                                  className="fas fa-trash-alt"
                                                  style={{ fontSize: "20px" }}
                                                ></i>
                                              </a>
                                            )}
                                          </div>
                                        </td>
                                      )}

                                    <td>{TimeZone(sp.startDate)}</td>
                                    <td>{TimeZone(sp.endDate)}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      <h4>No Sprint Found.</h4>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="updateSprint"
          tabIndex="-1"
          aria-labelledby="AssignUserStuday"
          aria-hidden="true"
        >
          <div className="modal-dialog ">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="AssignUserStuday">
                  Update Sprint
                </h5>
                <button
                  ref={closeModalRef}
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={updateRoleHandler}>
                  <div className="row">
                    {userDetail && allAccess.includes(userDetail.role) && (
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="role" className="col-form-label">
                            Name :
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            id="role"
                            onChange={handleChange}
                            value={updateData.name}
                          ></input>
                        </div>
                      </div>
                    )}
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="role-email1" className="col-form-label">
                          Sprint Task:
                        </label>
                        <Select
                          options={allTask.map((t) => {
                            return { value: t._id, label: t.title };
                          })}
                          placeholder="Select Tasks"
                          value={updateData.tasks}
                          onChange={handleSelect}
                          isMulti
                        />
                      </div>
                    </div>
                    {userDetail && allAccess.includes(userDetail.role) && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="start" className="col-form-label">
                              Starting Date
                            </label>
                            <input
                              type="datetime-local"
                              name="startDate"
                              id="start"
                              className="form-control"
                              onChange={handleChange}
                              value={updateData.startDate.slice(0, 16)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="role-pass"
                              className="col-form-label"
                            >
                              Ending Date:
                            </label>
                            <input
                              onChange={handleChange}
                              name="endDate"
                              type="datetime-local"
                              className="form-control"
                              id="role-pass"
                              value={updateData.endDate.slice(0, 16)}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="text-center mt-4">
                    <button type="submit" className="btn btn-primary">
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListofSprint;
