import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  counterTicket,
  delaySprints,
  delayTask,
  fetchSprint,
  freeUserGet,
  getAllProjects,
} from "../../api/api";
import queryString from "query-string";
import { useToast } from "@chakra-ui/toast";
import useRole from "../../utils/middleware/role";
import { TicketContext } from "../../utils/context";
import usePermission from "../../utils/middleware/usePermission";
import TimeZone from "../../utils/helper/timezone";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import PieChart from "../../component/PieChart";

Chart.register(CategoryScale);

const Dashbord = () => {
  const [userDetail, setUserDetail] = useState(null);
  const [date, setDate] = useState({ start: "", end: "", adminProject: "" });
  const [counter, setCounter] = useState({
    open: 0,
    running: 0,
    resolved: 0,
    hold: 0,
    total: 0,
  });
  const [delayCount, setDelayCount] = useState(0);
  const [delaySprintCount, setDelaySprintCount] = useState(0);
  const [projectCounter, setProjectCounter] = useState(null);
  const [projectList, setProjectList] = useState(null);
  const [freeUser, setFreeUser] = useState(null);
  const [sprintCounter, setSprintCounter] = useState(null);
  const { projectId, projectLength } = useContext(TicketContext);
  const navigate = useNavigate();
  const toast = useToast();
  const { getRole } = useRole();
  let filterParams = {};
  let param = { project: projectId };

  const fetchFreeUsers = async () => {
    try {
      let res = await freeUserGet();
      let data = res.data;
      if (data.status) {
        setFreeUser(data.user);
      } else {
        console.log(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDelayTask = async () => {
    try {
      const res = await delayTask();
      if (res.data.result) {
        setDelayCount(res.data.result);
      } else {
        console.log(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getDelaySprint = async () => {
    try {
      const res = await delaySprints();
      if (res.data.result) {
        setDelaySprintCount(res.data.result);
      } else {
        console.log(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const memoizedFetchUser = useMemo(() => fetchFreeUsers, []);

  useEffect(() => {
    getRole()
      .then((d) => setUserDetail(d))
      .catch((err) => console.log(err));
    fetchAllProject();
    memoizedFetchUser();
  }, []);

  // if (
  //   userDetail !== null &&
  //   userDetail.role !== "Admin" &&
  //   userDetail.role !== "Team Leader"
  // ) {
  //   navigate("/listoftickets");
  // }
  const { allPermission,hasSprintPiechart,hasTicketPiechart } = usePermission(userDetail);
  let task = "Task"
  const Dispatcher =  userDetail && (userDetail.role === "Dispatcher" || userDetail.role === "Dispatcher Manager")
  if (Dispatcher) {
    task = "Ticket"
  }
  const chart_data = [
    {
      task: "Open",
      counter: counter.open,
    },
    {
      task: "Running",
      counter: counter.running,
    },
    {
      task: "In Progress",
      counter: counter.hold,
    },
    {
      task: "Resolved",
      counter: counter.resolved,
    },
  ];

  const fetchAllProject = async () => {
    try {
      const res = await getAllProjects();
      let data = res.data;
      if (data.project) {
        setProjectList(data.project);
      } else {
        console.log(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let Dynamicdata = {
    labels: chart_data.map((data) => data.task),
    datasets: [
      {
        label: "Tasks",
        data: chart_data.map((data) => data.counter),
        backgroundColor: [
          // "#ec222b",
          "#0068c8",
          "#ffcf3a",
          "#ec2e1d",
          "#67d479",
          // "#6ef345"
        ],
        // borderColor: "black",
        borderWidth: 0,
      },
    ],
  };

  const [chartData, setChartData] = useState(Dynamicdata);

  const filterCheck = (date, projectId) => {
    if (date.adminProject) {
      filterParams.project = date.adminProject;
    }
    if (date.start) {
      filterParams.start = date.start;
    }
    if (date.end) {
      filterParams.end = date.end;
    }
    if (projectId) {
      filterParams.project = projectId;
    }
    return filterParams;
  };

  var filterForCounter;
  useEffect(() => {
    if (
      userDetail !== null &&
      userDetail.role !== "Admin" &&
      userDetail.role !== "Team Leader" &&
      projectLength &&
      projectLength > 1 &&
      !projectId
    ) {
      toast({
        title: "Please, Select Project.",
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
    if (userDetail) {
      filterForCounter = filterCheck(date, projectId);
      FetchSprints();
      fetchTickets(filterForCounter);
      getDelayTask();
      getDelaySprint();
    }
  }, [projectId, userDetail, projectLength, date]);

  function mixSprintCounter(sprint, counter) {
    let newCounter = counter.map((count, index) => {
      count.projectName = sprint[index].project.projectName;
      count._id = sprint[index]._id;
      count.endDate = sprint[index].endDate;
      return count;
    });
    return newCounter;
  }

  async function FetchSprints() {
    try {
      let response;
      let fil = {};
      if (projectId) {
        fil.project = projectId;
      }
      if (date.adminProject) {
        fil.project = date.adminProject;
      }

      response = await fetchSprint(fil);
      let data = response.data;
      if (data.status === false) {
        // throw new Error(data.message);
        console.log(data)
      }
      if (data.counter) {
        data.sprint.map((sp) => {
          sp.endDate = TimeZone(sp.endDate);
          return sp;
        });
        let c = mixSprintCounter(data.sprint, data.counter);

        setSprintCounter(c);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const fetchTickets = async (param) => {
    try {
      let response;
      // if (projectId) {
      //   response = await counterTicket(param);
      // }
      response = await counterTicket(param);

      let data = response.data;
      if (data.status === false) {
        // throw new Error(data.message);
        console.log(data)
      }
      if (data.counter) {
        setProjectCounter(data.counter);
        let x = data.count;
        let tR = data.totalresult;
        const chart_data = [
          {
            task: "Open",
            counter: x.open,
          },
          {
            task: "Running",
            counter: x.running,
          },
          {
            task: "In Progress",
            counter: x.hold,
          },
          {
            task: "Resolved",
            counter: x.resolved,
          },
        ];
        Dynamicdata = {
          labels: chart_data.map((data) => data.task),
          datasets: [
            {
              label: task,
              data: chart_data.map((data) => data.counter),
              backgroundColor: [
                // "#ec222b",
                "#0068c8",
                "#ffcf3a",
                "#ec2e1d",
                "#67d479",
                // "#6ef345",
              ],
              // borderColor: "black",
              borderWidth: 0,
            },
          ],
        };
        setChartData(Dynamicdata);
        setCounter({ ...counter, ...x, total: tR });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const dateHandleChange = (e) => {
    setDate({ ...date, [e.target.name]: e.target.value });
  };

  filterForCounter = filterCheck(date, projectId);
  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">{`${task} Counter`}</h4>

                  {userDetail&& allPermission && (
                    <>
                      <div className="row">
                        <div className="col-md-3 me-3 col-12">
                          <label
                            htmlFor="role-priority"
                            className="col-form-label"
                          >
                            Project
                          </label>
                          <select
                            id="role-priority"
                            className="form-select"
                            aria-label="Default select example"
                            name="adminProject"
                            style={{
                              height: "2.2rem",
                              width: "9rem",
                            }}
                            // onChange={handleChange}
                            onChange={dateHandleChange}
                            value={date.adminProject}
                          >
                            <option defaultValue value="">
                              All Projects
                            </option>
                            {projectList &&
                              projectList.map((p) => (
                                <option value={p._id} key={p._id}>
                                  {p.projectName}
                                </option>
                              ))}
                          </select>
                        </div>

                        <div className="col-md-3">
                          <label htmlFor="start" className="col-form-label">
                            Starting Date
                          </label>
                          <input
                            type="date"
                            name="start"
                            id="start"
                            className="form-control"
                            onChange={dateHandleChange}
                            value={date.start}
                          />
                        </div>
                        <div className="col-md-3">
                          <label htmlFor="end" className="col-form-label">
                            Ending Date
                          </label>
                          <input
                            type="date"
                            name="end"
                            id="end"
                            className="form-control"
                            onChange={dateHandleChange}
                            value={date.end}
                          />
                        </div>

                        <div className="col-md-2 d-flex align-items-end mt-3">
                          <button
                            className="btn btn-secondary ms-2 "
                            type="button"
                            onClick={() => window.location.reload()}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item active">
                        <a href="#">{`${task} Counter`}</a>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  {/* <div className="mb-4 ms-4">
                    <div className="row">
                      {allPermission && (
                        <div className="col-md-3">
                          <label
                            htmlFor="role-priority"
                            className="col-form-label"
                          >
                            Project
                          </label>
                          <select
                            id="role-priority"
                            className="form-select"
                            aria-label="Default select example"
                            name="adminProject"
                            style={{
                              height: "2.2rem",
                              width: "9rem",
                            }}
                            // onChange={handleChange}
                            onChange={dateHandleChange}
                            value={date.adminProject}
                          >
                            <option defaultValue value="">
                              All Projects
                            </option>
                            {projectList &&
                              projectList.map((p) => (
                                <option value={p._id} key={p._id}>
                                  {p.projectName}
                                </option>
                              ))}
                          </select>
                        </div>
                      )}
                      <div className="col-md-3">
                        <label htmlFor="start" className="col-form-label">
                          Starting Date
                        </label>
                        <input
                          type="date"
                          name="start"
                          id="start"
                          className="form-control"
                          onChange={dateHandleChange}
                          value={date.start}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="end" className="col-form-label">
                          Ending Date
                        </label>
                        <input
                          type="date"
                          name="end"
                          id="end"
                          className="form-control"
                          onChange={dateHandleChange}
                          value={date.end}
                        />
                      </div>

                      <div className="col-md-3 d-flex align-items-end ">
                        <button
                          className="btn btn-secondary ms-2 "
                          type="button"
                          onClick={() => window.location.reload()}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <div className="card mini-stats-wid">
                      <Link to="/listoftickets">
                        <div className="card-body ">
                          <div className="text-center">
                            <h2 className="font-size-24 text-primary">
                              Total {`${task}`}
                              {/* <i className="mdi mdi-ticket-confirmation-outline ms-1"></i> */}
                              <br /> {counter.total}
                            </h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  {userDetail && allPermission && (
                    <div className="col-md-6">
                      <div className="card mini-stats-wid">
                        <a
                          href="#"
                          role="button"
                          data-bs-toggle="modal"
                          data-bs-target="#vlewLogs"
                        >
                          <div className="card-body">
                            <div className="text-center">
                              <h2 className=" font-size-24 text-primary">
                                Non Working User <br />
                                {freeUser && (freeUser.length ?? 0)}
                              </h2>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  )}
                  <div className="col-md-6">
                    <div className="card mini-stats-wid">
                      <Link
                        to={
                          filterForCounter
                            ? `/listoftickets?${queryString.stringify({
                                ...filterForCounter,
                                status: "Open",
                              })}`
                            : `/listoftickets?${queryString.stringify({
                                status: "Open",
                              })}`
                        }
                      >
                        <div className="card-body">
                          <div className="text-center">
                            <h2 className=" font-size-24 text-primary">
                              Open {`${task}`} <br /> {counter.open}
                            </h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card mini-stats-wid">
                      <Link
                        to={
                          filterForCounter
                            ? `/listoftickets?${queryString.stringify({
                                ...filterForCounter,
                                status: "Running",
                              })}`
                            : `/listoftickets?${queryString.stringify({
                                status: "Running",
                              })}`
                        }
                      >
                        <div className="card-body">
                          <div className="text-center">
                            <h2 className=" font-size-24 text-primary">
                              Running {`${task}`} <br /> {counter.running}
                            </h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card mini-stats-wid">
                      <Link
                        to={
                          filterForCounter
                            ? `/listoftickets?${queryString.stringify({
                                status: "In Progress",
                                ...filterForCounter,
                              })}`
                            : `/listoftickets?${queryString.stringify({
                                status: "In Progress",
                              })}`
                        }
                      >
                        <div className="card-body">
                          <div className="text-center">
                            <h2 className=" font-size-24 text-primary">
                              In Progress {`${task}`} <br /> {counter.hold}
                            </h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card mini-stats-wid">
                      <Link
                        to={
                          filterForCounter
                            ? `/listoftickets?${queryString.stringify({
                                status: "Resolved",
                                ...filterForCounter,
                              })}`
                            : `/listoftickets?${queryString.stringify({
                                status: "Resolved",
                              })}`
                        }
                      >
                        <div className="card-body">
                          <div className="text-center">
                            <h2 className=" font-size-24 text-primary">
                              Finished {`${task}`} <br />
                              {counter.resolved}
                            </h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  {userDetail && allPermission && (
                    <>
                      <div className="col-md-6">
                        <div className="card mini-stats-wid">
                          <Link
                            to={`/listoftickets?${queryString.stringify({
                              delayTasks: 1,
                            })}`}
                          >
                            <div className="card-body">
                              <div className="text-center">
                                <h2 className=" font-size-24 text-primary">
                                  Delay {`${task}`} <br />
                                  {delayCount}
                                </h2>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card mini-stats-wid">
                          <Link
                            to={`/list-sprint?${queryString.stringify({
                              delayTask: delaySprintCount,
                            })}`}
                          >
                            <div className="card-body">
                              <div className="text-center">
                                <h2 className=" font-size-24 text-primary">
                                  Delay Sprints <br />
                                  {delaySprintCount}
                                </h2>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                {/* <Chart
                  chartType="PieChart"
                  data={data}
                  options={options}
                  width={"100%"}
                  height={"300px"}
                /> */}
                <div className="card card-danger">
                  <div className="card-header">
                    <h3 className="card-title">{`${task}`}</h3>
                  </div>
                  <div className="card-body">
                    <PieChart
                      chartData={chartData}
                      task={task}
                      options={{ maintainAspectRatio: false }}
                    />
                  </div>
                </div>
              </div>

              {projectCounter && (projectCounter.length > 1) && (
                <div className="row">
                  <h3 className="text-center my-5">
                    <span
                      style={{
                        backgroundColor: "#003f55",
                        padding: "0.6rem",
                        borderRadius: "10px",
                        color: "whitesmoke",
                      }}
                    >
                      Tasks Chart
                    </span>
                  </h3>
                  {projectCounter !== null &&
                    projectCounter?.map((counter, index) => {
                      let { open, running, resolved, hold } = counter?.count;
                      const chart_data = [
                        {
                          task: "Open",
                          counter: open,
                        },
                        {
                          task: "Running",
                          counter: running,
                        },
                        {
                          task: "In Progress",
                          counter: hold,
                        },
                        {
                          task: "Resolved",
                          counter: resolved,
                        },
                      ];
                      const Dynamicdata = {
                        labels: chart_data.map((data) => data.task),
                        datasets: [
                          {
                            label: "Tasks",
                            data: chart_data.map((data) => data.counter),
                            backgroundColor: [
                              // "#ec222b",
                              "#0068c8",
                              "#ffcf3a",
                              "#ec2e1d",
                              "#67d479",
                              // "#6ef345",
                            ],
                            // borderColor: "black",
                            borderWidth: 0,
                          },
                        ],
                      };
                      return open || running || resolved || hold ? (
                        <div className="col-md-4 col-xl-4" key={index}>
                          <div className="card card-danger">
                            <div className="card-header">
                              <h3 className="card-title">
                                {counter.projectName}
                              </h3>
                            </div>
                            <div className="card-body" style={{ padding: "0" }}>
                              <PieChart
                                chartData={Dynamicdata}
                                options={{ maintainAspectRatio: false }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-4" key={counter?._id}>
                          <h4 style={{ marginTop: "20px" }}>
                            Sprint Name :{counter.sprintName}
                          </h4>
                          <strong>Project : {counter.projectName}</strong>
                          <p>No Ticket In This Sprint.</p>
                        </div>
                      );
                    })}
                </div>
              )}
              {sprintCounter &&
                sprintCounter.length > 0 &&
                userDetail &&
                (hasSprintPiechart)
                /* userDetail.role !== "Dispatcher" &&
                userDetail.role !== "Dispatcher Manager" */ && (
                  <div className="row">
                    <h3 className="text-center my-5">
                      {" "}
                      <span
                        style={{
                          backgroundColor: "#003f55",
                          padding: "0.6rem",
                          borderRadius: "10px",
                          color: "whitesmoke",
                        }}
                      >
                        Sprint Chart
                      </span>
                    </h3>
                    {sprintCounter !== null &&
                      sprintCounter?.map((counter) => {
                        let { open, running, resolved, hold } =
                          counter?.taskCount;
                        const chart_data = [
                          {
                            task: "Open",
                            counter: open,
                          },
                          {
                            task: "Running",
                            counter: running,
                          },
                          {
                            task: "In Progress",
                            counter: hold,
                          },
                          {
                            task: "Resolved",
                            counter: resolved,
                          },
                        ];
                        const Dynamicdata = {
                          labels: chart_data.map((data) => data.task),
                          datasets: [
                            {
                              label: "Tasks",
                              data: chart_data.map((data) => data.counter),
                              backgroundColor: [
                                // "#ec222b",
                                "#0068c8",
                                "#ffcf3a",
                                "#ec2e1d",
                                "#67d479",
                                // "#6ef345",
                              ],
                              borderColor: "black",
                              borderWidth: 0,
                            },
                          ],
                        };

                        return (
                          (open || running || resolved || hold) && (
                            <div
                              className="col-md-4 col-md-4"
                              key={counter?._id}
                            >
                              <div className="card card-danger">
                                <div className="card-header">
                                  <h3 className="card-title">
                                    {counter.sprintName}
                                  </h3>
                                  <h3 className="card-title">
                                    {`End Date : ${counter.endDate}`}
                                  </h3>
                                </div>
                                <div
                                  className="card-body"
                                  style={{ padding: "0" }}
                                >
                                  <PieChart
                                    chartData={Dynamicdata}
                                    options={{ maintainAspectRatio: false }}
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        );
                        // ) : (
                        //   <span key={counter?._id}>{""}</span>
                        //   // <div className="col-md-4" key={counter?._id}>
                        //   //   <h4 style={{ marginTop: "20px" }}>
                        //   //     Sprint :{counter.sprintName}
                        //   //   </h4>
                        //   //   <strong>Project : {counter.projectName}</strong>{" "}
                        //   //   <br />
                        //   //   <strong>
                        //   //     End Date : {counter.endDate?.slice(0, 19)}
                        //   //   </strong>
                        //   //   <p>No Tasks In This Sprint.</p>
                        //   // </div>
                        // );
                      })}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="vlewLogs"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Non Running Task User
              </h5>
              <button
                type="button"
                // onClick={() => close()}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">No.</th>
                    <th scope="col">Username</th>
                  </tr>
                </thead>
                <tbody>
                  {freeUser && freeUser.length !== 0 ? (
                    freeUser.map((u, index) => {
                      return (
                        <tr key={u._id}>
                          <th scope="row">{index + 1}</th>
                          <td>{u.username}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>No Free Users</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashbord;
