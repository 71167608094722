import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useToast } from "@chakra-ui/toast";
import useRole from "../../utils/middleware/role";
import api from "../../api/index";
import timeAgo from "../../utils/helper/timeago";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  readmessage,
  sendmessage,
  updateticket,
  deleteMessage,
  fetchallusers,
  updateupload,
  fileDelete,
  fetchlogs,
  sendNotification,
  getworkingticket,
  updateTicketStatus,
  showTimelogs,
} from "../../api/api";
import { viewticket } from "../../api/api";
import usePermission from "../../utils/middleware/usePermission";
import { CircularProgress } from "@chakra-ui/react";
import TimeZone from "../../utils/helper/timezone";
const allAccess = ["Admin", "Team Leader"];

const ListOfStudy = () => {
  const fileInputRef = React.createRef();
  const fileInputRef2 = React.createRef();
  const location = useLocation();
  const [ticket, setTicket] = useState({});
  const [user, setUsers] = useState([]);
  const [logs, setLogs] = useState([]);
  const [status, setStatus] = useState("");
  const [priority, setPriority] = useState("");
  const [assign_id, setAssign] = useState({ _id: "", username: "" });
  const [message, setMessage] = useState({
    ticketId: "",
    message: "",
    file: [],
  });
  const [error, setError] = useState({});
  const [ids, setId] = useState("");
  const didMountRef = useRef(false);
  const didMount = useRef(false);
  const didM = useRef(false);
  const [sfirst, setSfirst] = useState(false);
  const [pfirst, setPfirst] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allMessage, setAllMessage] = useState([]);
  const [file, setFile] = useState([]);
  const [userDetail, setUserDetail] = useState(null);
  const toast = useToast();
  let parsedData;
  const [proFirst, setProFirst] = useState(false);
  const [inputValue, setInputValue] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const dotRef = useRef(null);
  const navigate = useNavigate();
  const fileUploadRef = useRef();
  const modalCloseRef = useRef();

  const [modalUpload, setModalUpload] = useState(false);

  //  const handleProgressBarClick = (event) => {
  //    const progressBar = event.currentTarget;
  //    const clickPosition =
  //      event.clientX - progressBar.getBoundingClientRect().left;
  //    const percentage = (clickPosition / progressBar.offsetWidth) * 100;
  //    const newValue = Math.round(Math.min(Math.max(0, percentage), 101)); // Ensure the value is within [0, 100]
  //    setInputValue(newValue);
  //  };

  const handleFileMessageChange = (e) => {
    setMessage({ ...message, file: e.target.files });
  };

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (isDragging && dotRef.current) {
        const progressBar = dotRef.current.parentElement;
        const clickPosition =
          event.clientX - progressBar.getBoundingClientRect().left;
        const percentage = (clickPosition / progressBar.offsetWidth) * 100;
        const newValue = Math.round(Math.min(Math.max(0, percentage), 100));
        setInputValue(newValue);
      }
    };
    const handleMouseUp = () => {
      setIsDragging(false);
    };
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  useEffect(() => {
    if (location && location.search) {
      // Parse the query string from the URL
      parsedData = queryString.parse(location.search);

      setId(parsedData._id);
      // setId(id);
    }
  }, [location]);
  const { getRole } = useRole();

  useEffect(() => {
    showticket(ids);
    setStatus(ticket.status);
    setPriority(ticket.priority);
  }, [ids]);

  useEffect(() => {
    getRole()
      .then((d) => setUserDetail(d))
      .catch((err) => console.log(err));
  }, []);

  const { hasTicketStatus, hasTicketPriority } = usePermission(userDetail);

  const handleFileChange = (e, id) => {
    setFile(e.target.files);
    setId(id);
  };
  let task = "Task";
  const Dispatcher =
    userDetail &&
    (userDetail.role === "Dispatcher" ||
      userDetail.role === "Dispatcher Manager");
  const Client = userDetail && userDetail.role === "Client";
  if (Dispatcher || Client) {
    task = "Ticket";
  }

  const createNotification = async (data) => {
    try {
      let response = await sendNotification(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileUpload = async (e, id, ticket) => {
    e.preventDefault();

    try {
      if (file.length !== 0) {
        let form = new FormData();
        form.append("ticket_id", ticket);
        for (let i = 0; i < file.length; i++) {
          form.append("files", file[i]);
        }
        let response = await updateupload(id, form);
        let data = response.data;
        if (data.status === false) {
          toast({
            title: data?.message,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
          // throw new Error(data?.message);
          console.log(data);
        }
        if (data.status === true) {
          toast({
            title: data.message,
            description: "We've added this file in this ticket.",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          modalCloseRef.current.click();
          setModalUpload(false);
          setFile([]);
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          showticket(ids);
        }
      } else {
        toast({
          title: "Please add a File.",
          status: "warning",
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.message) {
        toast({
          title: error.response.data.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  const updateStatus = async (id, status) => {
    try {
      let response;
      if (status && status.length) {
        if (status === "Resolved") {
          const shouldUpload = await Swal.fire({
            title: "Do you want to upload attachment with a Task ?",
            icon: "question",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonText: "Okay",
          });
          if (shouldUpload.isConfirmed) {
            fileUploadRef.current.click();
          }
          response = await updateTicketStatus(id, { status: status });
          let data = {
            ticket: id,
            name: `Task Status Changed to ${status}`,
          };
          var statusNotification = await createNotification(data);
        } else {
          if (status === "Running") {
            let res = await getworkingticket({ status });
            let data = res.data;

            if (
              data.status === true &&
              userDetail &&
              data.ticket.assign_id._id == userDetail.loginUser._id
            ) {
              if (data.status === true) {
                let ticket = data.ticket;
                let shouldHold = await Swal.fire({
                  title: `You Have Already Running Task ${ticket.title} First You Need To Hold This Task.`,
                  text: "Are you sure to hold this task ?",
                  icon: "warning",
                  showCancelButton: true,
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Okay",
                });

                if (shouldHold.isConfirmed) {
                  response = await updateTicketStatus(id, { status: status });
                  response = await updateTicketStatus(ticket._id, {
                    status: "In Progress",
                  });
                  let data = {
                    ticket: id,
                    name: `Task Status Changed to ${status}`,
                  };
                  var statusNotification = await createNotification(data);
                } else {
                  Swal.fire({
                    title: `You need to hold already running task to start this task.`,
                    icon: "info",
                    showCancelButton: false,
                    //  cancelButtonColor: "#d33",
                    //  confirmButtonText: "Okay",
                  });

                  setStatus("");
                  return false;
                }
              } else {
                response = await updateTicketStatus(id, { status: status });
                let data = {
                  ticket: id,
                  name: `Task Status Changed to ${status}`,
                };
                var statusNotification = await createNotification(data);
              }
            } else {
              response = await updateTicketStatus(id, { status: status });
              let data = {
                ticket: id,
                name: `Task Status Changed to ${status}`,
              };
              var statusNotification = await createNotification(data);
            }
          } else {
            response = await updateTicketStatus(id, { status: status });
            let data = {
              ticket: id,
              name: `Task Status Changed to ${status}`,
            };
            var statusNotification = await createNotification(data);
          }
        }
        showticket(ids);
        if (response.data.status === true && sfirst) {
          toast({
            title: "Status Changed Successfully.",
            status: "success",
            position: "bottom",
            duration: 1000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateProgress = async (id, value) => {
    try {
      if (value == 100) {
        const response = await updateTicketStatus(id, { progressValue: value });
      } else {
        const response = await updateticket(id, { progressValue: value });
      }
      let data = {
        name: `${ticket.ticket_id} is ${value}% completed.`,
        ticket: id,
        value,
      };
      let progressNotification = await createNotification(data);
      showticket(ids);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (proFirst && !isDragging) {
      updateProgress(ids, inputValue);
    } else {
      setProFirst(true);
    }
  }, [isDragging]);

  const updatePriority = async (id, priority) => {
    try {
      if (priority !== undefined) {
        const response = await updateticket(id, { priority: priority });
        showticket(ids);

        if (response.data.status === true && pfirst) {
          toast({
            title: "Priority Updated Successfully.",
            status: "success",
            position: "top",
            duration: 1000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updateAssign = async (id, assign_id) => {
    try {
      if (assign_id !== null || assign_id !== undefined) {
        const response = await updateticket(id, { assign_id: assign_id });

        if (response.data.status === true) {
          toast({
            title: "Ticket Assigned Successfully.",
            status: "success",
            position: "top",
            duration: 2000,
            isClosable: true,
          });
          let timeOut = setTimeout(() => {
            navigate("/listoftickets");
          }, 2000);
          return () => clearTimeout(timeOut);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAssign = async () => {
    try {
      let response = await fetchallusers();
      let data = response.data;
      if (data.users) {
        let users = data.users;

        setUsers(users);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showticket = async (id) => {
    try {
      if (id) {
        setIsLoading(true);
        let response = await viewticket(id);
        let data = response.data;
        setTicket(data.ticket);
        setInputValue(data.ticket.progressValue);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  // assign_user filter

  let usersA = user.filter((user) => {
    return (
      user.roleName.includes("Tester") ||
      user.roleName.includes("Team Leader") ||
      user.roleName.includes("Dispatcher") ||
      user.roleName.includes("Developer")
    );
  });

  let Auser = usersA.filter((user) => {
    if (ticket.assign_id !== undefined && ticket.assign_id._id !== null) {
      return (
        ticket.assign_id._id !== user._id && user._id !== ticket?.user?._id
      );
    } else {
      return user._id !== ticket?.user?._id;
    }
  });

  useEffect(() => {
    if (didMountRef.current) {
      updateStatus(ids, status);

      setSfirst(true);
    } else {
      didMountRef.current = true;
    }
  }, [status, didMountRef]);

  useEffect(() => {
    if (didMount.current) {
      updatePriority(ids, priority);

      setPfirst(true);
    } else {
      didMount.current = true;
    }
  }, [priority, didMount]);

  useEffect(() => {
    if (didM.current) {
      updateAssign(ids, assign_id);
      showticket(ids);
    } else {
      didM.current = true;
    }
  }, [assign_id, didM]);

  const handleChange = (id, e) => {
    setStatus(e.target.value);
    setId(id);
    if (e.target.value === "Resolved") {
      setModalUpload(true);
    }
  };

  const priorityHandleChange = (id, e) => {
    setPriority(e.target.value);
    setId(id);
  };
  const assignHandleChange = (id, e) => {
    setAssign(e.target.value);

    setId(id);
  };

  useEffect(() => {
    fetchAssign();
  }, []);

  const messageHandler = (id, e) => {
    setMessage({ ...message, ticketId: id, message: e.target.value });
  };

  const messageSubmit = async (e, ticket_id) => {
    e.preventDefault();
    if (message.message.length > 3) {
      try {
        let form = new FormData();
        form.append("ticket_id", ticket_id);
        form.append("message", message.message);
        form.append("ticketId", message.ticketId);

        if (message.file.length !== 0) {
          for (let i = 0; i < message.file.length; i++) {
            form.append("files", message.file[i]);
          }
        }
        let response = await sendmessage(form);
        let data = response.data;

        if (data.ticket_message) {
          fetchMessage(ticket._id);
          toast({
            title: data.message,
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });

          setMessage({ ticketId: "", message: "", file: [] });
          if (fileInputRef2.current) {
            fileInputRef2.current.value = "";
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast({
        title: "Please, Write some Message.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
  };

  const fetchMessage = async (id) => {
    try {
      let response = await readmessage(id);
      let data = response.data;
      if (data.message) {
        setAllMessage(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMessage(parsedData._id);
    // fetchMessage(id);
  }, [location]);

  const deletemessage = async (id) => {
    try {
      if (!id) {
        setError({ dele: "Somthing Has Been Wrong !" });
        return false;
      }
      const response = await deleteMessage(id);
      let data = response.data;

      if (data.status === true) {
        toast({
          title: data.messages,
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }

      fetchMessage(ticket._id);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadfile = (filename) => {
    let Fname = filename.substring(filename.indexOf("_") + 1);
    let token = localStorage.getItem("rove-token");
    fetch(`${api.downloadFile}/${filename}`, {
      method: "GET",
      headers: {
        "Content-Type": "image/jpeg",
        Authorization: `bearer ${token}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = Fname;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error("Error downloading image:", error));
    // const response = await downloadFile(filename, {
    //   responseType: "arraybuffer",
    // });
    // const blob = new Blob([response.data], { type: "image/jpeg" });
    // const link = document.createElement("a");
    // link.href = window.URL.createObjectURL(blob);
    // link.download = filename;
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };

  const deleteFile = async (fileName) => {
    try {
      let response = await fileDelete(fileName, { _id: ids });
      let data = response.data;
      if (data.status === false) {
        toast({
          title: data?.message,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
        // throw new Error(data?.message);
        console.log(data);
      }
      if (data.status === true) {
        toast({
          title: data.message,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
        showticket(ids);
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.message) {
        toast({
          title: error.response.data.message,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      }
    }
  };

  const fetchLogs = async (id) => {
    try {
      const response = await showTimelogs(id);

      if (response.data.timeLog) {
        let log = response.data.timeLog;
        log.forEach((lg) => {
          lg.createdAt = TimeZone(lg.createdAt);
          return lg;
        });
        setLogs(log);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const keyDown = (event) => {
    if (event.keyCode === 13 && event.ctrlKey) {
      messageSubmit(event);
    }
  };

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}

      <button
        ref={fileUploadRef}
        className="btn btn-primary btn-sm d-none"
        data-bs-toggle="modal"
        data-bs-target="#uploadFileModal"
      >
        upload File
      </button>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4
                    className="mb-sm-0 font-size-18"
                    style={{ textTransform: "none" }}
                  >
                    {`View ${task}`}
                  </h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <Link to="/listoftickets" className="btn btn-primary">
                        Back{" "}
                      </Link>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            {isLoading ? (
              <div className="text-center">
                <CircularProgress isIndeterminate color="green.300" />
              </div>
            ) : (
              <div className="row">
                <div className="col-xl-5 col-md-5">
                  <div className="card" style={{ minHeight: "42em" }}>
                    <div className="card-body">
                      <h4 className="text-center">Messages</h4>
                      {allMessage.length === 0 && (
                        <h5 className="m-4 text-center">
                          No Previous Messages.
                        </h5>
                      )}
                      {allMessage.map((message) => (
                        <div className="card my-4" key={message._id}>
                          <div className="card-body">
                            <div className="row my-2">
                              <div className="d-flex justify-content-evenly">
                                <div className="w-100">
                                  <div className="d-flex justify-content-between">
                                    <div>
                                      <h6 className="badge badge-soft-info font-size-15 ">
                                        {message.from}
                                      </h6>
                                      <span className="ms-3">
                                        {timeAgo(message.createdAt)}
                                      </span>
                                    </div>
                                    <button
                                      type="button"
                                      onClick={() => deletemessage(message._id)}
                                      className="btn btn-sm btn-info"
                                    >
                                      Delete
                                    </button>
                                  </div>
                                  <h6 className="my-3">{message.message}</h6>
                                  <table>
                                    <tbody>
                                      {message.fileId &&
                                        message.fileId.length !== 0 &&
                                        message.fileId.map((file, index) => {
                                          let ticketId = file.split("/")[4];
                                          // let fileWithDate = file.split("/")[5];
                                          let fileWithDate = file.substring(
                                            file.lastIndexOf("/") + 1
                                          );
                                          let filename =
                                            fileWithDate.split("_")[1];
                                          let ext = filename.split(".");
                                          var filePath = `${ticketId}!${fileWithDate}`;
                                          return (
                                            <tr key={file}>
                                              <th scope="row">
                                                <a
                                                  href={file}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  title="Click To See"
                                                >
                                                  {ext.includes("webp") ||
                                                  ext.includes("png") ||
                                                  ext.includes("jpeg") ||
                                                  ext.includes("jpg") ? (
                                                    <img
                                                      src={file}
                                                      alt="File"
                                                      style={{
                                                        height: "100px",
                                                        width: "100px",
                                                      }}
                                                    />
                                                  ) : (
                                                    <h6>File</h6>
                                                  )}
                                                </a>
                                              </th>
                                              <td>{filename}</td>
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {ticket.status !== "Resolved" && (
                        <div className="row my-4">
                          <div className={` col-lg-12 col-md-12 text-center`}>
                            <form
                              onSubmit={(e) =>
                                messageSubmit(e, ticket.ticket_id)
                              }
                              encType="multipart/form-data"
                            >
                              <div className="d-flex my-2">
                                {/* <label
                                    htmlFor="file1"
                                    className="col-form-label"
                                  >
                                    Attachments :
                                  </label> */}
                                <input
                                  multiple
                                  ref={fileInputRef2}
                                  type="file"
                                  name="file"
                                  id="file1"
                                  style={{ width: "50%" }}
                                  className="form-control"
                                  onChange={handleFileMessageChange}
                                />
                              </div>
                              <div className="mb-3">
                                {error.message && (
                                  <h6
                                    style={{
                                      color: "green",
                                      margin: "5px 0",
                                    }}
                                  >
                                    {error.message}
                                  </h6>
                                )}

                                <textarea
                                  className="form-control"
                                  name="message"
                                  onChange={(e) =>
                                    messageHandler(ticket._id, e)
                                  }
                                  id="message"
                                  onKeyDown={keyDown}
                                  cols="30"
                                  rows="3"
                                  placeholder="Send Messages"
                                  value={message.message}
                                  style={{
                                    width: "100%",

                                    // border:"1px solid black"
                                  }}
                                ></textarea>
                              </div>

                              <button
                                type="submit"
                                className="btn btn-primary ms-1 "
                              >
                                Sent Message
                              </button>
                            </form>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-7 col-md-7">
                  <div className="card" style={{ minHeight: "42em" }}>
                    <div className="card-body">
                      <div>
                        <div className="row">
                          {(Dispatcher || Client) && (
                            <>
                              <div className="col-md-4">
                                <div className="card">
                                  <div
                                    className="card-body"
                                    style={{ padding: "10px" }}
                                  >
                                    <div className="col-md-12">
                                      <div className="row">
                                        <div className="text-secondary fw-bold fs-5 mb-2">
                                          <div className="d-flex align-items-center">
                                            <i className="bx bxs-user-detail fs-4 me-2"></i>
                                            Customer Name
                                          </div>
                                        </div>
                                        <div className="text-info fw-bold fs-5">
                                          {ticket.customer_name
                                            ? ticket.customer_name
                                            : " - "}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="card">
                                  <div
                                    className="card-body"
                                    style={{ padding: "10px" }}
                                  >
                                    <div className="col-md-12">
                                      <div className="row">
                                        <div className="text-secondary fw-bold fs-5 mb-2 ">
                                          <div className="d-flex align-items-center">
                                            <i className="fa-solid fa-phone me-2"></i>
                                            Customer Number
                                          </div>
                                        </div>
                                        <div className="text-info fw-bold fs-5">
                                          {ticket.customer_number
                                            ? ticket.customer_number
                                            : " - "}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="card">
                                  <div
                                    className="card-body"
                                    style={{ padding: "10px" }}
                                  >
                                    <div className="col-md-12">
                                      <div className="row">
                                        <div className="text-secondary fw-bold fs-5 mb-2 ">
                                          Created By
                                        </div>
                                        <div className="text-info fw-bold fs-5">
                                          {ticket.user?.username}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          <div className="col-md-12">
                            <div className="card">
                              <div
                                className="card-body"
                                style={{ padding: "10px" }}
                              >
                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="text-secondary fw-bold fs-5 mb-2 ">
                                      Title
                                    </div>
                                    <div
                                      className="text-primary fw-bold fs-5 "
                                      dangerouslySetInnerHTML={{
                                        __html: ticket.title,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="card">
                              <div
                                className="card-body"
                                style={{ padding: "10px" }}
                              >
                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="text-secondary fw-bold fs-5 mb-2 ">
                                      Description
                                    </div>
                                    <div className="text-primary fw-bold fs-5">
                                      {ticket.summary && ticket.summary.length
                                        ? ticket.summary
                                        : " - "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-md-6">
                            <div className="card">
                              <div className="card-body">
                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="text-secondary fw-bold fs-5 mb-2 ">
                                      Status
                                    </div>
                                    <div>
                                      <div
                                        className={`badge badge-pill ${
                                          ticket.status === "Resolved"
                                            ? "badge-soft-success"
                                            : "badge-soft-danger"
                                        } fw-bold fs-5`}
                                      >
                                        {ticket.status}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card">
                              <div className="card-body">
                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="text-secondary fw-bold fs-5 mb-2 ">
                                      Priority
                                    </div>
                                    <div>
                                      <div
                                        className={`badge badge-pill ${
                                          ticket.priority === "Low"
                                            ? "badge-soft-primary"
                                            : ticket.priority === "Medium"
                                            ? "badge-soft-info"
                                            : "badge-soft-danger"
                                        } fw-bold fs-5`}
                                      >
                                        {ticket.priority}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                        <div className="row">
                          {/* {(Dispatcher || Client) && (
                            <>
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="text-secondary fw-bold fs-5 w-50">
                                    Customer Name
                                  </div>
                                  <div className="fw-bold fs-5 w-50 text-end">
                                    {ticket.customer_name ?? " - "}
                                  </div>
                                </div>
                                <hr />
                              </div>
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="text-secondary fw-bold fs-5 w-50">
                                    Customer Number
                                  </div>
                                  <div className="fw-bold fs-5 w-50 text-end">
                                    {ticket.customer_number ?? " - "}
                                  </div>
                                </div>
                                <hr />
                              </div>
                            </>
                          )}
                          <div className="col-md-12">
                            <div className="row">
                              <div className="text-secondary fw-bold fs-5 w-50">
                                Title
                              </div>
                              <div
                                className="fw-bold fs-5 w-50 text-end"
                                dangerouslySetInnerHTML={{
                                  __html: ticket.title,
                                }}
                              ></div>
                            </div>
                            <hr />
                          </div>
                          <div className="col-md-12">
                            <div className="row">
                              <div className="text-secondary fw-bold fs-5 w-50">
                                Discription
                              </div>
                              <div className="fw-bold fs-5 w-50 text-end">
                                {ticket.summary && ticket.summary.length
                                  ? ticket.summary
                                  : " - "}
                              </div>
                            </div>
                            <hr />
                          </div>
                          <div className="col-md-12">
                            <div className="row">
                              <div className="text-secondary fw-bold fs-5 w-50">
                                Created By
                              </div>
                              <div className="fw-bold fs-5 w-50 text-end">
                                {ticket.user?.username}
                              </div>
                            </div>
                            <hr />
                          </div>*/}
                          <div className="col-md-12">
                            <div className="row">
                              <div className="text-secondary fw-bold fs-5 w-50">
                                Priority
                              </div>
                              <div className="fw-bold fs-5 w-50 text-end">
                                <div
                                  className={`badge badge-pill ${
                                    ticket.priority === "Low"
                                      ? "badge-soft-primary"
                                      : ticket.priority === "Medium"
                                      ? "badge-soft-info"
                                      : "badge-soft-danger"
                                  } fw-bold fs-5`}
                                >
                                  {ticket.priority}
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>
                          <div className="col-md-12">
                            <div className="row">
                              <div className="text-secondary fw-bold fs-5 w-50">
                                Status
                              </div>
                              <div className="fw-bold fs-5 w-50 text-end">
                                <div
                                  className={`badge badge-pill ${
                                    ticket.status === "Resolved"
                                      ? "badge-soft-success"
                                      : "badge-soft-danger"
                                  } fw-bold fs-5`}
                                >
                                  {ticket.status}
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>

                          {!Dispatcher && !Client && (
                            <>
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="text-secondary fw-bold fs-5 w-50">
                                    Assign To
                                  </div>
                                  <div className="fw-bold fs-5 w-50 text-end">
                                    {ticket.assign_id !== undefined &&
                                    ticket.assign_id.username !== null
                                      ? ticket.assign_id.username
                                      : "-"}
                                  </div>
                                </div>
                                <hr />
                              </div>
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="text-secondary fw-bold fs-5 w-50">
                                    Sprint
                                  </div>
                                  <div className="fw-bold fs-5 w-50 text-end">
                                    {ticket.sprint ? ticket.sprint.name : "-"}
                                  </div>
                                </div>
                                <hr />
                              </div>
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="text-secondary fw-bold fs-5 w-50">
                                    Created by
                                  </div>
                                  <div className="fw-bold fs-5 w-50 text-end">
                                    {ticket.user?.username}
                                  </div>
                                </div>
                                <hr />
                              </div>
                            </>
                          )}
                          <div className="col-md-12">
                            <div className="row">
                              <div className="text-secondary fw-bold fs-5 w-50">
                                Created Date
                              </div>
                              <div className="fw-bold fs-5 w-50 text-end">
                                {ticket.createdAt && TimeZone(ticket.createdAt)}
                              </div>
                            </div>
                            <hr />
                          </div>
                          {!Dispatcher && !Client && (
                            <>
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="text-secondary fw-bold fs-5 w-50">
                                    Expected Solving Hours
                                  </div>
                                  <div className="fw-bold fs-5 w-50 text-end">
                                    {ticket.deadline !== ""
                                      ? `${ticket.deadline} Hours`
                                      : "-"}
                                  </div>
                                </div>
                                <hr />
                              </div>
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="text-secondary fw-bold fs-5 w-50">
                                    Expected Solving Date
                                  </div>
                                  <div className="fw-bold fs-5 w-50 text-end">
                                    {ticket?.expected_date
                                      ? `${TimeZone(
                                          ticket?.expected_date
                                        ).slice(0, 13)} `
                                      : "-"}
                                  </div>
                                </div>
                                <hr />
                              </div>
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="text-secondary fw-bold fs-5 w-50">
                                    Finished Date
                                  </div>
                                  <div className="fw-bold fs-5 w-50 text-end">
                                    {(ticket?.finished_date &&
                                      TimeZone(ticket?.finished_date)) ||
                                      "-"}
                                  </div>
                                </div>
                                <hr />
                              </div>
                            </>
                          )}
                          {ticket.feedback_user && (
                            <div className="col-md-12">
                              <div className="row">
                                <div className="text-secondary fw-bold fs-5 w-50">
                                  User
                                </div>
                                <div className="fw-bold fs-5 w-50 text-end">
                                  {ticket.feedback_user?.name} <br />
                                  {ticket.feedback_user?.email} <br />
                                  {ticket.feedback_user?.number}
                                </div>
                              </div>
                              <hr />
                            </div>
                          )}
                          {!Dispatcher && !Client && (
                            <div className="col-md-12">
                              <button
                                onClick={() => fetchLogs(ticket._id)}
                                className="btn btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#vlewLogs"
                              >
                                Activity
                              </button>
                            </div>
                          )}
                          {/* <table cellPadding="5">
                            <tbody>
                              <tr>
                                <td className="tktDspLabel">
                                  {`${task}`}&nbsp;ID :
                                </td>

                                <td className="tktDspValue" colSpan="3">
                                  {ticket.ticket_id}
                                </td>
                              </tr>
                              {(Dispatcher || Client) && (
                                <>
                                  <tr>
                                    <td className="tktDspLabel">
                                      Customer Name :
                                    </td>
                                    <td className="tktDspValue">
                                      {ticket.customer_name ?? " - "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="tktDspLabel">
                                      Customer Number :
                                    </td>
                                    <td className="tktDspValue">
                                      {ticket.customer_number ?? " - "}
                                    </td>
                                  </tr>
                                </>
                              )}
                              <tr>
                                <td className="tktDspLabel">Title :</td>
                                <td
                                  className="tktDspValue"
                                  style={{
                                    fontWeight: "bolder",
                                    whiteSpace: "break-spaces",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: ticket.title,
                                  }}
                                ></td>
                              </tr>
                              <tr>
                                <td className="tktDspLabel">Created By :</td>
                                <td className="tktDspValue">
                                  {ticket.user?.username}
                                </td>
                              </tr>
                              {ticket.feedback_user && (
                                <tr>
                                  <td className="tktDspLabel">User :</td>
                                  <td className="tktDspValue">
                                    {ticket.feedback_user?.name} <br />
                                    {ticket.feedback_user?.email} <br />
                                    {ticket.feedback_user?.number}
                                  </td>
                                </tr>
                              )}
                              {ticket.feedback_user && (
                                <tr>
                                  <td className="tktDspLabel">Summary :</td>
                                  <td className="tktDspValue">
                                    {ticket.summary}
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <td className="tktDspLabel">Category :</td>
                                <td className="tktDspValue">
                                  {ticket.category?.cname}
                                </td>
                              </tr>
                              <tr>
                                <td className="tktDspLabel">Status :</td>
                                <td className="tktDspValue">{ticket.status}</td>
                              </tr>
                              <tr>
                                <td className="tktDspLabel">Priority :</td>
                                <td className="tktDspValue">
                                  {ticket.priority}
                                </td>
                              </tr>
                              <tr>
                                <td className="tktDspLabel">Assign To:</td>
                                <td className="tktDspValue">
                                  {ticket.assign_id !== undefined &&
                                  ticket.assign_id.username !== null
                                    ? ticket.assign_id.username
                                    : "-"}
                                </td>
                              </tr>
                              {userDetail &&
                                userDetail.role !== "Dispatcher" &&
                                userDetail.role !== "Dispatcher Manager" && (
                                  <tr>
                                    <td className="tktDspLabel">Sprint</td>
                                    <td className="tktDspValue">
                                      {ticket.sprint ? ticket.sprint.name : "-"}
                                    </td>
                                  </tr>
                                )}
                              <tr>
                                <td className="tktDspLabel">Created Date :</td>
                                <td className="tktDspValue">
                                  {ticket.createdAt &&
                                    TimeZone(ticket.createdAt)}
                                </td>
                              </tr>
                              <tr>
                                <td className="tktDspLabel">
                                  Expected Solving Hours:
                                </td>
                                <td className="tktDspValue">
                                  {ticket.deadline !== ""
                                    ? `${ticket.deadline} Hours`
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td className="tktDspLabel">
                                  Expected Solving Date:
                                </td>
                                <td className="tktDspValue">
                                  {ticket?.expected_date
                                    ? `${TimeZone(ticket?.expected_date).slice(
                                        0,
                                        13
                                      )} `
                                    : "-"}
                                </td>
                              </tr>

                              <tr>
                                <td className="tktDspLabel">Finished Date :</td>
                                <td className="tktDspValue">
                                  {(ticket?.finished_date &&
                                    TimeZone(ticket?.finished_date)) ||
                                    "-"}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <button
                                    onClick={() => fetchLogs(ticket._id)}
                                    className="btn btn-primary btn-sm"
                                    data-bs-toggle="modal"
                                    data-bs-target="#vlewLogs"
                                  >
                                    Activity
                                  </button>
                                </td>
                              </tr>

                            </tbody>
                          </table> */}

                          <form className="adminupdate mt-3">
                            {hasTicketStatus &&
                              userDetail &&
                              (allAccess.includes(userDetail.role) ||
                                ticket.status !== "Resolved") && (
                                <>
                                  <div className="mb-3 d-flex">
                                    <label
                                      htmlFor="role-Responsibility"
                                      className="col-form-label"
                                    >
                                      Status :
                                    </label>

                                    <select
                                      id="role-Responsibility"
                                      className="form-select"
                                      aria-label="Default select example"
                                      name="status"
                                      onChange={(e) =>
                                        handleChange(ticket._id, e)
                                      }
                                      value={ticket.status}
                                      // placeholder="Change Status"
                                    >
                                      {/* <option defaultValue value="">Change Status</option> */}
                                      <option value="Open">Open</option>
                                      <option value="Running">Running</option>
                                      <option value="In Progress">
                                        In Progress
                                      </option>
                                      <option value="Resolved">Resolved</option>
                                    </select>
                                  </div>
                                  {ticket.status === "Running" && (
                                    <div className="my-3">
                                      <div
                                        style={{
                                          width: "57%",
                                          height: "7px",
                                          backgroundColor: "white",
                                          position: "relative",
                                          borderRadius: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            height: "100%",
                                            backgroundColor: "#689ead",
                                            width: `${inputValue}%`,
                                            borderRadius: "10px",
                                          }}
                                        ></div>

                                        <div
                                          ref={dotRef}
                                          style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: `calc(${inputValue}% - 5px)`, // Adjust the offset as needed
                                            transform: "translateY(-50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor:
                                              "rgba(12, 73, 105, 1)",
                                            cursor: "grab", // Change cursor on hover
                                          }}
                                          onMouseDown={handleMouseDown}
                                        ></div>
                                      </div>
                                      <br />
                                      <p>Progress: {inputValue} %</p>
                                    </div>
                                  )}
                                </>
                              )}
                            {hasTicketPriority &&
                              ticket.status !== "Resolved" && (
                                <div className="mb-3 d-flex">
                                  <label
                                    htmlFor="role-priority"
                                    className="col-form-label"
                                  >
                                    Priority :
                                  </label>

                                  <select
                                    id="role-priority"
                                    className="form-select"
                                    aria-label="Default select example"
                                    name="priority"
                                    onChange={(e) =>
                                      priorityHandleChange(ticket._id, e)
                                    }
                                    value={ticket.priority}
                                  >
                                    <option value="Low">Low</option>
                                    <option value="Medium">Medium</option>
                                    <option value="High">High</option>
                                  </select>
                                </div>
                              )}
                            {userDetail !== null &&
                              ticket.status !== "Resolved" &&
                              ticket.assign_id._id === null &&
                              (userDetail.role !== "User" ||
                                ticket.user._id ===
                                  userDetail.loginUser._id) && (
                                <div className="mb-3 d-flex">
                                  <label
                                    htmlFor="role-Assign"
                                    className="col-form-label"
                                  >
                                    Assign User :
                                  </label>

                                  <select
                                    id="role-Assign"
                                    className="form-select"
                                    aria-label="Default select example"
                                    name="assign_id"
                                    onChange={(e) =>
                                      assignHandleChange(ticket._id, e)
                                    }
                                    placeholder="Select User"
                                    // value={
                                    //   ticket.assign_id !== undefined &&
                                    //   ticket.assign_id.username
                                    // }
                                  >
                                    <option
                                      defaultValue
                                      value={
                                        ticket.assign_id !== undefined &&
                                        ticket.assign_id._id !== null
                                          ? ticket.assign_id._id
                                          : ""
                                      }
                                    >
                                      {ticket.assign_id !== undefined &&
                                      ticket.assign_id.username !== null
                                        ? ticket.assign_id.username
                                        : "Select User"}
                                    </option>
                                    {Auser.length > 0
                                      ? Auser.map((u) => (
                                          <option
                                            key={u._id}
                                            value={`${u._id} ${u.username}`}
                                          >
                                            {/* value={`${u._id} ${u.username}`} */}
                                            {u.username}
                                          </option>
                                        ))
                                      : usersA.map((u) => (
                                          <option
                                            key={u._id}
                                            value={`${u._id} ${u.username}`}
                                          >
                                            {/* value={`${u._id} ${u.username}`} */}
                                            {u.username}
                                          </option>
                                        ))}
                                  </select>
                                </div>
                              )}
                          </form>
                          {/* <div className="d-flex">
                            <form
                              encType="multipart/form-data"
                              onSubmit={(e) =>
                                handleFileUpload(
                                  e,
                                  ticket._id,
                                  ticket.ticket_id
                                )
                              }
                              className="text-center"
                            >
                              <div className="d-flex">
                                <label
                                  htmlFor="file"
                                  className="col-form-label"
                                >
                                  Attachments:
                                </label>
                                <input
                                  multiple
                                  ref={fileInputRef}
                                  type="file"
                                  name="file"
                                  id="file"
                                  style={{ width: "64%" }}
                                  className="form-control ms-4"
                                  onChange={(e) =>
                                    handleFileChange(e, ticket._id)
                                  }
                                />
                              </div>
                              <button
                                type="submit"
                                className="btn btn-primary btn-sm my-3"
                              >
                                Upload Files
                              </button>
                            </form>
                          </div> */}
                          <div className="col my-3">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Type</th>
                                  <th scope="col">File Name</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ticket.fileId && ticket.fileId.length !== 0 ? (
                                  ticket.fileId.map((file, index) => {
                                    let ticketId = file.split("/")[4];
                                    // let fileWithDate = file.split("/")[5];
                                    let fileWithDate = file.substring(
                                      file.lastIndexOf("/") + 1
                                    );
                                    let filename = fileWithDate.split("_")[1];
                                    let ext = filename.split(".");
                                    let filePath = `${ticketId}!${fileWithDate}`;
                                    return (
                                      <tr key={file}>
                                        <th scope="row">
                                          <a
                                            href={file}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Click To See"
                                          >
                                            {ext.includes("webp") ||
                                            ext.includes("png") ||
                                            ext.includes("jpeg") ||
                                            ext.includes("jpg") ? (
                                              <img
                                                src={file}
                                                alt="File"
                                                style={{
                                                  height: "100px",
                                                  width: "100px",
                                                }}
                                              />
                                            ) : (
                                              <h6>File</h6>
                                            )}
                                          </a>
                                        </th>
                                        <td>{filename}</td>
                                        <td>
                                          <a
                                            role="button"
                                            onClick={() =>
                                              downloadfile(filePath)
                                            }
                                          >
                                            <i
                                              className="fas fa-download"
                                              style={{ fontSize: "20px" }}
                                              title="Download File"
                                            ></i>
                                          </a>
                                          <a
                                            role="button"
                                            onClick={() => deleteFile(filePath)}
                                            className="ms-3"
                                          >
                                            <i
                                              className="fas fa-trash-alt"
                                              style={{ fontSize: "20px" }}
                                              title="Delete File"
                                            ></i>
                                          </a>
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td>
                                      <h5>No Files Uploaded.</h5>{" "}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            <ul className="p-0"></ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="vlewLogs"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {`${task} Time Logs`}
              </h5>
              <button
                type="button"
                // onClick={() => close()}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">No.</th>
                    <th scope="col">Date & Time</th>
                    {/* <th scope="col">Time</th> */}
                    <th scope="col">Type</th>
                    {/* <th scope="col">Task-Status</th> */}
                  </tr>
                </thead>
                <tbody>
                  {logs.length !== 0 ? (
                    logs.map((log, index) => {
                      let time = log.createdAt.split("T")[1];
                      let date = log.createdAt.split("T")[0];
                      return (
                        <tr key={log._id}>
                          <th scope="row">{index + 1}</th>
                          <td>{date}</td>
                          {/* <td>{time?.slice(0, 8)}</td> */}
                          {/* <td>{log.user.username}</td> */}
                          <td>{log.type}</td>
                          {/* <td>{log.ticket_id.status}</td> */}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>No Logs</td>
                    </tr>
                  )}
                </tbody>
              </table>

              {/* <div className="modal-footer">
                <button
                  type="button"
                  // onClick={() => close()}
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Save changes
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="uploadFileModal"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="AssignUserStuday"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="AssignUserStuday">
                Upload File
              </h5>
              <button
                ref={modalCloseRef}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex">
                <form
                  encType="multipart/form-data"
                  onSubmit={(e) =>
                    handleFileUpload(e, ticket._id, ticket.ticket_id)
                  }
                  className="text-center"
                >
                  <div className="d-flex">
                    <label htmlFor="file" className="col-form-label">
                      Attachments:
                    </label>
                    <input
                      multiple
                      ref={fileInputRef}
                      type="file"
                      name="file"
                      id="file"
                      style={{ width: "64%" }}
                      className="form-control ms-4"
                      onChange={(e) => handleFileChange(e, ticket._id)}
                    />
                  </div>
                  <button type="submit" className="btn btn-primary btn-sm my-3">
                    Upload Files
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListOfStudy;
