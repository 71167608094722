import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useToast } from "@chakra-ui/toast";
import { createpermission, getallroles } from "../../api/api";

const CreatePermission = () => {
  const initialstate = {
    role: "",
    permission: [],
  };
  const initialTicketState = {
    Ticket: false,
    Ticket_Create: false,
    Ticket_Update: false,
    Ticket_View: false,
    Ticket_Delete: false,
    Ticket_Priority: false,
    Ticket_Status: false,
    Ticket_Piechart: false,
    Ticket_Groupby: false,
    Ticket_Filter: false,
  };
  const initialProjectState = {
    Project: false,
    Project_Create: false,
    Project_View: false,
    Project_Update: false,
    Project_Delete: false,
  };
  const initialSprintState = {
    Sprint: false,
    Sprint_Create: false,
    Sprint_View: false,
    Sprint_Update: false,
    Sprint_Delete: false,
    Sprint_Piechart: false,
  };
  const initialUserState = {
    User: false,
    User_Create: false,
    User_View: false,
    User_Update: false,
    User_Delete: false,
  };
  const [formsData, setFormData] = useState(initialstate);
  const [ticketChecked, setTicketChecked] = useState(initialTicketState);
  const [userChecked, setUserChecked] = useState(initialUserState);
  const [sprintChecked, setSprintChecked] = useState(initialSprintState);
  const [projectChecked, setProjectChecked] = useState(initialProjectState);
  const [roles, setRoles] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();

  const fetchRoll = async () => {
    try {
      let response = await getallroles();
      let data = response.data;
      if (data.status === false) {
        // throw new Error(data?.message);
        console.log(data)
      }
      if (response.data.roles) {
        setRoles(response.data.roles);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRoll();
  }, []);

  const ticketHandleChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (name === "Ticket") {
      // Handle "All" checkbox
      const updatedFormData = { ...ticketChecked };
      Object.keys(updatedFormData).forEach((key) => {
        updatedFormData[key] = value;
      });
      setTicketChecked(updatedFormData);
    } else {
      // Handle other checkboxes
      setTicketChecked((prevData) => {
        const updatedData = { ...prevData, [name]: value };

        // Check if all child checkboxes are checked
        const allChildCheckboxesChecked = Object.keys(updatedData)
          .filter((key) => key !== "Ticket")
          .every((key) => updatedData[key]);
       
        // Update "Ticket" checkbox accordingly
        updatedData["Ticket"] = allChildCheckboxesChecked;

        return updatedData;
      });
    }
  };
  const userHandleChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (name === "User") {
      // Handle "All" checkbox
      const updatedFormData = { ...userChecked };
      Object.keys(updatedFormData).forEach((key) => {
        updatedFormData[key] = value;
      });
      setUserChecked(updatedFormData);
    } else {
      // Handle other checkboxes
      setUserChecked((prevData) => {
        const updatedData = { ...prevData, [name]: value };

        // Check if all child checkboxes are checked
        const allChildCheckboxesChecked = Object.keys(updatedData)
          .filter((key) => key !== "User")
          .every((key) => updatedData[key]);
       
        // Update "User" checkbox accordingly
        updatedData["User"] = allChildCheckboxesChecked;

        return updatedData;
      });
    }
  };
  const projectHandleChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (name === "Project") {
      // Handle "All" checkbox
      const updatedFormData = { ...projectChecked };
      Object.keys(updatedFormData).forEach((key) => {
        updatedFormData[key] = value;
      });
      setProjectChecked(updatedFormData);
    } else {
      // Handle other checkboxes
      setProjectChecked((prevData) => {
        const updatedData = { ...prevData, [name]: value };

        // Check if all child checkboxes are checked
        const allChildCheckboxesChecked = Object.keys(updatedData)
          .filter((key) => key !== "Project")
          .every((key) => updatedData[key]);

        // Update "User" checkbox accordingly
        updatedData["Project"] = allChildCheckboxesChecked;

        return updatedData;
      });
    }
  };
  const sprintHandleChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (name === "Sprint") {
      // Handle "All" checkbox
      const updatedFormData = { ...sprintChecked };
      Object.keys(updatedFormData).forEach((key) => {
        updatedFormData[key] = value;
      });
      setSprintChecked(updatedFormData);
    } else {
      // Handle other checkboxes
      setSprintChecked((prevData) => {
        const updatedData = { ...prevData, [name]: value };

        // Check if all child checkboxes are checked
        const allChildCheckboxesChecked = Object.keys(updatedData)
          .filter((key) => key !== "Sprint")
          .every((key) => updatedData[key]);

        // Update "User" checkbox accordingly
        updatedData["Sprint"] = allChildCheckboxesChecked;

        return updatedData;
      });
    }
  };

  //  const ticketHandleChange = (e) => {
  //    const name = e.target.name;
  //    const value =
  //      e.target.type === "checkbox" ? e.target.checked : e.target.value;

  //    if (name === "Ticket") {
  //      const updatedFormData = { ...formsData };
  //      Object.keys(updatedFormData).forEach((key) => {
  //        updatedFormData[key] = value;
  //      });
  //      setFormData(updatedFormData);
  //    } else {

  //      setFormData((prevData) => ({ ...prevData, [name]: value }));
  //    }
  //  };

  // const ticketHandleChange = (e) => {
  //   let parent = e.target.getAttribute("data-parent-element");
  //   let parentDiv = document.getElementsByClassName(parent);
  //   let value = e.target.getAttribute("data-value");
  //   if (value === "All") {
  //     let all_checkboxes = document.querySelectorAll(
  //       "." + parent + ' input[type="checkbox"]'
  //     );
  //     all_checkboxes.forEach((checkbox) => {
  //       checkbox.checked = e.target.checked;
  //     });
  //   }
  //   // if (value === "All") {
  //   //   console.log(('checked' in e.target));
  //   //   let all_checkboxes = document.querySelectorAll("." + parent + ' input[type="checkbox"]');
  //   // }

  // }

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateForm = () => {
    let errors = {};
    if (formsData.role.length < 3) {
      errors.role = "Role Name Must Be 3 Character.";
      toast({
        title: errors.role,
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
    }
    return Object.keys(errors).length === 0;
  };

  const generatePermissionArray = (permissions) => {
    const permissionArray = {};

    for (const [key, value] of Object.entries(permissions)) {
      const [entity, action] = key.split("_");

      if (value !== false && action) {
        if (!permissionArray[entity]) {
          permissionArray[entity] = [];
        }

        permissionArray[entity].push(
          action.charAt(0).toUpperCase() + action.slice(1)
        );
      }
    }

    return permissionArray;
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        let ticketPermission = generatePermissionArray(ticketChecked);
        let userPermission = generatePermissionArray(userChecked);
        let projectPermission = generatePermissionArray(projectChecked);
        let sprintPermission = generatePermissionArray(sprintChecked);

        let permission = {};
        if (Object.keys(ticketPermission).length) {
          permission = { ...ticketPermission };
        }
        if (Object.keys(userPermission).length) {
          permission = { ...permission, ...userPermission };
        }
        if (Object.keys(projectPermission).length) {
          permission = { ...permission, ...projectPermission };
        }
        if (Object.keys(sprintPermission).length) {
          permission = { ...permission, ...sprintPermission };
        }
        
        let response = await createpermission({
          role_ID: formsData.role,
          permission,
        });

        let data = await response.data;
        if (data.status === false) {
          toast({
            title: data?.message,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
          throw new Error(data?.message);
        }
        if (data.status === true) {
          toast({
            title: data.message,
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top",
          });

          setFormData(initialstate);
          let timeout = setTimeout(() => {
            navigate("/listofpermission");
          }, 2000);
          return () => clearTimeout(timeout);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // const formSubmitHandler = data => {
  //   console.log(data)
  // }

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Create Permission</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <strong>Admin</strong>
                      </li>
                      <li className="breadcrumb-item active">
                        Create Permission
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-11 col-lg-10">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={formSubmitHandler}>
                      <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-md-6">
                          <div className="col-md-12 mb-3">
                            <label
                              htmlFor="role-Role"
                              className="col-form-label"
                            >
                              Role :
                            </label>

                            <select
                              id="role-Role"
                              className="form-select"
                              aria-label="Default select example"
                              name="role"
                              onChange={handleChange}
                              value={formsData.role}
                            >
                              <option value="">Select Role</option>

                              {roles !== null &&
                                roles.map((r) => (
                                  <option value={r._id} key={r._id}>
                                    {r.role}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row  justify-content-center">
                        <div className="col-md-6 d-flex justify-content-center mb-3">
                          <div className="col-md-4 d-flex justify-content-center">
                            <div className="mb-3">
                              <div>
                                <div className=" form-check">
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleCheck10"
                                  >
                                    <h5>User Model</h5>
                                  </label>
                                  <input
                                    name="User"
                                    onChange={userHandleChange}
                                    type="checkbox"
                                    className="form-check-input"
                                    id="exampleCheck10"
                                    checked={userChecked.User}
                                  />
                                </div>
                              </div>
                              <div className=" form-check">
                                <input
                                  name="User_Create"
                                  onChange={userHandleChange}
                                  type="checkbox"
                                  className="form-check-input"
                                  id="exampleCheck1"
                                  checked={userChecked.User_Create}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleCheck1"
                                >
                                  Create
                                </label>
                              </div>
                              <div className=" form-check">
                                <input
                                  name="User_View"
                                  onChange={userHandleChange}
                                  type="checkbox"
                                  className="form-check-input"
                                  id="exampleCheck"
                                  checked={userChecked.User_View}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleCheck"
                                >
                                  View
                                </label>
                              </div>
                              <div className=" form-check">
                                <input
                                  name="User_Update"
                                  onChange={userHandleChange}
                                  type="checkbox"
                                  className="form-check-input"
                                  id="exampleCheck2"
                                  checked={userChecked.User_Update}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleCheck2"
                                >
                                  Update
                                </label>
                              </div>
                              <div className=" form-check">
                                <input
                                  name="User_Delete"
                                  onChange={userHandleChange}
                                  type="checkbox"
                                  className="form-check-input"
                                  id="exampleCheck3"
                                  checked={userChecked.User_Delete}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleCheck3"
                                >
                                  Delete
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 d-flex justify-content-center">
                            <div className="mb-3">
                              <div>
                                <div className=" form-check">
                                  <label
                                    className="form-check-label"
                                    htmlFor="Project"
                                  >
                                    <h5>Project Model</h5>
                                  </label>
                                  <input
                                    name="Project"
                                    onChange={projectHandleChange}
                                    type="checkbox"
                                    className="form-check-input"
                                    id="Project"
                                    checked={projectChecked.Project}
                                  />
                                </div>
                              </div>
                              <div className=" form-check">
                                <input
                                  name="Project_Create"
                                  onChange={projectHandleChange}
                                  type="checkbox"
                                  className="form-check-input"
                                  id="Project_Create"
                                  checked={projectChecked.Project_Create}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Project_Create"
                                >
                                  Create
                                </label>
                              </div>
                              <div className=" form-check">
                                <input
                                  name="Project_View"
                                  onChange={projectHandleChange}
                                  type="checkbox"
                                  className="form-check-input"
                                  id="Project_View"
                                  checked={projectChecked.Project_View}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Project_View"
                                >
                                  View
                                </label>
                              </div>
                              <div className=" form-check">
                                <input
                                  name="Project_Update"
                                  onChange={projectHandleChange}
                                  type="checkbox"
                                  className="form-check-input"
                                  id="Project_Update"
                                  checked={projectChecked.Project_Update}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Project_Update"
                                >
                                  Update
                                </label>
                              </div>
                              <div className=" form-check">
                                <input
                                  name="Project_Delete"
                                  onChange={projectHandleChange}
                                  type="checkbox"
                                  className="form-check-input"
                                  id="Project_Delete"
                                  checked={projectChecked.Project_Delete}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Project_Delete"
                                >
                                  Delete
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 d-flex justify-content-center">
                            <div className="ticket_model mb-3">
                              <div>
                                <div className=" form-check">
                                  <label
                                    className="form-check-label"
                                    htmlFor="ticket"
                                  >
                                    <h5>Ticket Model</h5>
                                  </label>
                                  <input
                                    name="Ticket"
                                    onChange={ticketHandleChange}
                                    type="checkbox"
                                    className="form-check-input"
                                    id="ticket"
                                    data-parent-element="ticket_model"
                                    data-value="All"
                                    checked={ticketChecked.Ticket}
                                  />
                                </div>
                              </div>
                              <div className=" form-check">
                                <input
                                  name="Ticket_Create"
                                  onChange={ticketHandleChange}
                                  type="checkbox"
                                  className="form-check-input"
                                  id="exampleCheck4"
                                  checked={ticketChecked.Ticket_Create}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleCheck4"
                                >
                                  Create
                                </label>
                              </div>
                              <div className=" form-check">
                                <input
                                  name="Ticket_View"
                                  onChange={ticketHandleChange}
                                  type="checkbox"
                                  className="form-check-input"
                                  id="exampleCheck5"
                                  checked={ticketChecked.Ticket_View}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleCheck5"
                                >
                                  View
                                </label>
                              </div>
                              <div className=" form-check">
                                <input
                                  name="Ticket_Update"
                                  onChange={ticketHandleChange}
                                  type="checkbox"
                                  className="form-check-input"
                                  id="exampleCheck6"
                                  checked={ticketChecked.Ticket_Update}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleCheck6"
                                >
                                  Update
                                </label>
                              </div>
                              <div className=" form-check">
                                <input
                                  name="Ticket_Delete"
                                  onChange={ticketHandleChange}
                                  type="checkbox"
                                  className="form-check-input"
                                  id="exampleCheck7"
                                  checked={ticketChecked.Ticket_Delete}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleCheck7"
                                >
                                  Delete
                                </label>
                              </div>
                              <div className=" form-check">
                                <input
                                  name="Ticket_Status"
                                  onChange={ticketHandleChange}
                                  type="checkbox"
                                  className="form-check-input"
                                  id="exampleCheck78"
                                  checked={ticketChecked.Ticket_Status}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleCheck78"
                                >
                                  Status
                                </label>
                              </div>
                              <div className=" form-check">
                                <input
                                  name="Ticket_Priority"
                                  onChange={ticketHandleChange}
                                  type="checkbox"
                                  className="form-check-input"
                                  id="exampleCheck77"
                                  checked={ticketChecked.Ticket_Priority}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleCheck77"
                                >
                                  Priority
                                </label>
                              </div>
                              <div className=" form-check">
                                <input
                                  name="Ticket_Piechart"
                                  onChange={ticketHandleChange}
                                  type="checkbox"
                                  className="form-check-input"
                                  id="Ticket_Piechart"
                                  checked={ticketChecked.Ticket_Piechart}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Ticket_Piechart"
                                >
                                  Piechart
                                </label>
                              </div>
                              <div className=" form-check">
                                <input
                                  name="Ticket_Groupby"
                                  onChange={ticketHandleChange}
                                  type="checkbox"
                                  className="form-check-input"
                                  id="Ticket_Groupby"
                                  checked={ticketChecked.Ticket_Groupby}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Ticket_Groupby"
                                >
                                  Groupby
                                </label>
                              </div>
                              <div className=" form-check">
                                <input
                                  name="Ticket_Filter"
                                  onChange={ticketHandleChange}
                                  type="checkbox"
                                  className="form-check-input"
                                  id="Ticket_Filter"
                                  checked={ticketChecked.Ticket_Filter}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Ticket_Filter"
                                >
                                  Filter
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 d-flex justify-content-center">
                            <div className="ticket_model mb-3">
                              <div>
                                <div className=" form-check">
                                  <label
                                    className="form-check-label"
                                    htmlFor="sprint"
                                  >
                                    <h5>Sprint Model</h5>
                                  </label>
                                  <input
                                    name="Sprint"
                                    onChange={sprintHandleChange}
                                    type="checkbox"
                                    className="form-check-input"
                                    id="sprint"
                                    data-parent-element="sprint_model"
                                    data-value="All"
                                    checked={sprintChecked.Sprint}
                                  />
                                </div>
                              </div>
                              <div className=" form-check">
                                <input
                                  name="Sprint_Create"
                                  onChange={sprintHandleChange}
                                  type="checkbox"
                                  className="form-check-input"
                                  id="Sprint_Create"
                                  checked={sprintChecked.Sprint_Create}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Sprint_Create"
                                >
                                  Create
                                </label>
                              </div>
                              <div className=" form-check">
                                <input
                                  name="Sprint_View"
                                  onChange={sprintHandleChange}
                                  type="checkbox"
                                  className="form-check-input"
                                  id="Sprint_View"
                                  checked={sprintChecked.Sprint_View}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Sprint_View"
                                >
                                  View
                                </label>
                              </div>
                              <div className=" form-check">
                                <input
                                  name="Sprint_Update"
                                  onChange={sprintHandleChange}
                                  type="checkbox"
                                  className="form-check-input"
                                  id="Sprint_Update"
                                  checked={sprintChecked.Sprint_Update}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Sprint_Update"
                                >
                                  Update
                                </label>
                              </div>
                              <div className=" form-check">
                                <input
                                  name="Sprint_Delete"
                                  onChange={sprintHandleChange}
                                  type="checkbox"
                                  className="form-check-input"
                                  id="Sprint_Delete"
                                  checked={sprintChecked.Sprint_Delete}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Sprint_Delete"
                                >
                                  Delete
                                </label>
                              </div>
                              <div className=" form-check">
                                <input
                                  name="Sprint_Piechart"
                                  onChange={sprintHandleChange}
                                  type="checkbox"
                                  className="form-check-input"
                                  id="Sprint_Piechart"
                                  checked={sprintChecked.Sprint_Piechart}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Sprint_Piechart"
                                >
                                  Piechart
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt-4">
                        <button className="btn btn-primary" type="submit">
                          Create Permission
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePermission;
