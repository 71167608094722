import api from ".";
import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("rove-token");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});



export const loginApi = (data) => {
  return axios.post(api.login, data);
};

export const createuser = (data) => {
  return axios.post(api.createuser, data);
};

export const fetchallusers = () => {
  return axios.get(api.fetchallusers);
};
export const freeUserGet = () => {
  return axios.get(api.freeuser);
};

export const updateuser = (id, data) => {
  return axios.put(`${api.updateuser}/${id}`, data);
};

export const deleteuser = (id) => {
  return axios.delete(`${api.deleteuser}/${id}`);
};

export const generateticket = (data, onUploadProgress) => {
  return axios.post(api.generateticket, data, { onUploadProgress });
};

export const getalltickets = (params) => {
  if (params) return axios.get(api.getalltickets, { params });
  return axios.get(api.getalltickets);
};

export const counterTicket = (params) => {
  if (params) return axios.get(api.ticketCounter, { params });
  return axios.get(api.ticketCounter);
};

export const delayTask = (params) => {
  if (params) return axios.get(api.delayTask, { params });
  return axios.get(api.delayTask);
};

export const dateWiseWorkApi = (params) => {
  if (params) return axios.get(api.showDateWiseWorkTime, { params });
  return axios.get(api.showDateWiseWorkTime);
};

export const dateWiseLogsApi = (params) => {
  if (params) return axios.get(api.showDateWiseLogs, { params });
  return axios.get(api.showDateWiseLogs);
};

export const delaySprints = (params) => {
  if (params) return axios.get(api.delaySprint, { params });
  return axios.get(api.delaySprint);
};

export const updateTicketStatus = (id, data) => {
  return axios.put(`${api.updateticketstatus}/${id}`, data);
};

export const updateticket = (id, data) => {
  return axios.put(`${api.updateticket}/${id}`, data);
};

export const updateTask = (id, data) => {
  return axios.put(`${api.updateTask}/${id}`, data);
};

export const updateupload = (id, data) => {
  return axios.put(`${api.updateupload}/${id}`, data);
};

export const deleteticket = (id) => {
  return axios.delete(`${api.deleteticket}/${id}`);
};
export const viewticket = (id) => {
  return axios.get(`${api.viewticket}/${id}`);
};

export const sendmessage = (data) => {
  return axios.post(api.sendmessage, data);
};

export const readmessage = (id) => {
  return axios.get(`${api.readmessage}/${id}`);
};

export const deleteMessage = (id) => {
  return axios.delete(`${api.deletemessage}/${id}`);
};

export const changepassword = (data) => {
  return axios.put(`${api.changepassword}`, data);
};

export const createrole = (data) => {
  return axios.post(api.createrole, data);
};

export const getallroles = (params) => {
  if (params) return axios.get(api.getallroles, { params });
  return axios.get(api.getallroles);
};

export const getworkingticket = (params) => {
  if (params) return axios.get(api.workingticket, { params });
  return axios.get(api.workingticket);
};

export const deleterole = (id) => {
  return axios.delete(`${api.deleterole}/${id}`);
};

export const updaterole = (id, data) => {
  return axios.put(`${api.updaterole}/${id}`, data);
};

export const updatepermission = (id, data) => {
  return axios.put(`${api.updatepermission}/${id}`, data);
};

export const deletepermission = (id) => {
  return axios.delete(`${api.deletepermission}/${id}`);
};

export const createpermission = (data) => {
  return axios.post(`${api.createpermission}`, data);
};
export const downloadFile = (id, header) => {
  return axios.get(`${api.downloadFile}/${id}`, { headers: { header } });
};

export const getallpermission = (params) => {
  if (params) return axios.get(`${api.getallpermission}`, { params });
  return axios.get(`${api.getallpermission}`);
};

export const fileDelete = (id, params) => {
  return axios.delete(`${api.filedelete}/${id}`, { params });
};

export const fetchlogs = (id) => {
  return axios.get(`${api.fetchLogs}/${id}`);
};

export const createSprint = (data) => {
  return axios.post(`${api.createsprint}`, data);
};

export const fetchSprint = (params) => {
  if(params) return axios.get(api.fetchsprint,{params})
  return axios.get(`${api.fetchsprint}`);
};

export const addTaskToSprint = (id, data) => {
  return axios.post(`${api.addsprintTask}/${id}`, data);
};

export const createTask = (data) => {
  return axios.post(`${api.createTask}`, data);
};

export const getAllTask = () => {
  return axios.get(`${api.fetchTask}`);
};

export const TaskDelete = (id) => {
  return axios.delete(`${api.deleteTask}/${id}`);
};

export const SprintDelete = (id) => {
  return axios.delete(`${api.deletesprint}/${id}`);
};



export const SprintUpdate = (id, data) => {
  return axios.put(`${api.updatesprint}/${id}`, data);
};

export const createCategory = (data) => {
  return axios.post(`${api.createCategory}`, data);
};

export const getallCategory = () => {
  return axios.get(`${api.getallCategory}`);
};

export const updateCategory = (id, data) => {
  return axios.put(`${api.updateCategory}/${id}`, data);
};

export const deleteCategory = (id) => {
  return axios.delete(`${api.deleteCategory}/${id}`);
};

export const createNewProject = (data) => {
  return axios.post(`${api.createProject}`, data);
};

export const getAllProjects = () => {
  return axios.get(`${api.getallProject}`);
};

export const getOneProject = (id) => {
  return axios.get(`${api.getOneProject}/${id}`);
};

export const updateProject = (id, data) => {
  return axios.put(`${api.updateProject}/${id}`, data);
};

export const deleteProject = (id) => {
  return axios.delete(`${api.deleteProject}/${id}`);
};

export const getAllNotifications = () => {
  return axios.get(`${api.getallnotification}`);
};

export const readNotifications = (id) => {
  return axios.put(`${api.readnotification}/${id}`);
};

export const showTimelogs = (id) => {
  return axios.get(`${api.showtimelogs}/${id}`);
};

export const sendNotification = (data) => {
  return axios.post(`${api.createNotification}`,data);
};
