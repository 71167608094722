import { createContext, useEffect, useState } from "react";

export const TicketContext = createContext({
  ticket: null,
  filterTicket: null,
  setTicket:()=>{},
  setFilterTicket:()=>{},
});

export const TicketProvider = ({ children }) => {
  const [ticket, setTicket] = useState(null);
  const [filterTicket, setFilterTicket] = useState(null);
  const [loginUser, setLoginUser] = useState(null);
  const [projectId, setProjectId] = useState("");
  const [projectLength, setProjectLength] = useState(null);

  useEffect(() => {
    let user = localStorage.getItem("rove-user");
    if (user) {
      user = JSON.parse(user);
      setLoginUser(user);
    } else {
      console.log("No User Found In localstorage.");
    }
  }, []);

  useEffect(() => {
    console.log("User");
  }, [loginUser]);

  return (
    <TicketContext.Provider
      value={{
        ticket,
        setTicket,
        loginUser,
        filterTicket,
        setFilterTicket,
        projectId,
        setProjectId,
        projectLength,
        setProjectLength,
      }}
    >
      {children}
    </TicketContext.Provider>
  );
};
