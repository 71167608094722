import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  changepassword,
  getAllNotifications,
  getAllProjects,
  readNotifications,
} from "../../api/api";
import useLocalstorage from "../../utils/hooks/local";
import useRole from "../../utils/middleware/role";
import usePermission from "../../utils/middleware/usePermission";
import { TicketContext } from "../../utils/context";
import timeAgo from "../../utils/helper/timeago";
import queryString from "query-string";

const Header = ({ onProjectChange }) => {
  const Navigate = useNavigate();
  const [user, setUser] = useState(null);
  const { getItem, removeItem } = useLocalstorage();
  let initialpass = {
    currentpassword: "",
    newpassword: "",
    confirmpassword: "",
  };
  const [passwordData, setPasswordData] = useState(initialpass);
  const [error, setError] = useState({});
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userDetail, setUserDetail] = useState(null);
  const { getRole } = useRole();
  const ctx = useContext(TicketContext);
  const [projects, setProjects] = useState(null);
  const [projectLength, setProjectLength] = useState(null);
  const path = window.location.pathname;
  const [notifications, setNotifications] = useState(null);
  const [isRead, setIsRead] = useState(null);
  const [projectId, setProjectId] = useState(null);

  const handleProjectChange = (project) => {
    ctx.setProjectId(project);
    onProjectChange(project);
    setProjectId(project)
  
  };

  useEffect(() => {
    getRole()
      .then((d) => {
        setUserDetail(d);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (projectLength && projects && projectLength <= 1) {
      ctx.setProjectId(projects[0]._id);
      ctx.setProjectLength(projectLength);
      onProjectChange(projects[0]._id);
    } else {
      ctx.setProjectLength(projectLength);
    }
  }, [projectLength, projects]);

  const { hasUser, allPermission,hasSprint,hasProject } = usePermission(userDetail);
  let task = "Task"
  const Dispatcher =  userDetail && (userDetail.role === "Dispatcher" || userDetail.role === "Dispatcher Manager")
  if (Dispatcher) {
    task = "Ticket"
  }
  async function fetchProjects() {
    try {
      let response = await getAllProjects();
      let data = response.data;
      if (data.status === false) {
        console.log(data)
        // throw new Error(data.message);
      }

      if (data.project) {
        let length = data.project.length;
        setProjectLength(length);
        setProjects(data.project);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchProjects();
    fetchNotification();
  }, []);



  async function fetchNotification() {
    try {
      let response = await getAllNotifications();
      let data = response.data;
      if (data.status === false) {
        // throw new Error(data.message);
        console.log(data)
      }
      if (data.notification) {
       
        setNotifications(data.notification);
        setIsRead(data.unread);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const logoutHandler = () => {
    removeItem("rove-token");
    removeItem("rove-user");
    ctx.setProjectId("");
    Navigate("/");
  };

  const togglePasswordVisibility = (passwordType) => {
    switch (passwordType) {
      case "current":
        setShowCurrentPassword(!showCurrentPassword);
        break;
      case "new":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirm":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  let timeout = () => {
    let Timeout = setTimeout(() => {
      setError({});
    }, 2000);
    return () => clearTimeout(Timeout);
  };

  const handleChange = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  };

  const validate = () => {
    const errors = {};
    if (passwordData.newpassword.length < 6) {
      errors.newpassword = "New-Password Length Must Be 6.";
    }
    if (passwordData.currentpassword.length === 0) {
      errors.currentpassword = "Please, Enter Current-Password.";
    }
    if (passwordData.currentpassword === passwordData.newpassword) {
      errors.newpassword = "New-Password Does Not Same To Current Password.";
    }
    if (passwordData.newpassword !== passwordData.confirmpassword) {
      errors.confirmpassword =
        "Confirm-Password Does Not Match To New-Password.";
    }
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const notificationRead = async (id, ticket_id) => {
    try {
      Navigate(
        `/listof-study?${queryString.stringify({
          _id: ticket_id,
        })}`
      );
      let response = await readNotifications(id);
      let data = response.data;
      if (data.status === false) {
        // throw new Error(data.message);
        console.log(data)
      }
      if (data.status === true) {
        fetchNotification();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        let response = await changepassword(passwordData);
        let data = response.data;
        if (data.status === false) {
          // throw new Error(data.message);
          console.log(data)
        }
        if (data.status === true) {
          setError({ message: data.message });
          let log_out = setTimeout(() => {
            Navigate("/");
            removeItem("rove-token");
            removeItem("rove-user");
          }, 2000);
          return () => clearTimeout(log_out);
        }
      } catch (error) {
        console.log(error);
        // if (error.response.data.error) {
        //   setError({ error: error.response.data.error });
        //   timeout();
        // }
      }
    }
  };

  useEffect(() => {
    let userDetail = getItem("rove-user");
    userDetail = JSON.parse(userDetail);
    if (userDetail !== undefined) {
      setUser(userDetail);
    } else {
      console.error("No user details found in localStorage");
    }
  }, []);

  useEffect(() => {
    console.log("User");
  }, [user]);

  const close = () => {
    timeout();
    setPasswordData(initialpass);
  };
 

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}{" "}
      <div
        className="modal fade"
        id="changePassordModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Change Password
              </h5>
              <button
                type="button"
                onClick={() => close()}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="form-horizontal" onSubmit={submitHandler}>
                <div className="my-3">
                  {error.error && (
                    <h6 style={{ color: "red" }}>{error.error}</h6>
                  )}
                  {error.message && (
                    <h6
                      style={{ color: "green" }}
                    >{`${error.message}.. Please Re-Login.`}</h6>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label" htmlFor="Current">
                    Current password{" "}
                  </label>
                  <div className="input-group auth-pass-inputgroup">
                    <input
                      name="currentpassword"
                      id="Current"
                      type={showCurrentPassword ? "text" : "password"}
                      onChange={handleChange}
                      value={passwordData.currentpassword}
                      className="form-control"
                      placeholder="Enter Current password"
                      aria-label="Password"
                      aria-describedby="password-addon"
                    />
                    <button
                      className="btn btn-light password-addon"
                      type="button"
                      id="password-addon"
                      onClick={() => togglePasswordVisibility("current")}
                    >
                      <i
                        className={`mdi mdi-eye${
                          showCurrentPassword ? "" : "-off"
                        }`}
                      ></i>
                    </button>
                  </div>
                  {error.currentpassword && (
                    <h6 style={{ color: "red", margin: "5px 0" }}>
                      {error.currentpassword}
                    </h6>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label" htmlFor="New">
                    New password
                  </label>
                  <div className="input-group auth-pass-inputgroup">
                    <input
                      name="newpassword"
                      id="New"
                      onChange={handleChange}
                      value={passwordData.newpassword}
                      type={showNewPassword ? "text" : "password"}
                      className="form-control"
                      placeholder="New password"
                      aria-label="Password"
                      aria-describedby="password-addon"
                    />
                    <button
                      className="btn btn-light password-addon"
                      type="button"
                      id="password-addon1"
                      onClick={() => togglePasswordVisibility("new")}
                    >
                      <i
                        className={`mdi mdi-eye${
                          showNewPassword ? "" : "-off"
                        }`}
                      ></i>
                    </button>
                  </div>
                  {error.newpassword && (
                    <h6 style={{ color: "red", margin: "5px 0" }}>
                      {error.newpassword}
                    </h6>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label" htmlFor="Confirm">
                    Confirm password
                  </label>
                  <div className="input-group auth-pass-inputgroup">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmpassword"
                      id="Confirm"
                      onChange={handleChange}
                      value={passwordData.confirmpassword}
                      className="form-control"
                      placeholder="Confirm password"
                      aria-label="Password"
                      aria-describedby="password-addon"
                    />
                    <button
                      className="btn btn-light password-addon"
                      type="button"
                      id="password-addon2"
                      onClick={() => togglePasswordVisibility("confirm")}
                    >
                      <i
                        className={`mdi mdi-eye${
                          showConfirmPassword ? "" : "-off"
                        }`}
                      ></i>
                    </button>
                  </div>
                  {error.confirmpassword && (
                    <h6 style={{ color: "red", margin: "5px 0" }}>
                      {error.confirmpassword}
                    </h6>
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => close()}
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <header id="page-topbar">
        <div className="navbar-header">
          <div
            className="navbar-brand-box d-flex "
            // style={{ width: "300px" }}
          >
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/dashboard" className="logo logo-light">
                  <span className="logo-sm">
                    <img
                      style={{ maxWidth: "100px", width: "auto" }}
                      src="assets/images/logo-rove.png"
                      alt="Header Avatar"
                    />
                  </span>
                  <span className="logo-lg">
                    <img
                      style={{ maxWidth: "100px", width: "auto" }}
                      src="assets/images/logo-rove.png"
                      alt="Header Avatar"
                    />
                  </span>
                </Link>
              </div>
            </div>
            {(path.includes("/listoftickets") ||
              path.includes("/createticket") ||
              path.includes("/list-sprint") ||
              path.includes("/dashboard") ||
              path.includes("/create-sprint")) &&
              userDetail &&
              !allPermission &&
              projectLength !== null &&
              projectLength > 1 && (
                <div className="col-12 d-flex ">
                  <select
                    id="role-priority"
                    className="form-select "
                    aria-label="Default select example"
                    name="priority"
                    style={{ height: "2.2rem", width: "9rem" }}
                    // onChange={handleChange}
                    onChange={(e) => handleProjectChange(e.target.value)}
                    value={projectId}
                  >
                    <option defaultValue value="">
                      Select Project
                    </option>
                    {projects !== null &&
                      projects.map((p) => (
                        <option value={p._id} key={p._id}>
                          {p.projectName}
                        </option>
                      ))}
                  </select>
                </div>
              )}
          </div>

          <div className="d-flex">
            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
                id="page-header-notifications-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="bx bx-bell bx-tada"></i>
                <span className="badge bg-danger rounded-pill">
                  {isRead !== null && isRead.length > 0 && `${isRead.length}`}
                </span>
              </button>
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-notifications-dropdown"
              >
                <div className="p-3">
                  <div className="row align-items-center">
                    <div className="col">
                      <h6 className="m-0" key="t-notifications">
                        {" "}
                        Notifications{" "}
                      </h6>
                    </div>
                    {/* <div className="col-auto">
                      <a href="#" className="small" key="t-view-all">
                        {" "}
                        View All
                      </a>
                    </div> */}
                  </div>
                </div>
                {/* <div data-simplebar> */}
                {notifications !== null &&
                  notifications.slice(0, 5).map((noti) => (
                    <a
                      href="#"
                      // to={`/listof-study?${queryString.stringify({
                      //   _id: noti.ticket._id,
                      // })}`}
                      role="button"
                      onClick={() =>
                        notificationRead(noti._id, noti.ticket._id)
                      }
                      className="text-reset notification-item"
                      key={noti._id}
                    >
                      <div
                        className="media"
                        style={{
                          backgroundColor: noti.isRead ? "" : "#f6f6f6",
                        }}
                      >
                        <div className="media-body">
                          <h6 className="mt-0 mb-1" key="t-your-order">
                            {`${noti.name}: ${noti.ticket?.ticket_id}`}
                          </h6>
                          <div className="font-size-12 text-muted">
                            <p className="mb-1" key="t-grammer">
                              {`From : ${noti.from.username}`}
                            </p>
                            <p className="mb-0">
                              <i className="mdi mdi-clock-outline"></i>{" "}
                              <span key="t-min-ago">
                                {timeAgo(noti.createdAt)}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  ))}
                {/* </div> */}
                {notifications !== null && notifications.length > 5 && (
                  <div className="p-2 border-top d-grid">
                    <Link
                      className="btn btn-sm btn-link font-size-14 text-center"
                      to="/allnotification"
                    >
                      <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
                      <span key="t-view-more">View All..</span>
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <button
              type="button"
              className="btn btn-sm px-3 d-lg-none font-size-16  header-item waves-effect waves-light"
              data-bs-toggle="collapse"
              data-bs-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>
            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="d-flex align-items-center">
                  <span
                    className="mdi mdi-account-outline"
                    style={{ fontSize: "25px" }}
                  ></span>
                  <span className="d-none d-xl-inline-block ms-1">
                    {user !== null ? user.name : "Loading..."}
                  </span>
                  <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                </div>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                {/* <!-- item--> */}
                <Link className="dropdown-item" to="/edit-profile">
                  <i className="bx bx-user font-size-16 align-middle me-1"></i>{" "}
                  <span key="t-profile">Profile</span>
                </Link>
                <a
                  className="dropdown-item"
                  href="/"
                  data-bs-toggle="modal"
                  data-bs-target="#changePassordModal"
                >
                  <i className="mdi mdi-lock font-size-16 align-middle me-1"></i>{" "}
                  <span key="t-profile">Change Password</span>
                </a>

                <div className="dropdown-divider"></div>

                <a
                  href="#"
                  role="button"
                  className="dropdown-item text-danger"
                  onClick={logoutHandler}
                  style={{ cursor: "pointer" }}
                >
                  <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>{" "}
                  <span key="t-logout">Logout</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="topnav">
        <div className="container-fluid">
          <nav className="navbar navbar-light navbar-expand-lg topnav-menu ">
            <div
              className="collapse navbar-collapse justify-content-between"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/dashboard"
                      // id="topnav-dashboard"
                      role="button"
                    >
                      <div className="d-flex align-items-center">
                        <i className="bx bx-home-circle me-2"></i>
                        <span key="t-dashboards">Dashboard</span>
                      </div>
                    </Link>
                  </li>
                  {userDetail && hasProject && (
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/list-project"
                        id="topnav-ecommerce"
                        role="button"
                      >
                        <div className="d-flex align-items-center">
                          <i className="fa fa-list me-2"></i>
                          <span key="t-ecommerce">Project</span>
                          {/* <div className="arrow-down"></div> */}
                        </div>
                      </Link>
                    </li>
                  )}
                </>

                {userDetail && (hasUser) && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="/listof-users"
                      // id="topnav-ecommerce"
                      role="button"
                    >
                      <div className="d-flex align-items-center">
                        <i className="bx bx-user-circle me-2"></i>
                        <span key="t-ecommerce">Users</span>
                        {/* <div className="arrow-down"></div> */}
                      </div>
                    </Link>
                  </li>
                )}

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/listoftickets"
                    id="topnav-ecommerce"
                    role="button"
                  >
                    <div className="d-flex align-items-center">
                      <i className="fas fa-laptop-house me-2"></i>
                      <span key="t-ecommerce">{`${task}`}</span>
                      {/* <div className="arrow-down"></div> */}
                    </div>
                  </Link>
                </li>
                {userDetail && allPermission && (
                  <>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/listofroles"
                        id="topnav-ecommerce"
                        role="button"
                      >
                        <div className="d-flex align-items-center">
                          <i className="fa fa-wrench mx-2"></i>
                          <span key="t-ecommerce">Roles</span>
                          {/* <div className="arrow-down"></div> */}
                        </div>
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/listofpermission"
                        id="topnav-ecommerce"
                        role="button"
                      >
                        <div className="d-flex align-items-center">
                          <i className="fas fa-laptop-house me-2"></i>
                          <span key="t-ecommerce">Permissions</span>
                          {/* <div className="arrow-down"></div> */}
                        </div>
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/list-category"
                        id="topnav-ecommerce"
                        role="button"
                      >
                        <div className="d-flex align-items-center">
                          <i className="fa fa-list me-2"></i>
                          <span key="t-ecommerce">Category</span>
                          {/* <div className="arrow-down"></div> */}
                        </div>
                      </Link>
                    </li>
                  </>
                )}
                {userDetail &&
                  (hasSprint)
                  && (
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/list-sprint"
                        id="topnav-ecommerce"
                        role="button"
                      >
                        <div className="d-flex align-items-center">
                          <i className="fas fa-retweet me-2"></i>
                          <span key="t-ecommerce">Sprints</span>
                          {/* <div className="arrow-down"></div> */}
                        </div>
                      </Link>
                    </li>
                  )}
                {userDetail &&
                  (userDetail.role==="Admin")
                  && (
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/calander-table"
                        id="topnav-ecommerce"
                        role="button"
                      >
                        <div className="d-flex align-items-center">
                          <i className="fas fa-retweet me-2"></i>
                          <span key="t-ecommerce">Report</span>
                          {/* <div className="arrow-down"></div> */}
                        </div>
                      </Link>
                    </li>
                  )}
              </ul>

              {userDetail && userDetail.role !== "Dispatcher" &&
                  userDetail.role !== "Dispatcher Manager" && (
                <a
                  href="https://t.me/rovesolutiontesting"
                  target="blank"
                  title="Join Telegram"
                  style={{ paddingLeft: "12px" }}
                >
                  <i
                    className="fab fa-telegram-plane"
                    style={{ fontSize: "20px" }}
                  ></i>
                </a>
              )}
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
