import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  deleteProject,
  fetchallusers,
  getAllProjects,
  updateProject,
} from "../../api/api";
import { useToast } from "@chakra-ui/toast";
import Select from "react-select";

const ListofProjects = () => {
  let initialstate = { projectname: "", assign_to: [] };
  const [updateData, setUpdateData] = useState(initialstate);
  const [projects, setProjects] = useState([]);
  const [allUsers, setAllUsers] = useState(null);
  const toast = useToast();
  const closeModal = useRef();

  const fetchProjects = async () => {
    try {
      let response = await getAllProjects();
      let data = response.data;
      if (data.status === false) {
        toast({
          title: data?.message,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
        throw new Error(data?.message);
      }
      if (data.project) {
        setProjects(data.project);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProjects();
    fetchAssign();
  }, []);

  const handleSelect = (data) => {
    setUpdateData({ ...updateData, assign_to: data });
  };

  const fetchAssign = async () => {
    try {
      let res = await fetchallusers();
      let data = res.data;
      if (data.status === false) {
        throw new Error(data?.message);
      }
      if (data.users) {
        let notAdmin = data.users.filter((user) => {
          return user.roleName !== "Admin";
        });
        setAllUsers(notAdmin);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setUpdateData({ ...updateData, [e.target.name]: e.target.value });
  };

  const updateprojectdata = (project) => {
    let assign_to = project.assign_to.map((t) => {
      return { value: t._id, label: t.username };
    });
    setUpdateData({ ...project, projectname: project.projectName, assign_to });
  };

  const updateProjectHandler = async (e) => {
    e.preventDefault();

    if (updateData.projectname.trim().length < 2) {
      toast({
        title: "Project Name Must Be 3 Characters.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else {
      try {
        let id = updateData._id;
        let value = updateData.assign_to?.map((t) => {
          return t.value;
        });
        let response = await updateProject(id, {
          ...updateData,
          assign_to: value,
        });

        let data = await response.data;
        if (data.status === false) {
          toast({
            title: data?.message,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
          throw new Error(data?.message);
        }
        if (data.status === true) {
          toast({
            title: data.message,
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          closeModal.current.click();
          setUpdateData(initialstate);
          fetchProjects();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const deleteproject = async (id) => {
    const shouldDelete = window.confirm("Are You Sure To Delete This Role ?");

    if (shouldDelete) {
      try {
        const response = await deleteProject(id);
        const data = response.data;
        if (data.status === false) {
          toast({
            title: data?.message,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
          throw new Error(data?.message);
        }
        if (data.status === true) {
          toast({
            title: data.message,
            status: "success",
            duration: 1000,
            isClosable: true,
          });
          fetchProjects();
        }
      } catch (error) {
        console.log(error);
      }
    }

    return;
  };

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">List of Projects</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="#">Admin</a>
                      </li>
                      <li className="breadcrumb-item active">
                        List of Projects
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className=" mb-4">
                      <div className="row d-flex justify-content-end">
                        <div className="col-md-2 ">
                          <Link
                            to="/create-project"
                            className="btn btn-primary  btn-rounded waves-effect waves-light"
                            data-bs-whatever="Create New Company"
                          >
                            <i className="fas fa-plus mx-2"></i>
                            Add Projects
                          </Link>
                        </div>
                      </div>
                    </div>

                    {projects.length > 0 ? (
                      <>
                        <div className="table-responsive">
                          <table className="table align-middle table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                <th className="align-middle">No.</th>
                                <th className="align-middle">Project</th>
                                <th className="align-middle">Created By</th>
                                <th className="align-middle">Assign To</th>
                                <th className="align-middle">Created At</th>

                                <th className="align-middle" width="250">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {projects.length > 0 &&
                                projects.map((p, index) => (
                                  <tr key={p._id}>
                                    <td className="text-body fw-bold">
                                      {index + 1}
                                    </td>
                                    <td>{p.projectName}</td>
                                    <td>{p.createdBy?.username}</td>
                                    <td>
                                      {p.assign_to &&
                                        p.assign_to.map((user) => (
                                          <li key={user._id}>
                                            {user.username}
                                          </li>
                                        ))}
                                    </td>

                                    <td>{p.createdAt.slice(0, 19)}</td>

                                    <td className="align-middle">
                                      <div className="d-flex align-items-center">
                                        <a
                                          href="#"
                                          role="button"
                                          onClick={() => updateprojectdata(p)}
                                          data-bs-toggle="modal"
                                          data-bs-target="#AssignUser"
                                          className="d-inline-block mx-2 "
                                        >
                                          <i
                                            className="fas fa-edit"
                                            style={{ fontSize: "20px" }}
                                          ></i>{" "}
                                        </a>

                                        <a
                                          href="#"
                                          role="button"
                                          onClick={() => deleteproject(p._id)}
                                          className="d-inline-block mx-2"
                                        >
                                          <i
                                            className="fas fa-trash-alt"
                                            style={{ fontSize: "20px" }}
                                          ></i>
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      <h4>No Projects Found.</h4>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="AssignUser"
          tabIndex="-1"
          aria-labelledby="AssignUserStuday"
          aria-hidden="true"
        >
          <div className="modal-dialog ">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="AssignUserStuday">
                  Update Project
                </h5>
                <button
                  ref={closeModal}
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={updateProjectHandler}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="role" className="col-form-label">
                          Project Name :
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="projectname"
                          id="role"
                          onChange={handleChange}
                          value={updateData.projectname}
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="role-assign" className="col-form-label">
                        Assign To <span style={{ fontSize: "17px" }}> </span> :
                      </label>

                      <Select
                        id="role-assign"
                        options={
                          allUsers &&
                          allUsers.map((t) => {
                            return { value: t._id, label: t.username };
                          })
                        }
                        placeholder="Select Tasks"
                        value={updateData.assign_to}
                        onChange={handleSelect}
                        isMulti
                      />
                    </div>
                  </div>

                  <div className="text-center mt-4">
                    <button type="submit" className="btn btn-primary">
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListofProjects;
