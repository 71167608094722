import React, { useState, useEffect } from "react";
import { createuser, getallroles } from "../../api/api";
import { useNavigate } from "react-router";
import { useToast } from "@chakra-ui/toast";
import usePermission from "../../utils/middleware/usePermission";
import useRole from "../../utils/middleware/role";
import { CircularProgress } from "@chakra-ui/react";

const CreateUser = () => {
  let initialstate = {
    name: "",
    username: "",
    email: "",
    password: "",
    confirmpassword: "",
    // isAdmin: false,
    role: "",
  };
  const [formData, setFormData] = useState(initialstate);
  const [allRoles, setAllRoles] = useState(null);
  const toast = useToast();
  const { getRole } = useRole();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [userDetail, setUserDetail] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getRole()
      .then((d) => {
        setUserDetail(d);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const { hasUserCreate } = usePermission(userDetail);

  if (userDetail && !hasUserCreate) {
    navigate("/listoftickets");
  }
  async function fetchRoles() {
    try {
      
      const res = await getallroles();
      let data = res.data;
      if (data.status === false) {
        
        throw new Error(data?.message);
      }
      if (data.roles) {
        setAllRoles(data.roles);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchRoles();
  }, []);

  const inputChangeHandler = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateForm = () => {
    let errors = {};
    var commonRegex = /^[A-Za-z0-9_]+$/;
    if (!/^[A-Z a-z0-9_]+$/.test(formData.name)) {
      errors.name =
        formData.name.trim().length === 0 || formData.name.trim().length < 3
          ? "Enter Name."
          : "Special character not allowed in Name.";
      toast({
        title: errors.name,
        status: "warning",
        duration: 1000,
        isClosable: true,
      });
    }
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(formData.email)) {
      if (formData.email.length === 0) {
        errors.email = "Enter Email Address.";
      } else {
        errors.email = "Enter Valid Email Address.";
      }
      toast({
        title: errors.email,
        status: "warning",
        duration: 1000,
        isClosable: true,
      });
    }
    if (!commonRegex.test(formData.username)) {
      errors.username =
        formData.username.trim().length === 0 ||
        formData.username.trim().length < 3
          ? "Username Must be 3 Characters."
          : "Special Charcter or space not Allow in Username";
      toast({
        title: errors.username,
        status: "warning",
        duration: 1000,
        isClosable: true,
      });
    }
    if (formData.password.trim().length < 6) {
      errors.password = "Password Must be At Least 6 Characters.";
      toast({
        title: errors.password,
        status: "warning",
        duration: 1000,
        isClosable: true,
      });
    }
    if (formData.password !== formData.confirmpassword) {
      errors.confirmpassword = "Password Not Match To Confirm Password.";
      toast({
        title: errors.confirmpassword,
        status: "warning",
        duration: 1000,
        isClosable: true,
      });
    }
    if (formData.role === "") {
      errors.role = "Please, Select Role For User.";
      toast({
        title: errors.role,
        status: "warning",
        duration: 1000,
        isClosable: true,
      });
    }

    return Object.keys(errors).length === 0;
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      try {
        let response = await createuser(formData);
        let data = response.data;
        if (data.status === false) {
          toast({
            title: data?.message,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
          throw new Error(data?.message);
        }
        if(data.status===true) {
          toast({
            title: data.message,
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
          setFormData(initialstate);
          let timeout = setTimeout(() => {
            navigate("/listof-users");
          }, 2000);
          return () => clearTimeout(timeout);
        }
      } catch (error) {
        if (error.response) {
          toast({
            title: error.response.data.message,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
      }
    }
  };

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      {isLoading ? (
        <div className="text-center">
          <CircularProgress isIndeterminate color="green.300" />
        </div>
      ) : (
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Create User</h4>

                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <strong>Admin</strong>
                        </li>
                        <li className="breadcrumb-item active">Create User</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-body">
                      <form className="outer-repeater" onSubmit={submitHandler}>
                        <div className="row ">
                          <div className="d-flex flex-column align-items-center">
                            <div className="col-md-4 col-sm-12 col-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="role-name"
                                  className="col-form-label"
                                >
                                  Name:
                                </label>
                                <input
                                  onChange={inputChangeHandler}
                                  name="name"
                                  type="text"
                                  className="form-control"
                                  id="role-name"
                                  value={formData.name}
                                />
                              </div>
                            </div>

                            <div className="col-md-4 col-sm-12 col-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="role-email"
                                  className="col-form-label"
                                >
                                  Email:
                                </label>
                                <input
                                  onChange={inputChangeHandler}
                                  name="email"
                                  type="text"
                                  className="form-control"
                                  id="role-email"
                                  value={formData.email}
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-12 col-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="role-uname"
                                  className="col-form-label"
                                >
                                  Username:
                                </label>
                                <input
                                  onChange={inputChangeHandler}
                                  name="username"
                                  type="text"
                                  className="form-control"
                                  id="role-uname"
                                  value={formData.username}
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-12 col-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="role-pass"
                                  className="col-form-label"
                                >
                                  Password:
                                </label>
                                <input
                                  onChange={inputChangeHandler}
                                  name="password"
                                  type="password"
                                  className="form-control"
                                  id="role-pass"
                                  value={formData.password}
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-12 col-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="role-CP"
                                  className="col-form-label"
                                >
                                  Confirm Password:
                                </label>
                                <input
                                  name="confirmpassword"
                                  onChange={inputChangeHandler}
                                  type="password"
                                  className="form-control"
                                  id="role-CP"
                                  value={formData.confirmpassword}
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-12 col-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="role-Role"
                                  className="col-form-label"
                                >
                                  Role :
                                </label>

                                <select
                                  id="role-Role"
                                  className="form-select"
                                  aria-label="Default select example"
                                  name="role"
                                  onChange={inputChangeHandler}
                                  value={formData.role}
                                >
                                  <option value="">Select Role</option>
                                  {allRoles !== null &&
                                      allRoles.map((r) => (
                                      <option value={r._id} key={r._id}>
                                        {r.role}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="text-center mb-4">
                          <button
                            type="submit"
                            className="btn btn-primary mt-3 mt-lg-0 me-3"
                          >
                            Create User
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary mt-3 mt-lg-0 me-3"
                            onClick={() => setFormData(initialstate)}
                          >
                            Clear
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateUser;
