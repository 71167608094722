import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteCategory, getallCategory, updateCategory } from "../../api/api";
import { useToast } from "@chakra-ui/toast";

const ListofCategory = () => {
  const [updateData, setUpdateData] = useState({ cname: "" });
  const [roles, setRoles] = useState([]);
  const toast = useToast();

  const fetchRoles = async () => {
    try {
      let response = await getallCategory();
      let data = response.data;
      if (data.status === false) {
           toast({
             title: data?.message,
             status: "error",
             duration: 2000,
             isClosable: true,
           });
          //  throw new Error(data?.message);
         }
      if (data.category) {
        setRoles(data.category);
      }
    } catch (error) {
      if (error.response) {
        toast({
          title: error.response.data.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleChange = (e) => {
    setUpdateData({ ...updateData, [e.target.name]: e.target.value });
  };

    const updateRole = (role) => {
    
    setUpdateData(role);
  };

  const updateRoleHandler = async (e) => {
    e.preventDefault();

    if (updateData.category === "") {
      toast({
        title: "Please Add Role Name.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else {
      try {
        let id = updateData._id;
        let response = await updateCategory(id, { category: updateData.cname });

        let data =  response.data;
        if (data.status === false) {
         toast({
           title: data?.message,
           status: "error",
           duration: 2000,
           isClosable: true,
         });
       }
        if (data.status===true) {
          toast({
            title: data.message,
            status: "success",
            duration: 2000,
            isClosable: true,
          });

          fetchRoles();
        }
      } catch (error) {
        if (error.response.data) {
          toast({
            title: error.response.data?.message,
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      }
    }
  };


  const deleteRole = async (id) => {
    const shouldDelete = window.confirm("Are You Sure To Delete This Role ?");

    if (shouldDelete) {
      try {
        const response = await deleteCategory(id);
        const data = response.data;

        if (data.status === false) {
          // throw new Error(data.message)
          console.log(data)
          
        }

        if (data.status===true) {
          toast({
            title: data.message,
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          fetchRoles();
        }
      } catch (error) {
        toast({
          title:
            error.response.data.error ||
            "Network error or something went wrong",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
    }

    return;
  };

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">List of Categories</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="#">Admin</a>
                      </li>
                      <li className="breadcrumb-item active">
                        List of Categories
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className=" mb-4">
                      <div className="row d-flex justify-content-end">
                        <div className="col-md-2 ">
                          <Link
                            to="/create-category"
                            className="btn btn-primary  btn-rounded waves-effect waves-light"
                            data-bs-whatever="Create New Company"
                          >
                            <i className="fas fa-plus mx-2"></i>
                            Add New Category
                          </Link>
                        </div>
                      </div>
                    </div>

                    {roles.length > 0 ? (
                      <>
                        <div className="table-responsive">
                          <table className="table align-middle table-nowrap mb-0 ">
                            <thead className="table-light">
                              <tr>
                                <th className="align-middle" width="20">
                                  No.
                                </th>
                                <th className="align-middle">Category</th>
                                {/* <th className="align-middle">Role Id</th> */}

                                <th className="align-middle" width="250">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {roles.length > 0 &&
                                roles.map((role, index) => (
                                  <tr key={role._id}>
                                    <td className="text-body fw-bold">
                                      {index + 1}
                                    </td>
                                    <td>{role.cname}</td>
                                    {/* <td>{role._id}</td> */}

                                    <td className="align-middle">
                                      <div className="d-flex align-items-center">
                                        <a
                                          href="#"
                                          role="button"
                                          onClick={() => updateRole(role)}
                                          data-bs-toggle="modal"
                                          data-bs-target="#AssignUser"
                                          className="d-inline-block mx-2 "
                                        >
                                          <i
                                            className="fas fa-edit"
                                            style={{ fontSize: "20px" }}
                                          ></i>{" "}
                                        </a>

                                        <a
                                          href="#"
                                          role="button"
                                          onClick={() => deleteRole(role._id)}
                                          className="d-inline-block mx-2"
                                        >
                                          <i
                                            className="fas fa-trash-alt"
                                            style={{ fontSize: "20px" }}
                                          ></i>
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      <h4>No Categories Found.</h4>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="AssignUser"
          tabIndex="-1"
          aria-labelledby="AssignUserStuday"
          aria-hidden="true"
        >
          <div className="modal-dialog ">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="AssignUserStuday">
                  Update Category
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={updateRoleHandler}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="role" className="col-form-label">
                          Category :
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="cname"
                          id="role"
                          onChange={handleChange}
                          value={updateData.cname}
                        ></input>
                      </div>
                    </div>
                  </div>

                  <div className="text-center mt-4">
                    <button type="submit" className="btn btn-primary">
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListofCategory;
