import React from "react";
import { Pie } from "react-chartjs-2";

function PieChart({ chartData,task }) {

const options = {
  plugins: {
    title: {
      display: true,
      text: task,
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          const label = context.label || "";
          const value = context.parsed;
          return `Count: ${value}`;
        },
      },
    },
  },
};


  return (
    <div className="chart-container">
      <div
        style={{
          height: "60vh",
          position: "relative",
          marginBottom: "1%",
          padding: "1%",
        }}
      >
        {/* <h2 style={{ textAlign: "center" }}>Tasks</h2> */}
        <Pie
          style={{margin:"0 auto"}}
            data={chartData}
          options={options}
          
        />
        {/* <Pie
          style={{margin:"0 auto"}}
            data={chartData}
            options={{
            plugins: {
                title: {
                display: true,
                text: "Tasks",
                },
            },
            }}
        /> */}
      </div>
    </div>
  );
}
export default PieChart;
