import React, { useEffect, useState } from "react";
import { createNewProject, fetchallusers } from "../../api/api";
import { useNavigate } from "react-router";
import { useToast } from "@chakra-ui/toast";
import Select from "react-select";

const CreateProjects = () => {
  const initialstate = {
    projectname: "",
    assign_to:null
  };
  const [formsData, setFormData] = useState(initialstate);
  const [allUsers, setAllUsers] = useState(null);

  const toast = useToast();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formsData, [e.target.name]: e.target.value });
  };

  const handleSelect = (data) => {
    setFormData({ ...formsData, assign_to: data });
  };


  useEffect(() => { 
    fetchAssign()
  },[])

  const fetchAssign = async () => {
  try {
    let res = await fetchallusers()
    let data = res.data
    if (data.status === false) {
    
      throw new Error(data?.message);
    }
    if (data.users) {
      
      let notAdmin = data.users.filter((user)=>{return user.roleName !== "Admin" && user.roleName !== "User";} )
      setAllUsers(notAdmin);
    }
  } catch (error) {
    console.log(error)
  }
}


  const validateForm = () => {
    let errors = {};
    if (formsData.projectname.trim().length < 2) {
      errors.projectname = "Project Name Must Be 3 Character.";
      toast({
        title: errors.projectname,
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
    }

    return Object.keys(errors).length === 0;
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        var value = [];
        if (formsData.assign_to !== null) {
          value = formsData.assign_to.map((t) => {
            return t.value;
          });
        }
        let response = await createNewProject({ ...formsData, assign_to: value});

        let data = await response.data;
        if (data.status === false) {
          toast({
            title: data?.message,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
          throw new Error(data?.message);
        }
        if (data.status===true) {
          toast({
            title: data.message,
            status: "success",
            duration: 1000,
            isClosable: true,
            position: "top",
          });

          setFormData(initialstate);
          let timeout = setTimeout(() => {
            navigate("/list-project");
          }, 1000);
          return () => clearTimeout(timeout);
        }
      } catch (error) {
        console.log(error)
      }
    }
  };
  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Create Project</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <strong>Create Project</strong>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-11 col-lg-10">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={formSubmitHandler}>
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-6">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label htmlFor="role" className="col-form-label">
                                Project Name :
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="projectname"
                                id="role"
                                onChange={handleChange}
                                value={formsData.projectname}
                              ></input>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <label
                                htmlFor="role-assign"
                                className="col-form-label"
                              >
                                Assign To{" "}
                                <span style={{ fontSize: "17px" }}> </span> :
                              </label>

                              <Select
                                id="role-assign"
                                options={allUsers && allUsers.map((t) => {
                                  return { value: t._id, label: t.username };
                                })}
                                placeholder="Select User"
                                value={formsData.assign_to}
                                onChange={handleSelect}
                                isMulti
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt-4">
                        <button className="btn btn-primary" type="submit">
                          Create Project
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateProjects;
