const usePermission = (userDetail) => {
  if (!userDetail) {
    return false;
  }
  let input = userDetail.permission;
  const output = {};

  for (const key in input) {
    if (input.hasOwnProperty(key)) {
      const permissions = input[key];

      // Generate individual permissions
      for (const permission of permissions) {
        const propName = `has${key}${permission}`;
        output[propName] = permissions.includes(permission);
      }
    }
  }

  output.allPermission = Object.keys(input).flat();

  // let per = userDetail.permission;
  // Object.entries(per).forEach((entry) => {

  //   let { key, value } = entry

  // });

  const hasUser =
    userDetail !== null && userDetail.permission && userDetail.permission.User;
  const hasTicket =
    userDetail !== null &&
    userDetail.permission &&
    userDetail.permission.Ticket;
  const hasProject =
    userDetail !== null &&
    userDetail.permission &&
    userDetail.permission.Project;
  const hasSprint =
    userDetail !== null &&
    userDetail.permission &&
    userDetail.permission.Sprint;

  const hasUserCreate =
    userDetail !== null &&
    userDetail.permission.User &&
    userDetail.permission.User.includes("Create");
  const hasUserUpdate =
    userDetail !== null &&
    userDetail.permission.User &&
    userDetail.permission.User.includes("Update");
  const hasUserView =
    userDetail !== null &&
    userDetail.permission.User &&
    userDetail.permission.User.includes("View");
  const hasUserDelete =
    userDetail !== null &&
    userDetail.permission.User &&
    userDetail.permission.User.includes("Delete");

  const hasProjectCreate =
    userDetail !== null &&
    userDetail.permission.Project &&
    userDetail.permission.Project.includes("Create");
  const hasProjectUpdate =
    userDetail !== null &&
    userDetail.permission.Project &&
    userDetail.permission.Project.includes("Update");
  const hasProjectView =
    userDetail !== null &&
    userDetail.permission.Project &&
    userDetail.permission.Project.includes("View");
  const hasProjectDelete =
    userDetail !== null &&
    userDetail.permission.Project &&
    userDetail.permission.Project.includes("Delete");

  const hasSprintCreate =
    userDetail !== null &&
    userDetail.permission.Sprint &&
    userDetail.permission.Sprint.includes("Create");
  const hasSprintUpdate =
    userDetail !== null &&
    userDetail.permission.Sprint &&
    userDetail.permission.Sprint.includes("Update");
  const hasSprintView =
    userDetail !== null &&
    userDetail.permission.Sprint &&
    userDetail.permission.Sprint.includes("View");
  const hasSprintDelete =
    userDetail !== null &&
    userDetail.permission.Sprint &&
    userDetail.permission.Sprint.includes("Delete");
  const hasSprintPiechart =
    userDetail !== null &&
    userDetail.permission.Sprint &&
    userDetail.permission.Sprint.includes("Piechart");

  const hasTicketCreate =
    userDetail !== null &&
    userDetail.permission.Ticket &&
    userDetail.permission.Ticket.includes("Create");
  const hasTicketUpdate =
    userDetail !== null &&
    userDetail.permission.Ticket &&
    userDetail.permission.Ticket.includes("Update");
  const hasTicketView =
    userDetail !== null &&
    userDetail.permission.Ticket &&
    userDetail.permission.Ticket.includes("View");
  const hasTicketDelete =
    userDetail !== null &&
    userDetail.permission.Ticket &&
    userDetail.permission.Ticket.includes("Delete");
  const hasTicketStatus =
    userDetail !== null &&
    userDetail.permission.Ticket &&
    userDetail.permission.Ticket.includes("Status");
  const hasTicketPriority =
    userDetail !== null &&
    userDetail.permission.Ticket &&
    userDetail.permission.Ticket.includes("Priority");
  const hasTicketFilter =
    userDetail !== null &&
    userDetail.permission.Ticket &&
    userDetail.permission.Ticket.includes("Filter");
  const hasTicketPiechart =
    userDetail !== null &&
    userDetail.permission.Ticket &&
    userDetail.permission.Ticket.includes("Piechart");
  const hasTicketGroupby =
    userDetail !== null &&
    userDetail.permission.Ticket &&
    userDetail.permission.Ticket.includes("Groupby");

  let allPermission = false;
  if (
    hasUser &&
    hasUserCreate &&
    hasUserDelete &&
    hasUserUpdate &&
    hasUserView &&
    hasProject &&
    hasProjectCreate &&
    hasProjectDelete &&
    hasProjectUpdate &&
    hasProjectView &&
    hasSprint &&
    hasSprintCreate &&
    hasSprintDelete &&
    hasSprintUpdate &&
    hasSprintView &&
    hasSprintPiechart &&
    hasTicket &&
    hasTicketCreate &&
    hasTicketDelete &&
    hasTicketUpdate &&
    hasTicketView &&
    hasTicketPriority &&
    hasTicketStatus&&
    hasTicketFilter &&
    hasTicketGroupby &&
    hasTicketPiechart
  ) {
    allPermission = true;
  }

  return {
    hasUser,
    hasUserCreate,
    hasUserDelete,
    hasUserUpdate,
    hasUserView,
    hasProject,
    hasProjectCreate,
    hasProjectDelete,
    hasProjectUpdate,
    hasProjectView,
    hasSprint,
    hasSprintCreate,
    hasSprintDelete,
    hasSprintUpdate,
    hasSprintView,
    hasSprintPiechart,
    hasTicket,
    hasTicketCreate,
    hasTicketDelete,
    hasTicketUpdate,
    hasTicketView,
    hasTicketPriority,
    hasTicketStatus,
    hasTicketFilter,
    hasTicketGroupby,
    hasTicketPiechart,
    allPermission,
  };
};

// const usePermission = (input) => {
//    const output = {};

//    for (const key in input) {
//      if (input.hasOwnProperty(key)) {
//        const permissions = input[key];

//        // Generate individual permissions
//        for (const permission of permissions) {
//          const propName = `has${key}${permission}`;
//          output[propName] = permissions.includes(permission);
//        }
//      }
//    }

//    // Additional computed properties
//    output.allPermission = Object.values(input).flat();

//    return output;
// }

export default usePermission;
