
import { getallpermission } from "../../api/api";
import useLocalstorage from "../hooks/local";

const useRole = (o) => {
    const { getItem } = useLocalstorage();
   
  let user = getItem("rove-user");
  const getRole = async () => {
    if (user!==undefined && user!==null) {
      user = JSON.parse(user);
      let role = user.role;
        if (role) {
          try {
            let response = await getallpermission({roll_Id:role})
             response = response.data;
             role = response.permission;
             return {role:role.role,permission:role.permission,loginUser:user}
          } catch (error) {
            console.log(error)
            return null
          }
       
      }
    }
    };
    
    return {getRole}
};

export default useRole;
