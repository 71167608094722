// let host = "http://192.168.1.12:9000";
// let host = "localhost:9000";
let host = "https://api.rovesolution.com";
// let port = "9000"

const api = {
  login: `${host}/admin/adminlogin`,
  createuser: `${host}/admin/createnewuser`,
  fetchallusers: `${host}/admin/allusers`,
  updateuser: `${host}/admin/updateuser`,
  deleteuser: `${host}/admin/deleteUser`,
  freeuser: `${host}/admin/free-users`,
  generateticket: `${host}/ticket/createnewticket`,
  getalltickets: `${host}/ticket/showtickets`,
  updateticket: `${host}/ticket/updateticket`,
  updateticketstatus: `${host}/ticket/update-ticket-status`,
  deleteticket: `${host}/ticket/deleteticket`,
  viewticket: `${host}/ticket/viewticket`,
  workingticket: `${host}/ticket/view_working_ticket`,
  sendmessage: `${host}/ticket/sendmessage`,
  readmessage: `${host}/ticket/readmessages`,
  deletemessage: `${host}/ticket/deletemessage`,
  changepassword: `${host}/admin/changepassword`,
  updateupload: `${host}/ticket/updatedocuments`,
  createrole: `${host}/userRole/create_role`,
  getallroles: `${host}/userRole/get_role`,
  deleterole: `${host}/userRole/s_delete`,
  updaterole: `${host}/userRole/update_role`,
  createpermission: `${host}/userPermissions/create_permissions`,
  getallpermission: `${host}/userPermissions/show_permissions`,
  updatepermission: `${host}/userPermissions/update_permissions`,
  deletepermission: `${host}/userPermissions/delete_permission`,
  downloadFile: `${host}/ticket/download`,
  filedelete: `${host}/ticket/filedelete`,
  fetchLogs: `${host}/ticket/logs`,
  createsprint: `${host}/sprint/createSpring`,
  fetchsprint: `${host}/sprint/fetchSpring`,
  deletesprint: `${host}/sprint/sprintDelete`,
  addsprintTask: `${host}/sprint/addTask`,
  updatesprint: `${host}/sprint/updateSpring`,
  createTask: `${host}/task/createnewtask`,
  fetchTask: `${host}/task/getalltasks`,
  deleteTask: `${host}/task/taskdelete`,
  createCategory: `${host}/category/createCategory`,
  getallCategory: `${host}/category/getallCategory`,
  updateCategory: `${host}/category/updateCategory`,
  deleteCategory: `${host}/category/deleteCategory`,
  createProject: `${host}/project/createproject`,
  getallProject: `${host}/project/getallprojects`,
  deleteProject: `${host}/project/deleteproject`,
  updateProject: `${host}/project/updateproject`,
  getOneProject: `${host}/project/getOneProject`,
  getallnotification: `${host}/ticket/getnotification`,
  readnotification: `${host}/ticket/notificationRead`,
  ticketCounter: `${host}/ticket/counterticket`,
  createNotification: `${host}/ticket/createNotification`,
  updateTask: `${host}/ticket/updateTask`,
  delayTask: `${host}/ticket/delayTask`,
  delaySprint: `${host}/sprint/delay-sprint-count`,
  timelogs: `${host}/ticket_time_log/time-log`,
  showtimelogs: `${host}/ticket_time_log/view-time-log`,
  showDateWiseWorkTime: `${host}/ticket/get-day-report`,
  showDateWiseLogs: `${host}/ticket/get-daywise-logs`,
};

export default api;
