

const useLocalstorage = () => {
  const setItem = (key, value) => {
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      console.log(error);
    }
  };

  const getItem = (key) => {
    try {
      let item = localStorage.getItem(key);
     
      return item ? (item) : null;
    } catch (error) {
      console.log(error);
    }
    };
    
    const removeItem = (key) => {
        try {
           localStorage.removeItem(key)
        } catch (error) {
            console.log(error)
        }
    }

  return { setItem, getItem,removeItem };
};

export default useLocalstorage;
