import React, { useState, useEffect, useContext } from "react";
import "select2";
import { useToast } from "@chakra-ui/toast";
import { getalltickets, getAllProjects } from "../api/api";
import Select from "react-select";
import { TicketContext } from "../utils/context";
import useRole from "../utils/middleware/role";

const SprintForm = (props) => {
  let initialstate = {
    name: "",
    startDate: "",
    endDate: "",
    task: null,
    projectId: "",
  };
  const minDate = new Date().toISOString().split('.')[0];
  const [formData, setFormData] = useState(initialstate);
  const [allTask, setAllTask] = useState([]);
  const toast = useToast();
  const [projects, setProjects] = useState(null);
  let { projectId, projectLength } = useContext(TicketContext);
  const [userDetail, setUserDetail] = useState(null);
  const { getRole } = useRole();
  let param = { project: projectId };

  async function fetchProjects() {
    try {
      let response = await getAllProjects();
      let data = response.data;
      if (data.status === false) {
        console.log(data?.message);
      }
      if (data.project) {
        setProjects(data.project);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getRole()
      .then((d) => setUserDetail(d))
      .catch((err) => console.log(err));
    fetchProjects();
  }, []);

  // const [selectedStates, setSelectedStates] = useState([]);

  //  useEffect(() => {
  //    // Initialize Select2 when the component mounts
  //    $(selectRef.current).select2();

  //    // Clean up Select2 when the component unmounts
  //    return () => {
  //      $(selectRef.current).select2("destroy");
  //    };
  //  }, []);

  const fetchTicket = async () => {
    try {
      if (formData.projectId) {
        param = { project: formData.projectId };
      }
      const res = await getalltickets(param);
      let data = res.data;
      if (data.status === false) {
        console.log(data?.message);
        // throw new Error(data?.message);
      }

      if (data.tickets) {
        let nonSprintTicket = data.tickets.filter((t) => {
          return t.sprint === null;
        });
        setAllTask(nonSprintTicket);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      userDetail !== null &&
      userDetail.role !== "Admin" &&
      userDetail.role !== "Team Leader" &&
      projectLength &&
      projectLength > 1 &&
      !projectId
    ) {
      toast({
        title: "Please, Select Project First.",
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
    fetchTicket();
  }, [projectId, formData.projectId, userDetail,projectLength]);

  const handleSelect = (data) => {
    setFormData({ ...formData, task: data });
  };

  // const handleSelectChange = (e) => {
  //   const selectedValues = Array.from(
  //     e.target.selectedOptions,
  //     (option) => option.value
  //   );
  //   // const selectedValues = selectedOptions.map((option) => option.value);
  //   setSelectedStates(selectedValues);
  // };

  const inputChangeHandler = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateForm = () => {
    let errors = {};
    if (!/^[A-Z a-z0-9_-]+$/.test(formData.name)) {
      errors.name =
        formData.name.trim().length === 0 || formData.name.trim().length < 3
          ? "Enter Sprint Name."
          : "Special character not allowed in  Name.";
      toast({
        title: errors.name,
        status: "warning",
        duration: 1000,
        isClosable: true,
        position: "top",
      });
    }

    if (formData.endDate.trim().length < 1) {
      errors.endDate = "Please Select End Date.";
      toast({
        title: errors.endDate,
        status: "warning",
        duration: 1000,
        isClosable: true,
        position: "top",
      });
    }
    // if (formData.task === null || formData.task?.length < 1) {
    //   errors.tasks = "Please Select Task.";
    //   toast({
    //     title: errors.tasks,
    //     status: "warning",
    //     duration: 1000,
    //     isClosable: true,
    //   });
    // }
    if (formData.startDate.trim().length < 1) {
      errors.startDate = "Please Select Start Date.";
      toast({
        title: errors.startDate,
        status: "warning",
        duration: 1000,
        isClosable: true,
        position: "top",
      });
    }
    if (
      userDetail !== null &&
      (userDetail.role === "Admin" || userDetail.role === "Team Leader") &&
      !formData.projectId
    ) {
      errors.project = "Please, Select Project.";
      toast({
        title: errors.project,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
    if (
      userDetail !== null &&
      userDetail.role !== "Admin" &&
      userDetail.role !== "Team Leader" &&
      !projectId
    ) {
      errors.project = "Please, Select Project First.";
      toast({
        title: errors.project,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }

    return Object.keys(errors).length === 0;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let value = [];
      if (formData.task !== null) {
        value = formData.task.map((t) => {
          return t.value;
        });
      }
      if (
        userDetail !== null &&
        (userDetail.role === "Admin" || userDetail.role === "Team Leader") &&
        formData.projectId
      ) {
        projectId = formData.projectId;
      }

      props.onSubmit({
        event: e,
        data: {
          ...formData,
          task: value,
          project: projectId,
        },
      });
      setFormData(initialstate);
    }
  };

  return (
    <form className="outer-repeater" onSubmit={submitHandler}>
      <div className="row ">
        <div className="d-flex flex-column align-items-center">
          <div className="col-md-4 col-sm-12 col-12">
            <div className="mb-3">
              <label htmlFor="role-name" className="col-form-label">
                Sprint Name:
              </label>
              <input
                onChange={inputChangeHandler}
                name="name"
                type="text"
                className="form-control"
                id="role-name"
                value={formData.name}
              />
            </div>
          </div>

          {userDetail !== null &&
            (userDetail.role.includes("Admin") ||
              userDetail.role.includes("Team Leader")) && (
              <div className="col-md-4 col-sm-12 col-12">
                <div className="mb-3">
                  <label htmlFor="project" className="col-form-label">
                    Project :
                  </label>
                  <select
                    id="project"
                    className="form-select"
                    aria-label="Default select example"
                    name="projectId"
                    onChange={inputChangeHandler}
                    value={formData.projectId}
                  >
                    <option defaultValue value="">
                      Select Project
                    </option>
                    {projects !== null &&
                      projects.map((p) => (
                        <option value={p._id} key={p._id}>
                          {p.projectName}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            )}

          <div className="col-md-4 col-sm-12 col-12">
            <div className="mb-3">
              <label htmlFor="role-sele" className="col-form-label">
                Sprint Task:
              </label>
              <Select
                id="role-sele"
                options={allTask.map((t) => {
                  return { value: t._id, label: t.title };
                })}
                placeholder="Select Tasks"
                value={formData.task}
                onChange={handleSelect}
                isMulti
              />
              {/* <select
                id="role-email"
                className="form-select js-example-basic-multiple"
                aria-label="Multiple select example"
                name="states[]"
                onChange={handleSelectChange}
                // onChange={(e) => handleSelectChange(e.target.selectedOptions)}
                value={selectedStates}
                multiple
              >
                {allTask.map((task) => (
                  <option key={task._id} value={task._id}>
                    {task.title}
                  </option>
                ))}
              </select> */}
            </div>
          </div>
          <div className="col-md-4 col-sm-12 col-12">
            <div className="mb-3">
              <label htmlFor="start" className="col-form-label">
                Starting Date
              </label>
              <input
                type="datetime-local"
                name="startDate"
                id="start"
                className="form-control"
                onChange={inputChangeHandler}
                value={formData.startDate}
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-12 col-12">
            <div className="mb-3">
              <label htmlFor="role-pass" className="col-form-label">
                Ending Date:
              </label>
              <input
                onChange={inputChangeHandler}
                name="endDate"
                type="datetime-local"
                className="form-control"
                id="role-pass"
                value={formData.endDate}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="text-center mb-4">
        <button type="submit" className="btn btn-primary mt-3 mt-lg-0 me-3">
          Create New Sprint
        </button>
        <button
          type="button"
          className="btn btn-secondary mt-3 mt-lg-0 me-3"
          onClick={() => {
            setFormData(initialstate);
          }}
        >
          Clear
        </button>
      </div>
    </form>
  );
};

export default SprintForm;
