import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleterole, getallroles, updaterole } from "../../api/api";
import { useToast } from "@chakra-ui/toast";
import TimeZone from "../../utils/helper/timezone";

const ListofRoles = () => {
  const [updateData, setUpdateData] = useState({ status: "", role: "" });
  const [roles, setRoles] = useState([]);
  const toast = useToast();
  
  


  const fetchRoles = async () => {
    try {
      let response = await getallroles();
      let data = response.data;
      if (data.status === false) {
        toast({
          title: data?.message,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
        throw new Error(data?.message);
      }
      if (data.roles) {
        
        setRoles(data.roles);
        
      }
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
   
    fetchRoles();
    
  }, []);

  const handleChange = (e) => {
    setUpdateData({ ...updateData, [e.target.name]: e.target.value });
  };

  const updateRole = (role) => {
    setUpdateData(role);
  };

  const updateRoleHandler = async (e) => {
    e.preventDefault();

    if (updateData.role === "") {
      toast({
        title: "Please Add Role Name.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else {
      try {
        let id = updateData._id;
        let response = await updaterole(id, updateData);

        let data = await response.data;
        if (data.status === false) {
          toast({
            title: data?.message,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
          throw new Error(data?.message);
        }
        if (data.status===true) {
          toast({
            title: data.message,
            status: "success",
            duration: 2000,
            isClosable: true,
          });

          fetchRoles();
        }
      } catch (error) {
        console.log(error)
      }
    }
  };
  

  const deleteRole = async (id) => {
    const shouldDelete = window.confirm("Are You Sure To Delete This Role ?");

    if (shouldDelete) {
      try {
        const response = await deleterole(id);
        const data = response.data;
        if (data.status === false) {
          toast({
            title: data?.message,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
          throw new Error(data?.message);
        }
        if (data.status===true) {
          toast({
            title: data.message,
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          fetchRoles()
        }
      } catch (error) {
        console.log(error)
      }
    }

    return;
  };
  

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">List of Roles</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="#">Admin</a>
                      </li>
                      <li className="breadcrumb-item active">List of Roles</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className=" mb-4">
                      <div className="row d-flex justify-content-end">
                        
                        <div className="col-md-2 ">
                          <Link
                            to="/createrole"
                            className="btn btn-primary  btn-rounded waves-effect waves-light"
                            data-bs-whatever="Create New Company"
                          >
                            <i className="fas fa-plus mx-2"></i>
                            Add New Role
                          </Link>
                        </div>
                      </div>
                    </div>

                    {roles.length > 0 ? (
                      <>
                        <div className="table-responsive">
                          <table className="table align-middle table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                <th className="align-middle">No.</th>
                                <th className="align-middle">Role</th>
                                <th className="align-middle">Role Id</th>

                                <th className="align-middle">Created </th>
                               
                                <th className="align-middle">Status</th>

                                <th className="align-middle" width="250">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {roles.length > 0 &&
                                roles.map((role, index) => (
                                  <tr key={role._id}>
                                    <td className="text-body fw-bold">
                                      {index + 1}
                                    </td>
                                    <td>{role.role}</td>
                                    <td>{role._id}</td>

                                    <td>{TimeZone(role.createdAt)}</td>
                                    

                                    <td>
                                      <span
                                        className={`badge badge-pill ${
                                          role.status === true
                                            ? "badge-soft-success"
                                            : "badge-soft-danger"
                                        } font-size-13`}
                                      >
                                        {role.status === false
                                          ? "Disable"
                                          : "Enable"}
                                      </span>
                                    </td>

                                    <td className="align-middle">
                                      <div className="d-flex align-items-center">
                                        {/* <div className="d-inline-block mx-2">
                                          <Link
                                            to={`/listof-study?${queryString.stringify(
                                              { _id: role._id }
                                            )}`}
                                            className="btn btn-primary btn-sm btn-rounded waves-effect waves-light"
                                          >
                                            View
                                          </Link>
                                        </div> */}
                                        <a
                                          href="#"
                                          role="button"
                                          onClick={() => updateRole(role)}
                                          data-bs-toggle="modal"
                                          data-bs-target="#AssignUser"
                                          className="d-inline-block mx-2 "
                                        >
                                          <i
                                            className="fas fa-edit"
                                            style={{ fontSize: "20px" }}
                                          ></i>{" "}
                                        </a>

                                        <a
                                          href="#"
                                          role="button"
                                          onClick={() => deleteRole(role._id)}
                                          className="d-inline-block mx-2"
                                        >
                                          <i
                                            className="fas fa-trash-alt"
                                            style={{ fontSize: "20px" }}
                                          ></i>
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      <h4>No Roles Found.</h4>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="AssignUser"
          tabIndex="-1"
          aria-labelledby="AssignUserStuday"
          aria-hidden="true"
        >
          <div className="modal-dialog ">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="AssignUserStuday">
                  Update Role
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={updateRoleHandler}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="role" className="col-form-label">
                          Role :
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="role"
                          id="role"
                          onChange={handleChange}
                          value={updateData.role}
                        ></input>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          htmlFor="role-Responsibility"
                          className="col-form-label"
                        >
                          Status :
                        </label>

                        <select
                          id="role-Responsibility"
                          className="form-select"
                          aria-label="Default select example"
                          name="status"
                          onChange={handleChange}
                          value={updateData.status}
                        >
                          <option value="true">Enable</option>
                          <option value="false">Disable</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="text-center mt-4">
                    <button type="submit" className="btn btn-primary">
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListofRoles;
