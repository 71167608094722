import React, { useContext, useEffect, useState } from "react";
import {
  fetchSprint,
  fetchallusers,
  generateticket,
  getAllProjects,
  getOneProject,
  getallCategory,
  sendmessage,
} from "../../api/api";
import { useNavigate } from "react-router";
// import "intl-tel-input/build/css/intlTelInput.css";
// import intlTelInput from "intl-tel-input";
import { useToast } from "@chakra-ui/toast";
import { TicketContext } from "../../utils/context";
import useRole from "../../utils/middleware/role";
import { Link } from "react-router-dom";
const allAccess = ["Admin", "Team Leader"];
const DIRECTSELFASSIGN = [""];

const CreateTicket = () => {
  const initialstate = {
    category: "",
    title: "",
    summary: "",
    priority: "",
    file: [],
    deadline: "",
    assign_id: "",
    sprint: "",
    projectId: "",
    expected_date: "",
    c_name: "",
    c_number: "",
    req_id: "",
    refund_amount: "",
    reason:""
  };
  const [formsData, setFormData] = useState(initialstate);
  const [assignUser, setAssignUser] = useState(null);
  const [categories, setCategories] = useState(null);
  const [sprints, setSprints] = useState([]);
  const [projects, setProjects] = useState(null);
  const [progress, setProgress] = useState({ started: false, pc: 0 });
  const toast = useToast();
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState(null);
  const [showRefundInput, setShowRefundInput] = useState(false);
  const { getRole } = useRole();
  const { projectId, projectLength } = useContext(TicketContext);
  const minDate = new Date().toISOString().split("T")[0];

  // console.log(projectId, "pp");
  const fetchCategories = async () => {
    try {
      let res = await getallCategory();
      let data = res.data;
      if (data.status === false) {
        // throw new Error(data?.message);
        console.log(data);
      }
      if (data.category) {
        setCategories(data.category);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function fetchProjects() {
    try {
      let response = await getAllProjects();
      let data = response.data;
      if (data.status === false) {
        // throw new Error(data?.message);
        console.log(data);
      }
      if (data.project) {
        setProjects(data.project);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = (e) => {
    if (
      userDetail &&
      e.target.name == "projectId" &&
      allAccess.includes(userDetail.role)
    ) {
      getOneProject(e.target.value)
        .then((res) => {
          let data = res.data;
          if (data.status) {
            var filterUser = data.project.assign_to;
            /* let filterUser = data.project.assign_to.filter((user) => {
            return user._id !== tk.assign_id._id;
          }); */
            setAssignUser(filterUser);
          } else {
            console.log(data);
          }
        })
        .catch((err) => console.log(err));
    }
    if (e.target.name == "category") {
      var refundAmount = ["65bb95de7ba25bfc9f95fadb"]
      if (refundAmount.includes(e.target.value)) {
        setShowRefundInput(true)
      } else {
        setFormData({...formsData,req_id:"",refund_amount:"",reason:""})
        setShowRefundInput(false)
      }
    }
    setFormData({ ...formsData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formsData, file: e.target.files });
  };

  const validateForm = () => {
    let errors = {};
    if (formsData.title.trim().length < 4) {
      errors.title = "Title Must Be 4 Charcter.";
      toast({
        title: errors.title,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
    if (formsData.category.length === 0 && !Dispatcher) {
      errors.category = "Please, Select Category.";
      toast({
        title: errors.category,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
    if (formsData.priority.length === 0) {
      errors.priority = "Please, Select Priority.";
      toast({
        title: errors.priority,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
    if (formsData.deadline.trim().length === 0 && !Dispatcher) {
      errors.deadline = "Please, Enter Expected Hours.";
      toast({
        title: errors.deadline,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
    if (
      userDetail &&
      allAccess.includes(userDetail.role) &&
      !formsData.projectId
    ) {
      errors.Adminproject = "Please, Select Project.";
      toast({
        title: "Please, Select Project.",
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
    if (
      userDetail &&
      userDetail.role !== "Admin" &&
      userDetail.role !== "Team Leader" &&
      !projectId
    ) {
      errors.project = "Please, Select Project First.";
      toast({
        title: "Please, Select Project First.",
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
    if (
      userDetail &&
      (userDetail.role === "Dispatcher" ||
        userDetail.role === "Dispatcher Manager") &&
      formsData.c_number.trim().length &&
      !/[+0-9]{8}/.test(formsData.c_number)
    ) {
      errors.c_number = "Please, enter valid number";
      toast({
        title: errors.c_number,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }

    return Object.keys(errors).length === 0;
  };

  const fetchAssign = async () => {
    try {
      const response = await fetchallusers();
      let data = response.data;
      if (data.status === false) {
        // throw new Error(data?.message);
        console.log(data);
      }
      if (data.users) {
        let users = data.users;

        users = users.filter((user) => {
          return (
            user.roleName !== "Admin" &&
            user.roleName !== "User" &&
            user.roleName !== "Client"
          );
        });

        setAssignUser(users);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRole()
      .then((d) => setUserDetail(d))
      .catch((err) => console.log(err));
    fetchProjects();
    fetchCategories();

    // console.log(projectLength > 1);
    if (projectLength == 1) {
      getOneProject(projectId)
        .then((res) => {
          let data = res.data;
          if (data.status) {
            var filterUser = data.project.assign_to;
            // if (userDetail) {
            //   filterUser = data.project.assign_to.filter(
            //     (u) => u._id !== userDetail.loginUser._id
            //   );
            // }
            /* let filterUser = data.project.assign_to.filter((user) => {
            return user._id !== tk.assign_id._id;
          }); */
            setAssignUser(filterUser);
          } else {
            console.log(data);
          }
        })
        .catch((err) => console.log(err));
    } else {
      fetchAssign();
    }
  }, []);

  // useEffect(() => {
  //   if( userDetail &&
  //     (userDetail.role === "Dispatcher" ||
  //       userDetail.role === "Dispatcher Manager")) {
  //         const input = document.querySelector("#countrySelect");
  //         var iti = intlTelInput(input, {
  //           initialCountry: "KW",
  //           showSelectedDialCode: true,
  //         });
  //         input.addEventListener("countrychange", function (e) {
  //           let dialcode = iti.getSelectedCountryData().dialCode;
  //           console.log(dialcode)
  //           // input.value = "+" + dialcode + " " + inputValue;
  //         });
  //       }
  // },[userDetail])

  useEffect(() => {
    if (
      userDetail !== null &&
      userDetail.role !== "Admin" &&
      userDetail.role !== "Team Leader" &&
      projectLength &&
      projectLength > 1 &&
      !projectId
    ) {
      toast({
        title: "Please, Select Project First.",
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }

    fetchsprints();
  }, [projectId, userDetail, formsData.projectId, projectLength]);

  const fetchsprints = async () => {
    try {
      let param = { project: projectId };
      if (formsData.projectId) {
        param = { project: formsData.projectId };
      }
      const res = await fetchSprint(param);
      let data = res.data;
      if (data.status === false) {
        // throw new Error(data?.message);
        console.log(data);
      }
      if (data.sprint) {
        setSprints(data.sprint);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const formSubmitHandler = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        let form = new FormData();
        form.append("title", formsData.title);
        form.append("summary", formsData.summary);
        if (!Dispatcher) {
          form.append("category", formsData.category);
          form.append("deadline", formsData.deadline);
          form.append("expected_date", formsData.expected_date);
        }
        form.append("priority", formsData.priority);
        if (userDetail && userDetail.role.includes("Developer")) {
          formsData.assign_id = `${userDetail.loginUser._id} ${userDetail.loginUser.username}`;
        }
        form.append("sprint", formsData.sprint);
        form.append("assign_id", formsData.assign_id);
        form.append("customer_name", formsData.c_name);
        form.append("customer_number", formsData.c_number);
        form.append("refund_amount", formsData.refund_amount);
        form.append("reason", formsData.reason);
        form.append("req_id", formsData.req_id);
        if (formsData.projectId) {
          form.append("project", formsData.projectId);
        } else if (projectId) {
          form.append("project", projectId);
        }
        // if (projectId) {
        //   form.append("project", projectId);
        // }
        if (formsData.file.length !== 0) {
          for (let i = 0; i < formsData.file.length; i++) {
            form.append("files", formsData.file[i]);
          }
        }

        setProgress((prev) => {
          return { ...prev, started: true };
        });
        // for (var pair of form.entries()) {
        //   console.log(pair[0] + ", " + pair[1]);
        // }

        let response = await generateticket(form, (progressEvent) =>
          setProgress((prev) => {
            return { ...prev, pc: progressEvent.progress * 100 };
          })
        );

        let data = await response.data;
        if (data.status === false) {
          toast({
            title: data?.message,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
          // throw new Error(data?.message);
          console.log(data);
        }

        if (data.status === true) {
          if (formsData.summary) {
            sendmessage({
              ticketId: data.ticket._id,
              message: formsData.summary,
            })
              .then((d) => console.log("Message Success"))
              .catch((err) => console.log(err));
          }
          toast({
            title: `Your Ticket Id Is ${data.ticket.ticket_id}`,
            description: data.message,
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top",
          });

          setProgress((prev) => {
            return { ...prev, started: false };
          });
          setShowRefundInput(false)
          setFormData(initialstate);
        }
      } catch (error) {
        if (error) {
          toast({
            title: "Somthing Has been Wrong.",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
      }
    }
  };

  let task = "Task";
  const Dispatcher =
    userDetail &&
    (userDetail.role === "Dispatcher" ||
      userDetail.role === "Dispatcher Manager");
  const Client = userDetail && userDetail.role === "Client";

  if (Dispatcher || Client) {
    task = "Ticket";
  }
  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">{`Create a ${task}`}</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <Link to="/listoftickets" className="btn btn-primary">
                        Back{" "}
                      </Link>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-11 col-lg-10">
                <div className="card">
                  <div className="card-body">
                    <form
                      onSubmit={formSubmitHandler}
                      encType="multipart/form-data"
                    >
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-6">
                          <div className="col-md-12">
                            {progress.started && (
                              <>
                                <progress
                                  max="100"
                                  value={progress.pc}
                                  style={{ width: "100%" }}
                                ></progress>
                                <span>{`${progress.pc}%`}</span>
                              </>
                            )}
                            {(Dispatcher||Client) && (
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="c_name"
                                    className="col-form-label"
                                  >
                                    Customer Name :
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter customer name"
                                    name="c_name"
                                    id="c_name"
                                    onChange={handleChange}
                                    value={formsData.c_name}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="countrySelect"
                                    className="col-form-label"
                                  >
                                    Customer Number :
                                  </label>
                                  <input
                                    type="tel"
                                    id="countrySelect"
                                    placeholder="Enter customer number"
                                    maxLength={8}
                                    className="form-control"
                                    name="c_number"
                                    onChange={handleChange}
                                    value={formsData.c_number}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                            <div className="row">
                              <div
                                className={
                                  userDetail &&
                                  (userDetail.role.includes("Admin") ||
                                    userDetail.role.includes("Team Leader") ||Dispatcher || Client)
                                    ? `col-md-12 col-lg-12`
                                    : `col-md-8 col-lg-8`
                                }
                              >
                                <div className="mb-3">
                                  <label
                                    htmlFor="title"
                                    className="col-form-label"
                                  >
                                    Title
                                    <span
                                      style={{ color: "red", fontSize: "17px" }}
                                    >
                                      *
                                    </span>{" "}
                                    :
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="title"
                                    placeholder="Enter title"
                                    id="title"
                                    onChange={handleChange}
                                    value={formsData.title}
                                  />
                                </div>
                              </div>
                              {!Dispatcher && !Client && <div
                                className={
                                  userDetail &&
                                  (userDetail.role.includes("Admin") ||
                                    userDetail.role.includes("Team Leader"))
                                    ? `col-md-6 col-lg-6`
                                    : `col-md-4 col-lg-4`
                                }
                              >
                                <div className="mb-3">
                                  <label
                                    htmlFor="category"
                                    className="col-form-label"
                                  >
                                    Category
                                    <span
                                      style={{ color: "red", fontSize: "17px" }}
                                    >
                                      *
                                    </span>{" "}
                                    :
                                  </label>
                                  <select
                                    id="category"
                                    className="form-select"
                                    aria-label="Default select example"
                                    name="category"
                                    onChange={handleChange}
                                    value={formsData.category}
                                  >
                                    <option defaultValue value="">
                                      Select Category
                                    </option>
                                    {categories !== null &&
                                      categories.map((c) => (
                                        <option value={c._id} key={c._id}>
                                          {c.cname}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>}
                              {userDetail &&
                                (userDetail.role.includes("Admin") ||
                                  userDetail.role.includes("Team Leader")) && (
                                  <div
                                    className={
                                      userDetail &&
                                      (userDetail.role.includes("Admin") ||
                                        userDetail.role.includes("Team Leader"))
                                        ? `col-md-6 col-lg-6`
                                        : `col-md-4 col-lg-4`
                                    }
                                  >
                                    <div className="mb-3">
                                      <label
                                        htmlFor="project"
                                        className="col-form-label"
                                      >
                                        Project
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "17px",
                                          }}
                                        >
                                          *
                                        </span>{" "}
                                        :
                                      </label>
                                      <select
                                        id="project"
                                        className="form-select"
                                        aria-label="Default select example"
                                        name="projectId"
                                        onChange={handleChange}
                                        value={formsData.projectId}
                                      >
                                        <option defaultValue value="">
                                          Select project
                                        </option>
                                        {projects !== null &&
                                          projects.map((p) => (
                                            <option value={p._id} key={p._id}>
                                              {p.projectName}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                         
                          <div className="row">
                            <div
                              className={
                                userDetail &&
                                !Dispatcher
                                  ? "col-md-4 mb-3"
                                  : "col-md-12 mb-3"
                              }
                            >
                              <label
                                htmlFor="role-priority"
                                className="col-form-label"
                              >
                                Priority
                                <span
                                  style={{ color: "red", fontSize: "17px" }}
                                >
                                  *
                                </span>{" "}
                                :
                              </label>

                              <select
                                id="role-priority"
                                className="form-select"
                                aria-label="Default select example"
                                name="priority"
                                onChange={handleChange}
                                value={formsData.priority}
                              >
                                <option defaultValue>Select priority</option>
                                <option value="Low">Low</option>
                                <option value="Medium">Medium</option>
                                <option value="High">High</option>
                              </select>
                            </div>
                            {!Dispatcher && !Client && <div
                              className={
                                userDetail &&
                                !Dispatcher
                                  ? "col-md-4 mb-3"
                                  : "col-md-6 mb-3"
                              }
                            >
                              <label
                                htmlFor="role-assign"
                                className="col-form-label"
                              >
                                Assign To
                                <span
                                  style={{ fontSize: "17px", color: "red" }}
                                >
                                  *
                                </span>{" "}
                                :
                              </label>
                              <select
                                id="role-assign"
                                className="form-select"
                                aria-label="Default select example"
                                name="assign_id"
                                onChange={handleChange}
                                value={formsData.assign_id}
                              >
                                <option
                                  value={
                                    userDetail &&
                                    userDetail.role.includes("Developer")
                                      ? userDetail.loginUser._id
                                      : ""
                                  }
                                >
                                  {userDetail &&
                                  userDetail.role.includes("Developer")
                                    ? userDetail.loginUser.username
                                    : "Select user"}
                                </option>
                                {userDetail &&
                                  !userDetail.role.includes("Developer") &&
                                  assignUser !== null &&
                                  assignUser.map((user) => (
                                    <option
                                      value={`${user._id} ${user.username}`}
                                      key={user._id}
                                    >
                                      {user.username}
                                    </option>
                                  ))}
                              </select>
                            </div>}
                            {userDetail &&
                              userDetail.role !== "Dispatcher" &&
                              userDetail.role !== "Dispatcher Manager" && (
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="sprint"
                                      className="col-form-label"
                                    >
                                      Sprint
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "17px",
                                        }}
                                      >
                                        {" "}
                                      </span>
                                      :
                                    </label>
                                    <select
                                      id="sprint"
                                      className="form-select"
                                      aria-label="Default select example"
                                      name="sprint"
                                      onChange={handleChange}
                                      value={formsData.sprint}
                                    >
                                      <option value="" defaultValue>
                                        Select Sprint
                                      </option>
                                      {sprints.map((sp) => (
                                        <option value={sp._id} key={sp._id}>
                                          {sp.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              )}
                          </div>
                          {(Dispatcher||Client) && showRefundInput && (
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="req_id"
                                    className="col-form-label"
                                  >
                                    Request id :
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Enter request id"
                                    className="form-control"
                                    name="req_id"
                                    id="req_id"
                                    onChange={handleChange}
                                    value={formsData.req_id}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="refund_amount"
                                    className="col-form-label"
                                  >
                                    Refund amount :
                                  </label>
                                  <input
                                    type="text"
                                    id="refund_amount"
                                    className="form-control"
                                    name="refund_amount"
                                    placeholder="Enter refund amount"
                                    onChange={handleChange}
                                    value={formsData.refund_amount}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="mb-3">
                                  <label
                                    htmlFor="reason"
                                    className="col-form-label"
                                  >
                                    Reason :
                                  </label>
                                  <input
                                    type="text"
                                    id="reason"
                                    className="form-control"
                                    name="reason"
                                    placeholder="Enter reason"
                                    onChange={handleChange}
                                    value={formsData.reason}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="col-md-12">
                            <div className="mb-3">
                              <label
                                htmlFor="summary"
                                className="col-form-label"
                              >
                                Description :
                              </label>
                              <textarea
                                className="form-control"
                                name="summary"
                                placeholder="Enter description"
                                id="summary"
                                rows="2"
                                onChange={handleChange}
                                value={formsData.summary}
                              ></textarea>
                            </div>
                          </div>
                          <div className="row my-2">
                            <div className={
                                userDetail &&
                                !Dispatcher
                                  ? "col-md-6"
                                  : "col-md-12"
                              }>
                              <label htmlFor="file" className="col-form-label">
                                Attachments
                                <span
                                  style={{ color: "red", fontSize: "17px" }}
                                >
                                  {" "}
                                </span>
                              </label>
                              <input
                                multiple
                                type="file"
                                name="file"
                                id="file"
                                className="form-control"
                                onChange={handleFileChange}
                              />
                            </div>
                            {!Dispatcher && !Client && <>
                            
                            <div className="col-md-3">
                              <label
                                htmlFor="deadline"
                                className="col-form-label"
                              >
                                Expected Hours
                                <span
                                  style={{ color: "red", fontSize: "17px" }}
                                >
                                  *
                                </span>{" "}
                                :
                              </label>
                              <input
                                required
                                type="number"
                                name="deadline"
                                id="deadline"
                                placeholder="Enter hours"
                                className="form-control"
                                onChange={handleChange}
                                value={formsData.deadline}
                              />
                            </div>
                            <div className="col-md-3">
                              <label
                                htmlFor="expected_date"
                                className="col-form-label"
                              >
                                Expected End Date
                                <span
                                  style={{ color: "red", fontSize: "17px" }}
                                >
                                  *
                                </span>{" "}
                              </label>
                              <input
                                required
                                type="date"
                                min={minDate}
                                name="expected_date"
                                id="expected_date"
                                className="form-control"
                                onChange={handleChange}
                                value={formsData.expected_date}
                              />
                            </div>
                            </>}
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt-4">
                        <button className="btn btn-primary" type="submit">
                          {`Create ${task}`}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTicket;
