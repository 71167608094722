import Home from "./pages/Home/Home";
import Dashbord from "./pages/Home/Dashbord";
import { Route, Routes } from "react-router-dom";
import ListofUsers from "./pages/user/ListofUsers";
import CreateTicket from "./pages/Ticket/CreateTicket";
import CreateRole from "./pages/Role/CreateRole";
import ListofTickets from "./pages/Ticket/ListofTickets";
import ListofRoles from "./pages/Role/ListofRoles";
import ListOfStudy from "./pages/Ticket/ViewTicket";
import CreateUser from "./pages/user/CreateUser";
import EditProfile from "./pages/user/EditProfile";
import PrivateRoute from "./component/PrivateRoute";
import NotFound from "./pages/404/NotFound";
import GeneratePdf from "./pages/Ticket/GeneratePdf";
import "./app.css";
import CreatePermission from "./pages/Permissions/CreatePermission";
import ListofPermissions from "./pages/Permissions/ListofPermissions";
import { useEffect, useState } from "react";
import useRole from "./utils/middleware/role";
import CreateSprint from "./pages/Sprint/CreateSprint";
import ListofSprint from "./pages/Sprint/ListofSprint";
import CreateCategory from "./pages/Category/CreateCategory";
import ListofCategory from "./pages/Category/ListofCategory";
import ListofProjects from "./pages/Project-Model/ListofProjects";
import CreateProjects from "./pages/Project-Model/CreateProjects";
import ProjectDashboard from "./pages/Home/ProjectDashboard";
import Header from "./pages/Header/Header";
import CalendarTable from "./pages/Report/CalenderTable";
import AllNotification from "./pages/Notification/AllNotification";
import DayWiseLogs from "./pages/Report/DayWiseLogs";

function App() {
  const [userDetail, setUserDetail] = useState(null);
  const { getRole } = useRole();
  const [projectId, setProjectId] = useState("");

  useEffect(() => {
    //     console.log("App")
    getRole()
      .then((data) => {
        setUserDetail(data);
        console.log("app");
      })
      .catch((err) => console.log(err));
  }, []);

  const handleProjectChange = (project) => {
    setProjectId(project);
  }

 

  return (
    <>
      <div id="layout-wrapper" className="app">
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route
            element={
              <PrivateRoute
                userDetail={userDetail}
                onProjectChange={handleProjectChange}
                project={projectId}
              />
            }
          >
            <Route
              exact
              path="/allnotification"
              element={<AllNotification />}
            ></Route>
            <Route
              exact
              path="/dashboard"
              element={<Dashbord projectId={projectId} />}
            ></Route>
            <Route
              exact
              path="/daywise-list"
              element={<DayWiseLogs />}
            ></Route>
            
            <Route
              exact
              path="/createticket"
              element={<CreateTicket projectId={projectId} />}
            ></Route>
            <Route
              exact
              path="/calander-table"
              element={<CalendarTable projectId={projectId} />}
            ></Route>
            <Route
              exact
              path="/listoftickets"
              element={<ListofTickets projectId={projectId} />}
            ></Route>

            <Route
              exact
              path="/edit-profile"
              element={<EditProfile projectId={projectId} />}
            ></Route>

            <Route
              exact
              path="/listof-users"
              element={<ListofUsers projectId={projectId} />}
            ></Route>

            <Route exact path="/createrole" element={<CreateRole />}></Route>
            <Route exact path="/listofroles" element={<ListofRoles />}></Route>
            <Route
              exact
              path="/listofpermission"
              element={<ListofPermissions />}
            ></Route>
            <Route
              exact
              path="/createpermission"
              element={<CreatePermission />}
            ></Route>
            <Route
              exact
              path="/listof-study"
              element={<ListOfStudy userDetail={userDetail} />}
            ></Route>
            <Route
              exact
              path="/create-user"
              element={<CreateUser userDetail={userDetail} />}
            ></Route>
            <Route
              exact
              path="/create-sprint"
              element={<CreateSprint />}
            ></Route>
            <Route exact path="/list-sprint" element={<ListofSprint />}></Route>
            <Route
              exact
              path="/create-category"
              element={<CreateCategory />}
            ></Route>
            <Route
              exact
              path="/list-category"
              element={<ListofCategory />}
            ></Route>
            <Route
              exact
              path="/list-project"
              element={<ListofProjects />}
            ></Route>
            <Route
              exact
              path="/create-project"
              element={<CreateProjects />}
            ></Route>

            <Route exact path="/genpdf" element={<GeneratePdf />}></Route>
          </Route>
        </Routes>
      </div>
    </>
  );
}

export default App;
