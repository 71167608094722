import React, { useState } from "react";
import { useToast } from "@chakra-ui/toast";
import { createCategory } from "../../api/api";
import { useNavigate } from "react-router";

const CreateCategory = () => {
  const toast = useToast();
  const [formsData, setFormData] = useState({ category: "" });
  const navigate = useNavigate();
  const handleChange = (e) => {
    setFormData({ ...formsData, [e.target.name]: e.target.value });
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      if (formsData.category.trim().length < 1) {
        toast({
          title: "Please Enter Category.",
          status: "warning",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      } else {
        let response = await createCategory(formsData);
        if (response.data.status === false) {
          toast({
            title: response.data?.message,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
          // throw new Error(response.data?.message);
        }
        if (response.data.status===true) {
          setFormData({ category: "" });
          toast({
            title: response.data.message,
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top",
          });

          let timeout = setTimeout(() => {
            navigate("/list-category");
          }, 2000);
          return () => clearTimeout(timeout);
        }
      }
    } catch (error) {
      console.log(error);
      
    }
  };

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Create a Category</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <strong>Admin</strong>
                      </li>
                      <li className="breadcrumb-item active">
                        Create a Category
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-11 col-lg-10">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={formSubmitHandler}>
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-6">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label htmlFor="role" className="col-form-label">
                                Category :
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="category"
                                id="role"
                                onChange={handleChange}
                                value={formsData.category}
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt-4">
                        <button className="btn btn-primary" type="submit">
                          Create Category
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCategory;
